import { useState, useEffect } from "react";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Badge, Button, useDisclosure, Text, HStack } from "@chakra-ui/react";
import { longBasic, longBasicLimit } from "../../Utils/TextValidation";
import { useAuth } from "../../Data/Contexts/AuthContext";
import { FormSelect, FormTextArea } from "./FormInputs";
import { AiOutlineEdit } from 'react-icons/ai';
import COMMENTTYPE from '../../Data/Constants/AnomalyCommentType.json';
import useStateValidate from "../../Custom_Hooks/useStateValidate";
import Anomalies from "../../Data/Firestore/anomalies";

export function EditAnomalyClassAndRationaleModal({ anomaly }) {

  const { accountInfo } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [classRationale, setClassRationale] = useState("");
  const [[manualClassRationale, setManualClassRationale], isManualClassRationaleValid] = useStateValidate(longBasic);
  const [classRationaleOptions, setClassRationaleOptions] = useState([]);
  const [showClassRationaleTextInput, setShowClassRationaleTextInput] = useState([]);

  const [anomalyClass, setAnomalyClass] = useState("");
  const [loading, setLoading] = useState(false);

  const class1Options = [
    "Class 1: Occurred on Election Day but did not cause a substantial delay in voting or tabulation.",
    "Class 1: Occurred on Election Day and did cause a substantial delay in voting or tabulation.",
    "Class 1: Occurred during Early Voting but did not cause a substantial delay in voting or tabulation.",
    "Class 1: Occurred during Early Voting and did cause a substantial delay in voting or tabulation",
    "Class 1: Anomaly has suspected malicious or unauthorized origins",
    "Class 1: Occurred on Election Day/Early Voting but outside of Indiana with an Indiana Certified System"
  ]

  const class2Options = [
    "Class 2: Did not occur on Election Day and did not pose a delay in voting or tabulation",
    "Class 2: Did not occur on Election Day/Early Voting but did occur outside of Indiana with an Indiana Certified System",
    "Class 2: Other: ____________________________",
  ]

  useEffect(() => {
    if(anomaly.class === "2" && !(class2Options.includes(anomaly.classRationale))){
      setAnomalyClass("2")
      setClassRationale(class2Options[2])
      setShowClassRationaleTextInput(true)
      setManualClassRationale(anomaly.classRationale)
    }//eslint-disable-next-line
  }, [anomaly])

  useEffect(() => {
    if(anomalyClass === "1"){
        setClassRationaleOptions(class1Options)
        setClassRationale(class1Options[0])
    } else {
        setClassRationaleOptions(class2Options)
        if (manualClassRationale.length > 0){
          setShowClassRationaleTextInput(true)
          setClassRationale(class2Options[2])
        } else {
          setClassRationale(class2Options[0])
        }
    } //eslint-disable-next-line
  }, [anomalyClass])

  useEffect(() => {
    setShowClassRationaleTextInput(classRationale === "Class 2: Other: ____________________________")
  }, [classRationale])

  async function submitChange(){
    setLoading(true);
    const updateObj = {
      class: anomalyClass,
      classRationale: showClassRationaleTextInput ? "Class 2: " + manualClassRationale : classRationale,
      comments: [
        ...anomaly.comments,
        ...generateComments(),
      ]
    }
    debugger
    await Anomalies.updateAnomalyField(anomaly.id, updateObj);
    setLoading(false);
    onClose();
  };

  function generateComments(){
    const result = [];
    const classModified = String(anomalyClass) !== String(anomaly.class);
    const rationaleModified = classRationale !== anomaly.classRationale;

    if (classModified){
      result.push({
        created: new Date(),
        creator: accountInfo.name,
        creatorEmail: accountInfo.email,
        creatorRole: accountInfo.role,
        editedProp: "Class",
        originalValue: anomaly.class,
        newValue: anomalyClass,
        type: COMMENTTYPE.PROPEDIT,
        subType: ""
      })
    }

    if (rationaleModified){
      result.push({
        created: new Date(),
        creator: accountInfo.name,
        creatorEmail: accountInfo.email,
        creatorRole: accountInfo.role,
        editedProp: "Class Rationale",
        originalValue: anomaly.classRationale,
        newValue: showClassRationaleTextInput ? "Class 2: " + manualClassRationale : classRationale,
        type: COMMENTTYPE.PROPEDIT,
        subType: "long"
      })
    }

    return result
  }

  return (
    <>
      <HStack cursor='pointer'>
        <Badge fontSize='1.2em' onClick={onOpen}>{String(anomaly.class).includes("1") ? "Class 1" : "Class 2"}</Badge> 
        <AiOutlineEdit onClick={onOpen}/>
      </HStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Anomaly Class and Rationale</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize={16}>
              Current: {anomaly.class}, {anomaly.state} 
            </Text>
            <FormSelect 
            children={
                <>
                    <option value={"1"}>Class 1</option>
                    <option value={"2"}>Class 2</option>
                </>
            }
            label="Class"
            state={anomalyClass}
            stateMod={setAnomalyClass}
            />
            <FormSelect
            children={classRationaleOptions?.map((element, index) => ( <option key={index}>{element}</option>))}
            label="Class Rationale"
            state={classRationale}
            stateMod={setClassRationale}
            />
            { 
                showClassRationaleTextInput &&
                <FormTextArea
                errorMessage="Class rationale is required"
                input={manualClassRationale.replace("Class 2: ", "").replace("Class 1: ", "")}
                inputMod={setManualClassRationale}
                isSpellChecked
                isValid={isManualClassRationaleValid}
                label="Class Rationale Manual Input"
                placeholder="Enter anomaly class rationale"
                validationTip={`Not quite long enough! Your response needs at least ${longBasicLimit - manualClassRationale.length} more character${longBasicLimit - manualClassRationale.length > 1 ? 's' : ""}.`}
                />
            }
          </ModalBody>
          <ModalFooter>
            <Button variant='ghost' colorScheme='red' mr={3} onClick={onClose} isDisabled={loading}>Close</Button>
            <Button variant='outline' colorScheme='green' onClick={submitChange} isLoading={loading}>Confirm</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}