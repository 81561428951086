import { useState, useEffect, useContext } from 'react'
import { Tooltip, IconButton } from '@chakra-ui/react'
import { AiOutlinePrinter } from 'react-icons/ai'
import { createPDFFromAnomalies } from '../../../../Utils/PDFUtils'
import { PaginationContext } from '../../AnomalyTable'

export default function PrintToPDF() {
    
    const [showPrint, setShowPrint] = useState(false)
    const { anomalies } = useContext(PaginationContext)

    useEffect(() => {
        setShowPrint(anomalies?.some(a => a.selected))
    }, [anomalies])

    function handleClick(){
        createPDFFromAnomalies(anomalies)
    }

    return (
        showPrint &&
        <Tooltip label="Print Selected">
            <IconButton
            onClick={handleClick}
            aria-label="Export selected anomalies to pdf"
            icon={ <AiOutlinePrinter size={22}/> }
            />
        </Tooltip>  
    )
}
