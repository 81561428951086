import { useState, useEffect } from 'react'

export default function useStateValidate(validateFunc, initialState){

    if (!initialState) { initialState = ""}

    const [value, setValue] = useState(initialState)
    const [isValid, setIsValid] = useState(false)

    useEffect(() => {
        setIsValid(validateFunc(value))
    }, [value, validateFunc])
    
    return [[value, setValue], isValid]
}