import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, VStack, Text, Divider, useDisclosure, IconButton } from '@chakra-ui/react';
import { PlusSquareIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router';
import { Comments } from '../../Common/Comments'

const AnomalyAttribute = ({label, text}) => (
  <>
      <Text fontWeight={'800'}>{label}</Text>
      <Text>{text}</Text>
  </>
)

const AnomalyModal = ({ anomaly }) => {  

  let navigate = useNavigate();
  
  const { isOpen, onOpen, onClose } = useDisclosure()

  function navtoAnomaly(){
    navigate(`/anomaly/${anomaly.type}/${anomaly.id}`)
  }

  return (
    <>
      <IconButton
      onClick={onOpen}
      aria-label='Expand Anomaly Information'
      size='xs'
      icon={<PlusSquareIcon  w={4} h={4}/>}
      />
      <Modal isOpen={isOpen} onClose={onClose} size='4xl' scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Expanded Anomaly Information - {anomaly?.id}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack alignItems='flex-start'>
              <AnomalyAttribute label='Issue' text={anomaly?.description} />
              <Divider />
              <AnomalyAttribute label='Root Cause' text={anomaly?.rootCause}/>
              <Divider />
              <AnomalyAttribute label='Resolution' text={anomaly?.resolution}/>
              <Divider />
              <AnomalyAttribute label='Class' text={anomaly?.class} />
              <Divider />
              <AnomalyAttribute label='Rationale' text={anomaly?.classRationale} />
              <Divider />
              <Text fontWeight={'800'}>Comments</Text>
              <VStack width={'100%'} alignItems={'center'}>
                <Comments anomaly={anomaly} withButtonRack />
              </VStack>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={navtoAnomaly} marginRight={2}>
              View Details
            </Button>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AnomalyModal