import { useState, useEffect, useContext } from 'react'
import { useDisclosure, Button, IconButton, Tooltip, useRadio, useRadioGroup, ButtonGroup, FormControl, FormHelperText } from '@chakra-ui/react'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { PaginationContext } from '../../AnomalyTable'
import { quickStatsAboutArray } from '../../../../Utils/AnomalyUtils'
import { exportTableToXlsx } from '../../../../Utils/SpreadsheetUtils'
import { TableContext } from '../../../../Data/Contexts/TableContext'

function RadioCard(props) {
    const { getInputProps, getCheckboxProps } = useRadio(props)
    const input = getInputProps()
    const checkbox = getCheckboxProps()
  
    return (
        <Button
        {...checkbox}
        as='label' 
        _checked={{
            bg: props.switch ? 'green.900' : 'blue.900',
            color: 'white',
            borderColor: props.switch ? 'green.200' : 'blue.200',
        }}
        boxShadow='md'
        cursor='pointer'
        borderWidth='1px'
        borderRadius='md'
        >
        <input {...input} />
        {props.children}
      </Button>
    )
}

const ExportXLSXModal = () => {
    
    const options = [
        {
            value: "TAB",
            label: "Tab Contents",
            text: "Export all anomalies in the currently selected tab",
            func: (arr) => { return arr }
        },
        {
            value: "TABLE",
            label: "Displayed Table",
            text: "Export all anomalies currently shown in the table",
            func: (arr) => { return arr.slice(startIndex, endIndex) }
        },
        {
            value: "CUSTOM",
            label: "Select Custom",
            text: "Export all currently selected anomalies",
            func: (arr) => {
                let selectedAnoms = [];
                arr.forEach(a => { if(a.selected){ selectedAnoms.push(a) }})
                return selectedAnoms
            }
        }
    ]

    const { anomalies, startIndex, endIndex } = useContext(PaginationContext)
    const { isSwitchChecked } = useContext(TableContext)
    const [exportOption, setExportOption] = useState(options[0])
    const [anomaliesToExport, setAnomaliesToExport] = useState(anomalies)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'Export option',
        defaultValue: options[0].value,
        onChange: (val) => {setExportOption(options.find(el => el.value === val))},
    })
    const group = getRootProps()

    useEffect(() => {
        setAnomaliesToExport(exportOption.func(anomalies))
    }, [exportOption, anomalies])
    
    function onExport(){        
        exportTableToXlsx(anomaliesToExport, isSwitchChecked)
    }

    return (
        <>
            <Tooltip label="Export Table">
                <IconButton
                onClick={onOpen} 
                aria-label="Export table to excel spreadsheet" 
                icon={ <ExternalLinkIcon /> }
                />
            </Tooltip>

            <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Export Anomalies to Excel Spreadsheet</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <ButtonGroup {...group} isAttached >
                        {options.map(({value, label}) => {
                            const radio = getRadioProps({ value })
                            return (
                                <RadioCard key={value} {...radio} switch={isSwitchChecked}>
                                    {label}
                                </RadioCard>
                            )
                        })}
                    </ButtonGroup>
                    <FormControl>
                        <FormHelperText justifySelf={'start'} textAlign="start">
                            {exportOption.text}
                        </FormHelperText>
                    </FormControl>
                    {quickStatsAboutArray(anomaliesToExport, false)}
                </ModalBody>
                <ModalFooter>
                <Button colorScheme='red' variant='ghost' mr={3} onClick={onClose}>
                    Close
                </Button>
                <Button onClick={onExport} colorScheme={ isSwitchChecked ? 'green' : 'blue' }>Export</Button>
                </ModalFooter>
            </ModalContent>
            </Modal>
        </>
    )
}

export default ExportXLSXModal
