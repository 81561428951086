import "../../Styles/AnomalyTable.css"
import { useState, useEffect, useContext, createContext } from 'react'
import { Thead, Tbody, Tr, Th, Td, TableContainer, Checkbox, useColorModeValue, Text, HStack, Table, Tooltip} from '@chakra-ui/react'
import { WarningTwoIcon } from '@chakra-ui/icons'
import { TableContext } from '../../Data/Contexts/TableContext'
import CustomTH from './AnomalyTable/CustomTH'
import TableFeaturesBar from './AnomalyTable/TableFeaturesBar'
import AnomalyModal from './AnomalyTable/AnomalyModal'
import useAnomalySelect from '../../Custom_Hooks/useAnomalySelect'
import usePlaceHolder from "../../Custom_Hooks/usePlaceholder"
import useTableAnomalies from "../../Custom_Hooks/useTableAnomalies"

export const PaginationContext = createContext();

const AnomalyTable = ({ isModal, filter }) => {
    
    const placeholder = usePlaceHolder()
    const borderColor = useColorModeValue('#5e5e5e', '#878787')
    const headerBg = useColorModeValue("#ffffff", "#1A202C")
    const { anomalies } = useTableAnomalies(filter)
    const { checkboxInformation, isSwitchChecked, tabIndex, columnClick, arrow, filterArray } = useContext(TableContext)
    const { selectedAnomalies, isAllSelected, onSelectAllCheck, onAnomalyCheck } = useAnomalySelect(anomalies)
    const [ displayAnomalies, setDisplayAnomalies ] = useState([])
    const isContentAvailable = displayAnomalies?.length > 0
    const startIndex = 0
    const endIndex = displayAnomalies?.length

    useEffect(() => {

        if(selectedAnomalies){
            let filteredAnomalies = [...selectedAnomalies]
    
            filterArray.forEach(filter => {                
                filteredAnomalies = filterAnomalies(filter, filteredAnomalies)
            })
    
            setDisplayAnomalies(filteredAnomalies)
        }
        // eslint-disable-next-line
    }, [selectedAnomalies, tabIndex, filterArray])

    return (
        <PaginationContext.Provider value={{ anomalies: selectedAnomalies, startIndex, endIndex, isModal }}>
            <TableFeaturesBar />
            { !isContentAvailable && placeholder }
            {   
                isContentAvailable &&
                <>
                    <TableContainer height={isModal ? '100%' : '71vh'} overflowY={'auto'} overflowX='auto' mt={isModal && '10px'}>
                        <Table
                        size='sm'
                        variant='striped'
                        colorScheme={ isSwitchChecked ? 'green' : 'blue' }>
                            <Thead>
                                <Tr >
                                    <Th style={{ position: 'sticky', top: 0, backgroundColor: headerBg, zIndex: 1 }}>
                                        <input
                                        checked={isAllSelected}
                                        type="checkbox"
                                        id="mastercheck"
                                        onChange={(e) => onSelectAllCheck(e)}
                                        />
                                    </Th>
                                    {checkboxInformation.map(({ state, title }, index) => (
                                        <CustomTH key={index} show={state} id={title} onClick={columnClick} display={arrow}/>
                                    ))}
                                    <Th className='noselect' textAlign='center' style={{ position: 'sticky', top: 0, zIndex: 5, backgroundColor: headerBg }}>More</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {displayAnomalies.map((anomaly, index) => (
                                    <Tr key={index} >
                                        <Td>
                                            <Checkbox
                                            isChecked={anomaly.selected}
                                            borderColor={borderColor}
                                            onChange={(e) => onAnomalyCheck(e, anomaly)}
                                            />
                                        </Td>
                                        {checkboxInformation.map(({ state, func, warningTooltip }, index) => (
                                            state && 
                                            <Td key={index}>
                                            {   
                                                func(anomaly) ||
                                                <Tooltip label={ warningTooltip || "Property was not submitted with anomaly" }>
                                                    <WarningTwoIcon />
                                                </Tooltip>
                                            }
                                            </Td>
                                        ))}
                                        <Td textAlign='center'>
                                            <AnomalyModal anomaly={anomaly}/>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                    <HStack justifyContent={'flex-end'} margin={2}>
                        <Text fontSize={12} width={120} textAlign={'start'}>Showing: {startIndex + 1} - {endIndex > selectedAnomalies.length ? selectedAnomalies.length : endIndex} of {selectedAnomalies.length}</Text>
                    </HStack> 
                </>
            }
        </PaginationContext.Provider>
    )

    function filterAnomalies({value, comparator, filterFunc}, anomaliesToFilter){
        if(value){ //filter vals can be blank; they are not used
            switch (comparator) {
                case 'is':
                case 'equals':
                    return anomaliesToFilter.filter(anomaly => filterFunc(anomaly) === value)
                case 'is-not':
                case 'does-not-equal':
                    return anomaliesToFilter.filter(anomaly => filterFunc(anomaly) !== value)
                case 'contains':
                    return anomaliesToFilter.filter(anomaly => filterFunc(anomaly).includes(value))
                case 'does-not-contain':
                    return anomaliesToFilter.filter(anomaly => !(filterFunc(anomaly).includes(value)))
                case 'greater-than':
                    return anomaliesToFilter.filter(anomaly => filterFunc(anomaly) > value)
                case 'less-than':
                    return anomaliesToFilter.filter(anomaly => filterFunc(anomaly) < value)
                case 'before':
                    return anomaliesToFilter.filter(anomaly => compareDateValues(rearrangeDateValues(filterFunc(anomaly)), value))
                case 'on':
                    return anomaliesToFilter.filter(anomaly => rearrangeDateValues(filterFunc(anomaly)) === value)
                case 'after':
                    return anomaliesToFilter.filter(anomaly => compareDateValues(value, rearrangeDateValues(filterFunc(anomaly))))
                default:
                    return anomaliesToFilter
            }
        }
        return anomaliesToFilter
    }

    function compareDateValues(a, b){
        const aDate = new Date(a)
        const bDate = new Date(b)

        return aDate.valueOf() < bDate.valueOf()
    }

    function rearrangeDateValues(date){
        const [month, day, year] = date.split(",")[0].split("/")
        return `${year}-${month}-${day}`
    }
}

export default AnomalyTable