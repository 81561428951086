import { useEffect, useState } from 'react';
import { useAuth } from '../Data/Contexts/AuthContext';
import { Navigate, useLocation } from 'react-router-dom';
import ROUTES from '../Data/Constants/Routes';
import Loading from '../Components/PrivateRoute/Loading';

export default function PrivateRoute({ children, roles }){

  const location = useLocation();
  const { accountInfo: { role }, isAuthenticated } = useAuth();

  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isAuthenticated) { 
      setLoading(false);
    } else {
      setIsAuthorized(roles.includes(role));
      setLoading(false);
    };
  }, [role, roles, isAuthenticated]);

  if (loading) {
    return <Loading />;
  } else {
    if (!isAuthenticated){
      return <Navigate to={ROUTES.Login} state={{ from: location }} replace/>;
    };
  
    if (isAuthenticated && !isAuthorized) {
      return <Navigate to={ROUTES.AccessDenied} state={{ from: location }} replace/>;
    };
  };

  return children;
};