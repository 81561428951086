import { useState, useEffect } from 'react'
import { useDisclosure, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Text, ModalFooter } from '@chakra-ui/react'
import Anomalies from '../../Data/Firestore/anomalies';
import { AiOutlineEdit } from 'react-icons/ai';
import { FormSelect } from './FormInputs';
import CountiesByState from '../../Data/Constants/CountiesByState.json';
import { useAuth } from '../../Data/Contexts/AuthContext';
import COMMENTTYPE from '../../Data/Constants/AnomalyCommentType.json'

export function EditAnomalyCountyModal({ anomaly }){

  const { accountInfo } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [county, setCounty] = useState("Adams");
  const [state, setState] = useState("Indiana");
  const [countiesOptions, setCountiesOptions] = useState(CountiesByState[13].counties)
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let optObj = CountiesByState.filter(optionsObj => optionsObj.state === state)
    if(optObj[0]?.counties){
        setCountiesOptions(optObj[0].counties)
        setCounty(optObj[0].counties[0])
    } else {
        setCountiesOptions([])
        setCounty("No Options")
    }
    // eslint-disable-next-line
  }, [state])
  
  async function handleSave(){
    setLoading(true);
    let updateObj = { county, state };
    updateObj.comments = anomaly.comments;
    const countyModified = county !== anomaly.county
    const stateModified = state !== anomaly.state
    const modified = countyModified || stateModified

    if (modified){
      if (countyModified) {
        updateObj.comments.push({
          created: new Date(),
          creator: accountInfo.name,
          creatorEmail: accountInfo.email,
          creatorRole: accountInfo.role,
          editedProp: "County",
          originalValue: anomaly.county,
          newValue: county,
          type: COMMENTTYPE.PROPEDIT,
          subType: ''
        })
      }

      if (stateModified){
        updateObj.comments.push({
          created: new Date(),
          creator: accountInfo.name,
          creatorEmail: accountInfo.email,
          creatorRole: accountInfo.role,
          editedProp: "State",
          originalValue: anomaly.state,
          newValue: state,
          type: COMMENTTYPE.PROPEDIT,
          subType: ''
        })
      }
      
      await Anomalies.updateAnomalyField(anomaly.id, updateObj);
    }

    setLoading(false);
    onClose();
  }

  return (
    <>
      <AiOutlineEdit onClick={onOpen} style={{ cursor: 'pointer'}}/>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Anomaly County/State Modal</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize={16}>
              Current: {anomaly.county} County, {anomaly.state} 
            </Text>
            <FormSelect
            children={ CountiesByState.map((element, index) => ( <option key={index}>{element.state}</option>))}
            state={state}
            stateMod={setState}
            isUnsupervised
            />
            <FormSelect
            children={ countiesOptions?.map((element, index) => ( <option key={index}>{element}</option>))}
            state={county}
            stateMod={setCounty}
            isUnsupervised
            />
          </ModalBody>
          <ModalFooter>
            <Button variant='ghost' colorScheme='red' mr={3} onClick={onClose} isDisabled={loading}>Close</Button>
            <Button variant='outline' colorScheme='green' onClick={handleSave} isLoading={loading}>Confirm</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}