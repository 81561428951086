export const exists = (text) => text !== "";

export const smallBasic = (text) => `${text}`.length >= 3;

export const longBasicLimit = 20;
export const longBasic = (text) => `${text}`.length >= longBasicLimit;

export const locationCategory = (text) => ["In Person Absentee", "Precinct", "Satellite Office", "Unknown", "Vote Center", "Other"].includes(text)

export function validEmail(text){
    if(text){
        const hasAtSign = text.includes('@') 
        const hasAddress = text.split('@')[0]?.length >= 3
        const hasPeriod = text.split('@')[1]?.includes('.') 
        const hasDomain = text.split('@')[1]?.split('.')[0].length >= 2
        const hasSubDomain = text.split('.')[1]?.length >= 2
        return hasAtSign && hasAddress && hasPeriod && hasDomain && hasSubDomain
    } else {
        return false
    }
}

export function isPasswordValid(password){
    const lower = new RegExp("[a-z]")
    const upper = new RegExp("[A-Z]")
    const nums = new RegExp("[0-9]")
    const isValid = lower.test(password) && upper.test(password) && nums.test(password) && password.length >= 8
    return isValid;
}