import { useState, useEffect } from 'react'

export default function useAnomalySelect(anomalies){
  const [selectedAnomalies, setSelectedAnomalies] = useState([])
  const [isAllSelected, setIsAllSelected] = useState(false)
  
  useEffect(() => {
      setSelectedAnomalies(anomalies)
  }, [anomalies])
  
  function onSelectAllCheck(e){
      setIsAllSelected(e.target.checked)
      setSelectedAnomalies(selectedAnomalies.map((a) => ({...a, selected: e.target.checked})))
  }

  function onAnomalyCheck(e, anomaly){
      const tempArray = selectedAnomalies.map(a => {
          if (a.id === anomaly.id) {
              return {...a, selected: e.target.checked}
          } else {
              return a
          }
      })

      setSelectedAnomalies(tempArray)
      setIsAllSelected(tempArray.every(element => element.selected === true))
  }
  
  function deselectAll(){
      setIsAllSelected(false)
      setSelectedAnomalies(selectedAnomalies.map((a) => ({...a, selected: false})))
  }

  return {
    selectedAnomalies,
    isAllSelected,
    onSelectAllCheck,
    onAnomalyCheck,
    deselectAll
  }
}