import React from 'react'
import { Box, VStack, Grid, Container, Text } from '@chakra-ui/react';
import PacmanLoader from 'react-spinners/PacmanLoader'

const override = {
  marginTop: '50px'
};

const Loading = () => (
  <Box textAlign="center" fontSize="xl">
    <Grid minH="100vh" p={3}>
      <Container maxW='lg'>
        <VStack spacing={8} marginTop={5} marginBottom={'90px'} marginRight={'220px'}>
          <PacmanLoader color={'gray'} loading={true} size={70} cssOverride={override}/>
        </VStack>
        <Text marginBottom={30} fontSize='12px'>You should be redirected within 3 seconds...</Text>
      </Container>
    </Grid>
  </Box>
)

export default Loading