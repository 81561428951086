import { useState, useEffect } from 'react'
import { ArrowUpIcon, ArrowDownIcon } from '@chakra-ui/icons'

export default function useSortAnomalies(anomalies, checkboxInformation){
    
    const [sortedAnomalies, setSortedAnomalies] = useState([])
    const [sortDirection, setSortDirection] = useState(true)
    const [sortColumn, setSortColumn] = useState()
    
    useEffect(() => {
        sortAnomalies('Date Submitted', true)
        //eslint-disable-next-line
    }, [anomalies])

    function sortAnomalies(col, dir){
        let found = checkboxInformation.find(column => column.title === col)
        let sortTrait = found.func
        let sortFunc = () => (true) //don't change this! I have no idea why this fixed all the problems that were happening but it does.
                                    //previously it was () => {return 1}; Can imagine why that was a problem but am too lazy to actually figure it out
        if(found.sortFuncs){
            if( dir ){
                sortFunc = found.sortFuncs.ascending
            } else {
                sortFunc = found.sortFuncs.descending
            }
        } else {
            if ( dir ){ 
                sortFunc = (a,b) => (sortTrait(a).toLowerCase() >= sortTrait(b).toLowerCase())
            } else {
                sortFunc = (a,b) => (sortTrait(a).toLowerCase() <= sortTrait(b).toLowerCase())
            }
        }

        const toSort = [...anomalies]
        const sorted = toSort.sort(sortFunc)
        setSortedAnomalies(sorted)
    }
    
    function arrow(col){
        return (
            sortColumn === col 
            ? sortDirection 
                ? <ArrowUpIcon boxSize={'1.2rem'} focusable={false}/> 
                : <ArrowDownIcon boxSize={'1.2rem'} focusable={false}/> 
            : <ArrowUpIcon boxSize={'1.2rem'} color="transparent" focusable={false}/> 
        )
    }

    function columnClick(columnName){
        const direction = sortColumn === columnName ? !sortDirection : sortDirection
        sortAnomalies(columnName, direction)
        setSortColumn(columnName)
        setSortDirection(direction)
    }

    return { 
        sortedAnomalies, 
        arrow, 
        columnClick
    }
}