import { useEffect, useState, useRef } from 'react'
import { VStack, HStack, Flex, Divider, Spinner, IconButton, Text, Textarea, Badge, Button, Alert, AlertTitle, AlertDescription, 
    useDisclosure, Image, InputGroup, InputRightElement, FormControl, FormLabel, Input, ButtonGroup, Modal, ModalOverlay, ModalContent, ModalHeader, 
    ModalFooter, ModalBody, ModalCloseButton, Select } from "@chakra-ui/react";
import { ChevronLeftIcon, CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons'
import { useParams } from "react-router-dom";
import { getStatusFromStatusNum, getAnomalyAlertDescription } from "../Utils/TextFormatting";
import { useAuth } from '../Data/Contexts/AuthContext';
import { AiOutlineInfo, AiOutlineUser } from 'react-icons/ai' 
import { GoLocation } from 'react-icons/go'
//import STATES from '../Data/Constants/CountiesByState.json'
import ROLES from "../Data/Constants/Roles.json";
import STATUS from "../Data/Constants/AnomalyStatus.json";
import useNavToPage from "../Custom_Hooks/useNavToPage";
import Anomalies from '../Data/Firestore/anomalies';
import UserMenu from '../Components/Common/UserMenu';
import COMMENTTYPE from '../Data/Constants/AnomalyCommentType.json'
import { Comments } from '../Components/Common/Comments';
import { EditAnomalyCountyModal } from '../Components/Common/EditAnomalyCountyModal';
import { EditAnomalyClassAndRationaleModal } from '../Components/Common/EditAnomalyClassAndRationaleModal';


function EditButtonWModal({ handleEdit, status, title }) {

    const { isOpen, onOpen, onClose } = useDisclosure();
    const displayStatus = status ? Object.keys(STATUS)[status-1] : "Undefined Status";

    function handleOpen(){
        if(status === STATUS.Accepted || status === STATUS.Reported){
            onOpen()
        } else {
            handleEdit()
        }
    }

    function handleClose(){
        handleEdit()
        onClose()
    }

    return (
      <>
        <IconButton icon={<EditIcon />} onClick={handleOpen} size='sm'/>
  
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
                This Anomaly Has Been {displayStatus}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Text>As a reminder: this anomaly has been {displayStatus.toLowerCase()}, and does not require further action.</Text>
                { (status === STATUS.Accepted || status === STATUS.Reported) && <Text>However, information can still be modified <span style={{ fontStyle: "italic" }}>if necessary.</span></Text> }
            </ModalBody>
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onClose}>
                Cancel
              </Button>
              { (status === STATUS.Accepted || status === STATUS.Reported) && <Button variant='ghost' onClick={handleClose}>Edit {title}</Button> }
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
}

const AnomalyAttribute = ({ title, value, imgResource}) => (
    <Flex fontSize={14} alignItems="center">
        <Flex 
        marginRight={5} 
        width='10vw'>
            {title}:
        </Flex>
        <Flex 
        fontStyle='italic' 
        maxWidth='680px' 
        textAlign='left'
        marginRight={5}>
            {value}
        </Flex>
        {imgResource && 
        <Image 
        src={imgResource} 
        borderRadius='full'
        width="75px" 
        height="50px"/>
        }
    </Flex>
)

const AnomalyAttributeEditable = ({ title, value, updateKV, anomaly, long }) => {

    const { accountInfo } = useAuth();
    const [attribute, setAttribute] = useState(value)
    const [isEditMode, setIsEditMode] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setAttribute(value)
    }, [value])

    function handleChange(event){
        setAttribute(event.target.value)
    }
    
    function handleEdit(){
        setIsEditMode(true)
    }
    
    function handleCancel(){
        setAttribute(value)
        setIsEditMode(false)
    }
    
    async function handleSave(){
        if(attribute !== value){
            setLoading(true)
    
            let updateObj = updateKV(attribute)
            updateObj.comments = anomaly.comments
            updateObj.comments.push({
                created: new Date(),
                creator: accountInfo.name,
                creatorEmail: accountInfo.email,
                creatorRole: accountInfo.role,
                editedProp: title,
                originalValue: value,
                newValue: attribute,
                type: COMMENTTYPE.PROPEDIT,
                subType: long ? 'long' : ''
            })
    
            await Anomalies.updateAnomalyField(anomaly.id, updateObj)
    
            setLoading(false)
        }
        setIsEditMode(false)
    }
    
    return (
        <FormControl display={'flex'} >
            <FormLabel fontSize={14} marginRight={5} width='10vw' display={'flex'} alignItems={'center'}>
                {title}:
            </FormLabel>
            <InputGroup w={ long ? '40vw' : '20vw'} maxWidth='680px'>
                {
                    long
                    ? <Textarea _disabled={{ opacity: 0.8, cursor: 'not-allowed' }} fontSize={14} value={attribute} onChange={handleChange} isDisabled={!isEditMode} pr={ isEditMode ? '4rem' : '2rem'}/>
                    : <Input _disabled={{ opacity: 0.8, cursor: 'not-allowed' }} fontSize={14} value={attribute} onChange={handleChange} isDisabled={!isEditMode} />
                }
                <InputRightElement pr={ isEditMode ? 10 : 0 }>
                    {   loading
                        ?   <Spinner />
                        :   isEditMode 
                            ?   <ButtonGroup size='sm' isAttached variant='outline'>
                                    <IconButton aria-label='Save edit' icon={<CheckIcon />} onClick={handleSave} _hover={{ backgroundColor: '#22ff2230'}}/>
                                    <IconButton aria-label='Cancel edit' icon={<CloseIcon />} onClick={handleCancel} _hover={{ backgroundColor: '#ff222230'}}/>
                                </ButtonGroup>
                            :   <EditButtonWModal title={title} handleEdit={handleEdit} status={anomaly.status}/>
                    }
                </InputRightElement>
            </InputGroup>
        </FormControl>
    )
}

const AnomalyAttributeSelectEditable = ({ children, title, value, updateKV, anomaly }) => {

    const { accountInfo } = useAuth();
    const [attribute, setAttribute] = useState(value)
    const [isEditMode, setIsEditMode] = useState(false)
    const [loading, setLoading] = useState(false)

    function handleChange(event){
        setAttribute(event.target.value)
    }
    
    function handleEdit(){
        setIsEditMode(true)
    }
    
    function handleCancel(){
        setAttribute(value)
        setIsEditMode(false)
    }
    
    async function handleSave(){
        if(attribute !== value){
            setLoading(true)
    
            let updateObj = updateKV(attribute)
            updateObj.comments = anomaly.comments
            updateObj.comments.push({
                created: new Date(),
                creator: accountInfo.name,
                creatorEmail: accountInfo.email,
                creatorRole: accountInfo.role,
                editedProp: title,
                originalValue: value,
                newValue: attribute,
                type: COMMENTTYPE.PROPEDIT,
                subType: ''
            })
    
            await Anomalies.updateAnomalyField(anomaly.id, updateObj)
    
            setLoading(false)
        }
        setIsEditMode(false)
    }
    
    return (
        <FormControl display={'flex'}>
            <FormLabel fontSize={14} marginRight={5} width='10vw' display={'flex'} alignItems={'center'}>
                {title}:
            </FormLabel>
            <InputGroup w={'20vw'} maxWidth='680px'>
                <Select
                placeholder={value}
                value={attribute} 
                onChange={handleChange}
                isDisabled={!isEditMode}
                iconSize={"0"}
                _disabled={{
                    opacity: 0.8
                }}
                >
                    {children}
                </Select>
                <InputRightElement pr={ isEditMode ? 10 : 0 }>
                    {   loading
                        ?   <Spinner />
                        :   isEditMode 
                            ?   <ButtonGroup size='sm' isAttached variant='outline'>
                                    <IconButton aria-label='Save edit' icon={<CheckIcon />} onClick={handleSave} _hover={{ backgroundColor: '#00ff0050'}}/>
                                    <IconButton aria-label='Cancel edit' icon={<CloseIcon />} onClick={handleCancel} _hover={{ backgroundColor: '#ff000050'}}/>
                                </ButtonGroup>
                            :   <EditButtonWModal title={title} handleEdit={handleEdit} status={anomaly.status}/>
                    }
                </InputRightElement>
            </InputGroup>
        </FormControl>
    )
}

const SingleAnomaly = () => {

    const params = useParams();
    const onClick = useRef(() => {});
     
    //const [flagResource, setFlagResource] = useState("")
    const [anomaly, setAnomaly] = useState({});
    const [isUserVSTOPAndAnomalyIsNotYetReported, setIsUserVSTOPAndAnomalyIsNotYetReported] = useState(false);
    
    const { accountInfo } = useAuth();
    const { toAnomalyDashboard, toAdminDashboard, toLogin } = useNavToPage();

    useEffect(() => {
        async function fetchData(){
            await Anomalies.getSpecificAnomalyListener(
                params.anomalyId, 
                function(anomaly){
                    setAnomaly(anomaly)
                }
            )
        }
        fetchData()
    }, [params])

    useEffect(() => {
        if(accountInfo.role){
            setIsUserVSTOPAndAnomalyIsNotYetReported((accountInfo.role === ROLES.Vstop) && (anomaly.status !== STATUS.Reported));

            switch (accountInfo.role) {
                case ROLES.Vstop:
                case ROLES.Vendor:
                case ROLES.State:
                    onClick.current = toAnomalyDashboard
                    break
                case ROLES.Administrator:
                    onClick.current = toAdminDashboard
                    break
                default:
                    onClick.current = toLogin
                    break
            };
        } // eslint-disable-next-line
    }, [accountInfo, anomaly])

    return (
        <VStack textAlign={"center"} fontSize={"xl"}>
            <HStack spacing={20} margin={5}>
                <IconButton onClick={onClick.current} icon={<ChevronLeftIcon />}/>
                <Alert status={["info", 'success', 'error', 'warning'][anomaly.status - 1]} justifyContent='center' alignItems='flex-end' borderRadius={10} >
                    <AlertTitle>Current Status:</AlertTitle>
                    <AlertDescription>{getStatusFromStatusNum(anomaly.status)}</AlertDescription>
                    <Text fontSize={14} marginLeft={2}>{getAnomalyAlertDescription(anomaly.status, accountInfo?.role, anomaly.vendorName)}</Text>
                </Alert>
                <UserMenu />
            </HStack>
            <HStack alignItems='flex-start' width={'100%'} p={4}>
                <VStack width='50vw' alignItems='flex-start' marginX={4}>
                    <HStack>
                        <AiOutlineInfo />
                        <Text fontSize={12}>
                            {anomaly.id}
                        </Text>
                    </HStack>
                    <HStack>
                        <AiOutlineUser />
                        <Text fontSize={12}>
                            {anomaly.vendorName} - {anomaly.vendorEmail}
                        </Text>
                    </HStack>
                    <HStack>
                        <GoLocation />
                        <Text fontSize={12}>
                            {anomaly.county} County, {anomaly.state} 
                        </Text>
                        {
                            isUserVSTOPAndAnomalyIsNotYetReported &&
                            <EditAnomalyCountyModal anomaly={anomaly} />
                        }
                    </HStack>
                    <AnomalyAttributeEditable title="County Contact" value={anomaly.reporterName} updateKV={(aProp) => ({reporterName: aProp})} anomaly={anomaly}/>
                    <AnomalyAttributeEditable title="County Contact Email" value={anomaly.reporterEmail} updateKV={(aProp) => ({reporterEmail: aProp})} anomaly={anomaly}/>
                    <AnomalyAttributeSelectEditable title="Location Category" value={anomaly.locationCategory} updateKV={(aProp)=>({locationCategory: aProp})} anomaly={anomaly}>
                        <option value='County Election Office'>County Election Office</option>
                        <option value='In Person Absentee'>In Person Absentee Voting Location</option>
                        <option value='Precinct'>Precinct</option>
                        <option value='Satellite Office'>Satellite Office</option>
                        <option value="Unknown">Unknown</option>
                        <option value='Vote Center'>Vote Center</option>
                        <option value="Other">Other</option>
                    </AnomalyAttributeSelectEditable>
                    <AnomalyAttributeEditable title="Location Name" value={anomaly.locationName} updateKV={(aProp)=>({locationName: aProp})} anomaly={anomaly}/>
                    <AnomalyAttributeEditable title="Machine Name" value={anomaly.machineName} updateKV={(aProp)=>({machineName: aProp})} anomaly={anomaly}/>
                    <AnomalyAttributeEditable title="Machine Version" value={anomaly.machineVersion} updateKV={(aProp)=>({machineVersion: aProp})} anomaly={anomaly}/>
                    <AnomalyAttributeEditable title="Machine Component" value={anomaly.machineComponent} updateKV={(aProp)=>({machineComponent: aProp})} anomaly={anomaly}/>
                    <AnomalyAttributeEditable title="Serial Number" value={anomaly.machineSerial} updateKV={(aProp)=>({machineSerial: aProp})} anomaly={anomaly}/>
                    <Divider margin={2}/>
                    <AnomalyAttribute title="Anomaly Class" value={isUserVSTOPAndAnomalyIsNotYetReported ? <EditAnomalyClassAndRationaleModal anomaly={anomaly} /> : <Badge fontSize='1.2em'>{String(anomaly.class).includes("1") ? "Class 1" : "Class 2"}</Badge>} />
                    <AnomalyAttribute title="Rationale" value={anomaly.classRationale}/>
                    <AnomalyAttribute title="Issue" value={anomaly.description}/>
                    <AnomalyAttributeEditable title="Root Cause" value={anomaly.rootCause} updateKV={(aProp)=>({rootCause: aProp})} anomaly={anomaly} long />
                    <AnomalyAttributeEditable title="Resolution" value={anomaly.resolution} updateKV={(aProp)=>({resolution: aProp})} anomaly={anomaly} long/>
                </VStack>
                <VStack width='45vw'>
                    <Comments anomaly={anomaly} withButtonRack withRackSeparation/>
                </VStack>
            </HStack>
        </VStack>
    )
}

export default SingleAnomaly