import { useContext } from 'react'
import { HStack, Button, Menu, MenuButton, MenuList, Text, Select, IconButton, VStack, Input, Tooltip, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper} from '@chakra-ui/react'
import { ChevronDownIcon, DeleteIcon } from '@chakra-ui/icons'
import FilterMenu from './FilterMenu'
import { TableContext } from '../../../../Data/Contexts/TableContext'
import {DebounceInput} from 'react-debounce-input';

export default function FilterList() {

    const { filterInformation, filterArray, removeAllFilters } = useContext(TableContext) 

    return (
        filterArray.length > 0 &&
        <HStack margin={0} width={'100%'} justifyContent={'space-between'} style={{ zIndex: 2 }}>
            <HStack margin={0} padding={0} flexWrap={'wrap'}>
                {filterArray.map((filter, index) => (
                    <FilterItem key={index} {...filter} />
                ))}
                {filterInformation.length !== filterArray.length && <FilterMenu />}
            </HStack>
            <Button onClick={removeAllFilters} style={{ placeSelf: 'center end' }} size={'sm'}>
               Reset 
            </Button>
        </HStack>
    )
}

function FilterItem({ title, value, comparator, type }){
    
    const { filterArray, setFilterArray } = useContext(TableContext)

    return (
        <Menu>
            <MenuButton as={Button} variant={'outline'} size={'sm'} rightIcon={<ChevronDownIcon />} colorScheme={value ? 'green' : 'red'}>
                { value ? `${title} ${comparator.replaceAll('-', " ")} '${value}'` : title}
            </MenuButton>
            <MenuList p={2}>
                <VStack m={0} p={0}>
                    <HStack justifyContent={'space-evenly'} alignItems={'center'}>
                        <Text fontSize={12} style={{whiteSpace: 'nowrap'}}>
                            {title}
                        </Text>
                        <Select size={'xs'} width={'60%'} defaultValue={comparator} onChange={handleComparatorChange} style={{textTransform: 'capitalize'}}>
                            {comparatorOptions().map((opt, index) => (
                                <option key={index} value={opt} style={{textTransform: 'capitalize'}}>{`${opt}`.replaceAll("-", " ")}</option>
                            ))}
                        </Select>
                        <Tooltip label='Remove filter' placement='top' openDelay={1000}>
                            <IconButton m={0} p={0} icon={<DeleteIcon />} size={'xs'} variant={'outline'} colorScheme='red' onClick={removeFilter}/>
                        </Tooltip>
                    </HStack>
                    { 
                        type === 'text' && 
                        // <Input placeholder='Type a value...' onChange={handleValueChange} value={value} />
                        <DebounceInput
                        minLength={0}
                        debounceTimeout={450}
                        onChange={handleValueChange} /> 
                    }
                    { 
                        type === 'number' && 
                        <NumberInput defaultValue={0} min={0} onChange={handleValueChange}>
                            <NumberInputField />
                            <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    }
                    {
                        type === 'datetime' &&
                        <VStack>
                            <Input onChange={handleValueChange} type='date' />
                            {
                                comparator === 'within' &&
                                <Input onChange={handleValueChange} type='date' />
                            }
                        </VStack>
                    }
                </VStack>
            </MenuList>
        </Menu>
    )

    function handleComparatorChange(e){
        const mutableArr = [...filterArray]
        const filterIndex = mutableArr.findIndex(filter => filter.title === title) //Find index of object with title as its key
        const filter = mutableArr[filterIndex]
        mutableArr.splice(filterIndex, 1, {...filter, comparator: e.target.value })
        setFilterArray(mutableArr) //Set filter value to current value except for object's new comparator at specified index 
    }

    function handleValueChange(e){
        const mutableArr = [...filterArray]
        const filterIndex = mutableArr.findIndex(filter => filter.title === title) //Find index of object with title as its key
        const filter = mutableArr[filterIndex]
        mutableArr.splice(filterIndex, 1, {...filter, value: type === 'number' ? e : e.target.value.toLowerCase() })
        setFilterArray(mutableArr) //Set filter value to current value except for object's new value at specified index 
    }

    function removeFilter(){
        const mutableArr = [...filterArray]
        const filterIndex = mutableArr.findIndex(filter => filter.title === title) //Find index of object with title as its key
        mutableArr.splice(filterIndex, 1)
        setFilterArray(mutableArr) //Set filter value to current value without object at specified index 
        //setShow(false)
    }

    function comparatorOptions(){
        switch (type) {
            case 'datetime':
                return [
                    'before',
                    'on',
                    'after',
                    //'within',
                ]
            case 'number':
                return [
                    'equals',
                    'does-not-equal',
                    'greater-than',
                    'less-than',
                ]
            default:
                return [
                    'is',
                    'is-not',
                    'contains',
                    'does-not-contain',
                ]
        }
    }
}