import "../Styles/AnomalyDashboard.css"
import { Box, VStack, Grid, HStack, Heading, Button, Tabs, TabList, TabPanel, TabPanels, Container} from '@chakra-ui/react';
import { useContext } from 'react'
import { useAuth } from '../Data/Contexts/AuthContext';
import { TableContext } from '../Data/Contexts/TableContext'
import LogOutButton from '../Components/Common/LogOutButton';
import useNavToPage from '../Custom_Hooks/useNavToPage';
import UserMenu from '../Components/Common/UserMenu';
import CustomTab from '../Components/Common/CustomTab'
import AnomalyTable from '../Components/AnomalyDashboard/AnomalyTable'
import ReportsTable from '../Components/AnomalyDashboard/ReportsTable'
import STATUS from '../Data/Constants/AnomalyStatus.json'
import FILTER from '../Data/Constants/Filters.json'

const AnomalyDashboard = () => {

  const { toVendorDashboard } = useNavToPage();
  const { accountInfo, isVendor } = useAuth();
  const { tabIndex, setTabIndex } = useContext(TableContext)
    
  return (
    <>
      <Box textAlign="center" fontSize="xl">
        <Grid >
          <VStack spacing={1}>
            <HStack spacing={20}>
              {
                isVendor 
                ? <Button
                  variant="ghost"
                  colorScheme="blue"
                  onClick={toVendorDashboard}
                  >
                    Back to Lobby
                  </Button>
                : <LogOutButton />
              }
              <Heading>{`INSOS-ARP ${accountInfo?.role.toUpperCase()} Dashboard`}</Heading>
              <UserMenu />
            </HStack>
            <Container minWidth={'98vw'} className="no-top-margin">
              <Tabs onChange={(index) => setTabIndex(index)} defaultIndex={tabIndex} >
                <TabList>
                  <CustomTab status={STATUS.New}>
                    New
                  </CustomTab>
                  <CustomTab status={STATUS.Revised}>
                    {isVendor ? "Needs Response" : "Vendor to Revise"}
                  </CustomTab>
                  <CustomTab status={STATUS.Replied}>
                    {isVendor ? "VSTOP to Review Response" : "Vendor Has Revised"}
                  </CustomTab>
                  <CustomTab status={STATUS.Accepted} showBadges={false}>
                    Accepted, to be Reported
                  </CustomTab>
                  <CustomTab status={STATUS.Reported} show={!isVendor} showBadges={false}>
                    Reported
                  </CustomTab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <AnomalyTable filter={[FILTER.STATUS, STATUS.New]}/>
                  </TabPanel> 
                  <TabPanel>
                    <AnomalyTable filter={[FILTER.STATUS, STATUS.Revised]}/>
                  </TabPanel>
                  <TabPanel>
                    <AnomalyTable filter={[FILTER.STATUS, STATUS.Replied]}/>
                  </TabPanel>
                  <TabPanel>
                    <AnomalyTable filter={[FILTER.STATUS, STATUS.Accepted]}/>
                  </TabPanel>
                  <TabPanel>
                    <ReportsTable />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Container>
          </VStack>
        </Grid>
      </Box>
    </>
  );
};

export default AnomalyDashboard;
