import { Card, CardHeader, CardBody, Heading, Text, Stack, StackDivider, Box, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button} from "@chakra-ui/react"
import { uniqueFilter } from "../../Utils/AnomalyUtils"
import { months } from "../../Utils/TextFormatting"
import AnomalyTable from "./AnomalyTable"
import FILTER from '../../Data/Constants/Filters.json'
import useTableAnomalies from "../../Custom_Hooks/useTableAnomalies"

export default function ReportsTable() {

  const { anomalies } = useTableAnomalies([FILTER.ALLREPORTED, ''], true)

  return (
    <div style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      gap: '50px'
    }} >
      {
        anomalies?.map(anomaly => anomaly.report) //Extract report prop
        .filter(uniqueFilter) //Find all unique reports
        .sort(sortReportGroupNames)
        .map(reportGroupName => ({ name: reportGroupName, anomalies: anomalies.filter(anomaly => anomaly.report === reportGroupName)})) //add anomalies to unique reports; make object
        .map((reportGroup, index) => <ReportCard {...reportGroup} key={index}/>) //display report cards
      }
    </div>
  )

  function sortReportGroupNames(a, b){
    function groupNameVal(gName){
      const [month, year] = gName.split(" ")
      const monthIdx = months.findIndex(m => m === month)
      return Number(year) + (monthIdx/100)
    }
    
    return groupNameVal(b) - groupNameVal(a)
  }
}

function ReportCard({ name, anomalies }){

  const { isOpen, onOpen, onClose } = useDisclosure()

  const vsAnoms = anomalies.filter(a => a.type === 'VS')

  return (
    <>
      <Card _hover={{ backgroundColor: '#ffffff11', cursor: 'pointer'}} onClick={onOpen}>
        <CardHeader>
          <Heading size='md'>{name}</Heading>
        </CardHeader>
        <CardBody>
          <Stack divider={<StackDivider />} spacing='4'>
            <Box>
              <Heading size='xs' textTransform={'uppercase'}>
                Anomaly Count
              </Heading>
              <Text pt='2' fontSize='sm'>
                {anomalies.length}
              </Text>
            </Box>
            <Box>
              <Heading size='xs' textTransform={'uppercase'}>
                VS Count
              </Heading>
              <Text pt='2' fontSize='sm'>
                {vsAnoms.length}
              </Text>
            </Box>
            <Box>
              <Heading size='xs' textTransform={'uppercase'}>
                EPB Count
              </Heading>
              <Text pt='2' fontSize='sm'>
                {anomalies.length - vsAnoms.length}
              </Text>
            </Box>
          </Stack>
        </CardBody>
      </Card>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Anomaly Report - {name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AnomalyTable isModal filter={[FILTER.REPORT, name]}/>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' variant='outline' onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}