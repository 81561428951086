import React from 'react'
import { Button } from '@chakra-ui/react'
import useNavToPage from '../../Custom_Hooks/useNavToPage';
import { useAuth } from '../../Data/Contexts/AuthContext';

const NavButton = ({ to, children }) => {

    const { logout } = useAuth()
    const { navFunc } = useNavToPage(to);

    function handleClick(){
        logout()
        navFunc()
    }

    return (
        <Button onClick={handleClick}>{children}</Button>
    )
}

export default NavButton