import STATUS from "../Data/Constants/AnomalyStatus"
import ROLES from "../Data/Constants/Roles"
const ISO_8601_FULL = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i

export const months = ["January", "February", "March", "April", "May", "June", "July", "Augusut", "September", "October", "November", "December"]
export const monthsShortened = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
export const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday", ]

export function capitalizeFirstLetter(str){
    return str?.charAt(0).toUpperCase() + str?.slice(1)
}

export function getDateFromISO(isoDate){
    if(isoDate) return isoDate.split("T")[0]
    else return ""
}

export function getDateAndTimeFromISO(isoDate, shortVersion){ 
    if(isoDate && ISO_8601_FULL.test(isoDate)){
        
        const [date, time] = isoDate.split("T")
        const year = date.split("-")[0]
        const day = date.split("-")[2]
        const newDate = new Date(date)
        let meridian = "AM"
        let newHour = ""
        const [hour, minute] = time?.slice(-9).split(":")
        const hourInt = parseInt(hour)
        if(hourInt >= 12){
            meridian = "PM"
            newHour = hourInt !== 12 ? hourInt - 12 : 12
        } else {
            meridian = "AM"
            newHour = hour
        }
        if(shortVersion){
            return `${monthsShortened[newDate.getMonth()]} ${day} '${year.slice(2)} @ ${newHour}:${minute} ${meridian}`
        } else {
            return `${days[newDate.getDay()]}, ${months[newDate.getMonth()]} ${day} @ ${newHour}:${minute} ${meridian}`
        }
    }
    else return ""
}

export function getDateObjFromFirestoreTimestamp(fstimestamp){
    try{
        return fstimestamp?.toDate()
    } catch (e){
        return fstimestamp
    }
}

export function getDateFromFirestoreTimestamp(fstimestamp){
    try{
        return fstimestamp?.toDate().toLocaleString()
    } catch ( error ) {
        return "Could not convert"
    }
}

export function getAnomalyAlertDescription(statusNum, role, vendor){
    let direction = ''

    switch (role) {
        case ROLES.State:
            direction = "Comment if necessary"
            break;
        case ROLES.Vstop:
            if(statusNum === STATUS.New) direction = "Review Anomaly and Resolve"
            else if(statusNum === STATUS.Replied) direction = `Review ${vendor} response`
            else if(statusNum === STATUS.Accepted) direction = "No action at this time"
            else if(statusNum === STATUS.Revised) direction = `Await ${vendor} Response`
            else if(statusNum === STATUS.Reported) direction = "No action at this time"
            break
        case ROLES.Vendor:
            if(statusNum === STATUS.New) direction = "Await VSTOP review of anomaly"
            else if(statusNum === STATUS.Replied) direction = "Await VSTOP review of reply"
            else if(statusNum === STATUS.Accepted) direction = "No action at this time"
            else if(statusNum === STATUS.Revised) direction = "Review Comments and Respond"
            else if(statusNum === STATUS.Reported) direction = "No action at this time"
            break
        default:
            break;
    }

    return ` - ${direction}`
}

export function getStatusFromStatusNum(statusNum){
    for (const [key, value] of Object.entries(STATUS)){
        if(value === statusNum) return key
    }
}

export function normalizeText(text){ //this is bad, not sure why I made this
        if(text){
            text = text.toLowerCase()
            text = text.replace(/\s+/g, '') //remove space
            text = text.replace(/\./g, '') //remove periods
            text = text.replace(/!/g, '') //remove !
            text = text.replace(/\?/g, '') //remove ?
            text = text.replace(/-/g, '') //remove -
            text = text.replace(/,/g, '') //remove ,
            text = text.replace(/@/g, '') //remove @
            text = text.replace(/#/g, '') //remove #
            return String(text)
        } else {
            return ""
        }
}

export function getTooltipLabel(sizes){
    const { vs, epb } = sizes ? sizes : { vs: 0, epb: 0 }

    const anomEnding = (count) => count === 1 ? 'y' : 'ies'

    const vsString = `(${vs}) VS anomal${anomEnding(vs)} with NEW comments\n`
    const epbString = `(${epb}) EPB anomal${anomEnding(epb)} with NEW comments`
    const tooltipLabel = (vs > 0 ? vsString : "") + (epb > 0 ? epbString : "")

    return tooltipLabel
}

export function sanitizeAnomalyClass(classText){
    if (!classText) return ""
    const normalized = classText.toLowerCase()

    if (/\b(?:1|one)\b/.test(normalized)) return "Class 1"
    if (/\b(?:2|two)\b/.test(normalized)) return "Class 2"

    return "Class ?"
}

export function sanitizeLongText(longText){
    if (!longText) return ""
    return longText.replace(/\r?\n|\r/g, "")
}