import { useState } from 'react'
import { getDateFromFirestoreTimestamp } from '../Utils/TextFormatting';
import CountiesByState from '../Data/Constants/CountiesByState.json'
import Vendors from '../Data/Constants/Vendors.json'
import { HStack } from '@chakra-ui/react';
import { AiOutlineFolder } from "react-icons/ai";
import { CgNametag } from "react-icons/cg";
import { FaHashtag, FaMapMarkerAlt, FaRegPaperPlane } from "react-icons/fa";
import { GiCog } from "react-icons/gi";
import { GoVersions } from "react-icons/go";
import { GrRobot } from "react-icons/gr";
import { HiTag } from "react-icons/hi";
import { MdEmail } from "react-icons/md";
import { RiBarcodeBoxLine } from "react-icons/ri";
import { TbCalendarStats } from "react-icons/tb";
import { TiKeyOutline } from "react-icons/ti";

export default function useAnomalyPropDetails(startWithValue = true) {

    const [showAnomalyID, setShowAnomalyID] = useState(false)
    //const [showDescription, setShowDescription] = useState(false)
    //const [showRationale, setShowRationale] = useState(false)
    //const [showResolution, setShowResolution] = useState(false)
    //const [showRootCause, setShowRootCause] = useState(false)
    const [showAnomalyClass, setShowAnomalyClass] = useState(startWithValue && true)
    const [showCommentCount, setShowCommentCount] = useState(startWithValue && true)
    const [showCounty, setShowCounty] = useState(startWithValue && true)
    const [showDateTime, setShowDateTime] = useState(startWithValue && true)
    const [showReporterEmail, setShowReporterEmail] = useState(false)
    const [showReporterName, setShowReporterName] = useState(false)
    //const [showReporterDateTime, setShowReporterDateTime] = useState(false)
    const [showComponent, setShowComponent] = useState(startWithValue && true)
    const [showMachineName, setShowMachineName] = useState(startWithValue && true)
    const [showMachineVersion, setShowMachineVersion] = useState(startWithValue && true)
    const [showMachineSerial, setShowMachineSerial] = useState(false)
    //const [showVendorDatetime, setShowVendorDatetime] = useState(false)
    const [showVendorEmail, setShowVendorEmail] = useState(false)
    const [showVendorName, setShowVendorName] = useState(startWithValue && true)

    const checkboxInformation = [
        {
            icon: <TiKeyOutline />,
            state: showAnomalyID,
            stateMod: setShowAnomalyID,
            title: "Anomaly ID",
            func: (a) => (a.id),
            type: 'text',
            filterType: 'text',
        },
        {
            icon: <AiOutlineFolder />,
            state: showAnomalyClass,
            stateMod: setShowAnomalyClass,
            title: "Anomaly Class",
            func: (a) => (String(a.class).includes("1") ? "Class 1" : "Class 2"),
            type: 'select',
            options: ["Class 1", "Class 2"],
            filterType: 'text',
        },
        // {
        //     state: showRationale,
        //     stateMod: setShowRationale,
        //     title: "Class Rationale",
        //     func: (a) => (a.classRationale),
        //     type: 'text',
        // },
        {
            icon: <FaHashtag />,
            state: showCommentCount,
            stateMod: setShowCommentCount,
            title: "Comment Count",
            func: (a) => (a.comments.length),
            type: 'text',
            warningTooltip: "There are not yet comments for this anomaly",
            filterType: 'number',
        },
        {
            icon: <FaMapMarkerAlt />,
            state: showCounty,
            stateMod: setShowCounty,
            title: "County Affected",
            func: (a) => (a.county),
            type: 'select',
            options: CountiesByState[13].counties,
            filterType: 'text',
        },
        {
            icon: <FaRegPaperPlane />,
            state: showDateTime,
            stateMod: setShowDateTime,
            title: "Date Submitted",
            func: (a) => (getDateFromFirestoreTimestamp(a.timestamp)),
            filtFunc: (a) => (a.timestamp.toDate()),
            sortFuncs: {
                ascending: (a,b) => {
                    let aVal = a.timestamp.valueOf()
                    let bVal = b.timestamp.valueOf()
        
                    if(aVal > bVal){
                        return 1
                    } else if(aVal < bVal){
                        return -1
                    } else {
                        return 0
                    }
                },
                descending: (a,b) => {
                    let aVal = a.timestamp.valueOf()
                    let bVal = b.timestamp.valueOf()
        
                    if(bVal > aVal){
                        return 1
                    } else if(bVal < aVal){
                        return -1
                    } else {
                        return 0
                    }
                }
            },
            type: 'date',
            filterType: 'datetime',
        },
        // {
        //     state: showDescription,
        //     stateMod: setShowDescription,
        //     title: "Issue Description",
        //     func: (a) => (a.description),
        //     type: 'text',
        // },
        // {
        //     state: showResolution,
        //     stateMod: setShowResolution,
        //     title: "Issue Resolution",
        //     func: (a) => (a.resolution),
        //     type: 'text',
        // },
        // {
        //     state: showRootCause,
        //     stateMod: setShowRootCause,
        //     title: "Issue Root Cause",
        //     func: (a) => (a.rootCause),
        //     type: 'text',
        // },
        {
            icon: <GiCog />,
            state: showComponent,
            stateMod: setShowComponent,
            title: "Machine Component",
            func: (a) => (a.machineComponent),
            type: 'text',
            filterType: 'text',
        },
        {
            icon: <HiTag />,
            state: showMachineName,
            stateMod: setShowMachineName,
            title: "Machine Name",
            func: (a) => (a.machineName),
            type: 'text',
            filterType: 'text',
        },
        {
            icon: <RiBarcodeBoxLine />,
            state: showMachineSerial,
            stateMod: setShowMachineSerial,
            title: "Machine Serial #",
            func: (a) => (a.machineSerial),
            type: 'text',
            filterType: 'text',
        },
        {
            icon: <GoVersions />,
            state: showMachineVersion,
            stateMod: setShowMachineVersion,
            title: "Machine Version",
            func: (a) => (a.machineVersion),
            type: 'text',
            filterType: 'text',
        },
        // {
        //     icon: <TbCalendarStats />,
        //     state: showReporterDateTime,
        //     stateMod: setShowReporterDateTime,
        //     title: "County Reported Date & Time",
        //     func: (a) => (a.reporterDatetime),
        //     filtFunc: (a) => (new Date(a.reporterDatetime)),
        //     sortFuncs: {
        //         ascending: (a,b) => {
        //             let aVal = Date.parse(a.reporterDatetime)
        //             let bVal = Date.parse(b.reporterDatetime)
        
        //             if(aVal > bVal){
        //                 return 1
        //             } else if(aVal < bVal){
        //                 return -1
        //             } else {
        //                 return 0
        //             }
        //         },
        //         descending:  (a,b) => {
        //             let aVal = Date.parse(a.reporterDatetime)
        //             let bVal = Date.parse(b.reporterDatetime)
        
        //             if(aVal < bVal){
        //                 return 1
        //             } else if(aVal > bVal){
        //                 return -1
        //             } else {
        //                 return 0
        //             }
        //         }
        //     },
        //     type: "date",
        //     filterType: 'reporterDatetime',
        // },
        {
            icon: <MdEmail />,
            state: showReporterEmail,
            stateMod: setShowReporterEmail,
            title: "County Reporter Email",
            func: (a) => (a.reporterEmail),
            type: 'text',
            filterType: 'text',
        },
        {
            icon: <CgNametag />,
            state: showReporterName,
            stateMod: setShowReporterName,
            title: "County Reporter Name",
            func: (a) => (a.reporterName),
            type: 'text',
            filterType: 'text',
        },
        // {
        //     icon: <HStack gap={-5}><GrRobot /><TbCalendarStats /></HStack>,
        //     state: showVendorDatetime,
        //     stateMod: setShowVendorDatetime,
        //     title: "Vendor Reported Date & Time",
        //     func: (a) => (a.vendorDatetime),
        //     filtFunc: (a) => (new Date(a.vendorDatetime)),
        //     sortFuncs: {
        //         ascending: (a,b) => {
        //             let aVal = Date.parse(a.vendorDatetime)
        //             let bVal = Date.parse(b.vendorDatetime)
        
        //             if(aVal > bVal){
        //                 return 1
        //             } else if(aVal < bVal){
        //                 return -1
        //             } else {
        //                 return 0
        //             }
        //         },
        //         descending:  (a,b) => {
        //             let aVal = Date.parse(a.vendorDatetime)
        //             let bVal = Date.parse(b.vendorDatetime)
        
        //             if(aVal < bVal){
        //                 return 1
        //             } else if(aVal > bVal){
        //                 return -1
        //             } else {
        //                 return 0
        //             }
        //         }
        //     },
        //     type: 'date',
        //     filterType: 'reporterDatetime',
        // },
        {
            icon: <HStack gap={-5}><GrRobot /><TbCalendarStats /></HStack>,
            state: showVendorEmail,
            stateMod: setShowVendorEmail,
            title: "Vendor Email",
            func: (a) => (a.vendorEmail),
            type: 'text',
            filterType: 'text',
        },
        {
            icon: <HStack gap={-5}><GrRobot /><HiTag /></HStack>,
            state: showVendorName,
            stateMod: setShowVendorName,
            title: "Vendor Name",
            func: (a) => (a.vendorName),
            type: 'select',
            filterType: 'text',
            options: Vendors.vendors,
        }
    ]
    
  return ({ checkboxInformation })
}
