import React, { useContext } from 'react'
import { Menu, MenuButton, MenuList, MenuOptionGroup, MenuItemOption, IconButton } from '@chakra-ui/react'
import { BiColumns } from 'react-icons/bi'
import { TableContext } from '../../../../Data/Contexts/TableContext'
import "../../../../Styles/TableOptionsModal.css"

const CustomMenuItemOption = ({ label, inputMod, defaultChecked }) => {

    function handleClick(){
        inputMod(!defaultChecked)
    }

    return (
        <MenuItemOption isChecked={defaultChecked} onClick={handleClick} fontSize={12}>{label}</MenuItemOption>
    )
}

const DisplayTableColumnsPicker = () => {

    const { checkboxInformation } = useContext(TableContext)

    return (
        <Menu closeOnSelect={false} offset={[ -50, -40]} >
            <MenuButton as={IconButton} icon={<BiColumns />} />
            <MenuList minWidth='240px' zIndex={10}>
                <MenuOptionGroup title='Display Columns' type='checkbox'>
                    {checkboxInformation.sort(sortFunc).map(({ title, stateMod, state }, index) => (
                        <CustomMenuItemOption key={index} label={title} inputMod={stateMod} defaultChecked={state} />
                    ))}
                </MenuOptionGroup>
            </MenuList>
        </Menu>
    )

    function sortFunc(a,b){
        if( a.title === b.title){
            return 0
        } else if (a.title > b.title){
            return 1
        } else {
            return -1
        }
    }
}

export default DisplayTableColumnsPicker