import React, { createContext, useContext, useState } from 'react';
import { auth } from '../firebase';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import Accounts from '../Firestore/accounts';
import Vendors from '../Firestore/vendors.ts';
import ROLES from '../Constants/Roles.json'

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }) {

  const [currentUser, setCurrentUser] = useState({});
  const [accountInfo, setAccountInfo] = useState({});
  const [vendorInfo, setVendorInfo] = useState({});
  const [isContractor, setIsContractor] = useState(false);
  const [isState, setIsState] = useState(false);
  const [isVendor, setIsVendor] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  async function login(email, password) {
    try {
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      setCurrentUser(user);
      setIsAuthenticated(true);
  
      await Accounts.updateLastLogin(email);
  
      const account = await Accounts.getAccountByEmail(user.email);
      if(account.role === ROLES.Vendor){
        const vendor = await Vendors.getVendor(account.vendorId);
        setVendorInfo(vendor);
      };
  
      setIsContractor(account.designation === 'State Contractor');
      setIsState(account.role === ROLES.State);
      setIsVendor(account.role === ROLES.Vendor);
      setAccountInfo(account);
  
      return { success: true, account };
    } catch (err) {
      return { success: false, err }
    }
  }

  async function logout() {
    return await signOut(auth);
  }

  return (
    <AuthContext.Provider value={{
      login,
      logout,
      currentUser,
      accountInfo,
      vendorInfo,
      isContractor,
      isState,
      isVendor,
      isAuthenticated
    }}>
      {children}
    </AuthContext.Provider>
  );
}
