import { VStack, HStack, Flex, Text, Center, Button, Tooltip } from "@chakra-ui/react";
import { useState, useEffect} from "react";
import { longBasic, longBasicLimit } from "../../Utils/TextValidation";
import { useAuth } from "../../Data/Contexts/AuthContext";
import useReviewAnomaly from "../../Custom_Hooks/useReviewAnomaly";
import ROLES from "../../Data/Constants/Roles.json";
import STATUS from '../../Data/Constants/AnomalyStatus.json';
import Comment from "./Comment";
import { FormTextArea } from "./FormInputs";
import AcceptAnomalyButton from "./AcceptAnomalyButton";
import { DeleteAnomalyModal } from "./DeleteAnomalyModal";
import MarkAnomalyAsReportedModal from "./MarkAnomalyAsReportedModal";

export function Comments({ anomaly, withButtonRack, withRackSeparation}){

  const [comment, setComment] = useState('')
  const [isStateOrAdmin, setIsStateOrAdmin] = useState(false)
  const [isVSTOPAndAnomalyRevised, setIsVSTOPAndAnomalyRevised] = useState(false)
  const [isVendorAndAnomalyRevised, setIsVendorAndAnomalyRevised] = useState(false)
  const [isVSTOPAndAnomalyAccepted, setIsVSTOPAndAnomalyAccepted] = useState(false)
  const [isVSTOPAndAnomalyNewOrReplied, setIsVSTOPAndAnomalyNewOrReplied] = useState(false)

  const { newAnomalyComment, requestRevisionOfAnomaly, submitRevisionOfAnomaly, submitManualStatusChange, submitCommentLoading } = useReviewAnomaly(anomaly, comment)
  const { accountInfo } = useAuth();

  const userCanComment = isStateOrAdmin || isVendorAndAnomalyRevised || isVSTOPAndAnomalyNewOrReplied || isVSTOPAndAnomalyAccepted || isVSTOPAndAnomalyRevised;
  const isCommentValid = longBasic(comment);
  const stackHeight = withRackSeparation ? (userCanComment ? "60vh" : "85vh") : '100%'

  useEffect(() => {
    setComment("")
    //Since we set the comment manually later in the useEffect, and listen for changes to the anomaly, pre-emptively resetting it will 
    //not show the message in the comment box when the status is changed manually and the message box becomes visible again
    if (accountInfo.role) {
      const role = accountInfo.role
      setIsStateOrAdmin((role === ROLES.State) || (role === ROLES.Administrator))
      setIsVendorAndAnomalyRevised((role === ROLES.Vendor) && (anomaly.status === STATUS.Revised))
      setIsVSTOPAndAnomalyNewOrReplied((role === ROLES.Vstop) && ((anomaly.status === STATUS.New) || (anomaly.status === STATUS.Replied)))
      setIsVSTOPAndAnomalyAccepted((role === ROLES.Vstop) && (anomaly.status === STATUS.Accepted))
      setIsVSTOPAndAnomalyRevised((role === ROLES.Vstop) && (anomaly.status === STATUS.Revised))
      
      
      if ((role === ROLES.Vstop) && (anomaly.status === STATUS.Revised)) { 
        /*
        
        There should be no comment box visible, so we set its contents; if this bool state is true and the buttons are needed, 
        an anomaly is stuck in the vendor's inbox and all information has been resolved through prop changes.
        
        */
        setComment("Anomaly Status set to 'Vendor Has Replied'")
      }
    } 
    // eslint-disable-next-line
  }, [accountInfo, anomaly])

  return (
    <>
      <VStack height={stackHeight} overflow={'auto'} width='100%' gap={4} alignItems='flex-start' textAlign='left'>  
        { 
          anomaly.comments?.length > 0
          ? anomaly.comments.map((comment, index) => (
              <Comment key={index} {...comment} />
            ))
          : <Flex w={'100%'} mt={4} padding={2} justifyContent={'center'} alignItems={'center'}>
              <Text fontSize={16} fontStyle='italic' color='gray'>There are no comments to display at this time</Text>
            </Flex>
        }
      </VStack>
      { 
        (userCanComment && withButtonRack) &&   
        <Center maxW='100%' marginTop={8}>
          <VStack spacing={4} width="700px">
            {
              (!isVSTOPAndAnomalyAccepted && !isVSTOPAndAnomalyRevised) &&
              <FormTextArea 
              errorMessage=""
              helperText=""
              isSpellChecked
              isValid={isCommentValid}
              label=""
              placeholder="Enter comment here..."
              input={comment}
              inputMod={setComment}
              />
            }
            {   
              isStateOrAdmin && 
              <Button 
              isDisabled={!isCommentValid} 
              isLoading={submitCommentLoading} 
              onClick={newAnomalyComment}
              >
                Submit Comment
              </Button> 
            }
            { 
              isVendorAndAnomalyRevised && 
              <Button
              variant='outline'
              colorScheme={isCommentValid ? 'green' : 'red'}
              width='100%'
              onClick={submitRevisionOfAnomaly}
              isDisabled={!isCommentValid}
              isLoading={submitCommentLoading}
              >
                Submit Comment
              </Button>
            }
            {
              isVSTOPAndAnomalyRevised && 
              <Button
              variant='outline'
              colorScheme={'blue'}
              width='100%'
              onClick={submitManualStatusChange}
              isLoading={submitCommentLoading}
              >
                Set Anomaly Status to Replied
              </Button>
            }
            {
              isVSTOPAndAnomalyNewOrReplied && 
              <HStack>
                <AcceptAnomalyButton anomaly={anomaly} comment={comment} isCommentValid={isCommentValid} />
                <Tooltip label={!isCommentValid && `Revision comment is not long enough. ${comment.length}/${longBasicLimit} chars`}>
                  <div>
                    <Button
                    size='lg'
                    variant='outline'
                    colorScheme='red'
                    onClick={requestRevisionOfAnomaly}
                    isDisabled={!isCommentValid}
                    isLoading={submitCommentLoading}
                    >
                      Submit Revision Request
                    </Button>
                  </div>
                </Tooltip>
                <DeleteAnomalyModal anomaly={anomaly}/>
              </HStack>
            }
              {
                  isVSTOPAndAnomalyAccepted &&
                  <MarkAnomalyAsReportedModal anomaly={anomaly}/>
              }
          </VStack>
        </Center>
      }
    </>
  )
}