import { useEffect, useState } from 'react'
import { Badge, HStack, Text, VStack, Link, Alert, AlertIcon, AlertTitle, AlertDescription } from '@chakra-ui/react'
import { getDateFromFirestoreTimestamp, getDateObjFromFirestoreTimestamp } from '../../Utils/TextFormatting'
import { GrDocumentText } from "react-icons/gr";
import { formatDistance } from 'date-fns'
import ROLES from '../../Data/Constants/Roles'
import COMMENTTYPE from '../../Data/Constants/AnomalyCommentType.json'

export default function Comment(props){
  switch (props.type) {
    case COMMENTTYPE.PROPEDIT:
      return props.tags?.includes('bulk')
        ? <HStack>
            <Badge variant='outline' colorScheme={'gray'}>{'Bulk Upload'}</Badge>
            <PropEdit {...props} />
          </HStack>
        : <PropEdit {...props} />
    case COMMENTTYPE.ACCEPTANOMALY:
      return <AnomalyAccepted {...props} />
    case COMMENTTYPE.REPORTANOMALY:
      return <ReportedAnomaly {...props} />
    case COMMENTTYPE.MANUALSTATUSCHANGE:
      return <ManualStatuschange {...props} />
    default:
      return <StatusChange {...props} />
  }
}

export const AnomalyAccepted = ({ created }) => {
  return (
    <VStack alignItems='center' justifyContent='center' width='100%' >
      <Alert status='success' borderRadius='20px'>
        <AlertIcon />
        Accepted! - {getDateFromFirestoreTimestamp(created)}
      </Alert>
    </VStack>
  )
}

export const ReportedAnomaly = ({ created, text }) => {
  return (
    <VStack alignItems='center' justifyContent='center' width='100%' >
      <Alert status='info' borderRadius='20px' flexDirection={'column'}>
        <AlertIcon />
        <AlertTitle>
          Marked as Reported - {getDateFromFirestoreTimestamp(created)}
        </AlertTitle>
        <AlertDescription>
          <HStack m={2}>
            <GrDocumentText size={25} />
            <Text>{text}</Text>
          </HStack>
        </AlertDescription>
      </Alert>
    </VStack>
  )
}

export const ManualStatuschange = ({ text }) => {
  return (
    <VStack alignItems='center' justifyContent='center' width='100%' >
      <Alert status='warning' borderRadius='20px'>
        <AlertIcon />
        {/* <Text fontStyle='italic' fontSize={12}>{`${creator} - ${getDateFromFirestoreTimestamp(created)}`}</Text> */}
        <Text fontSize={14} textAlign='start'>{text}</Text>
      </Alert>
    </VStack>
  )
}

export const StatusChange=({ text, creator, created, creatorRole }) => {
  const [badgeColor, setBadgeColor] = useState('green')

  useEffect(() => {
    switch (creatorRole) {
      case ROLES.Vstop:
        setBadgeColor('red')
        break;
      case ROLES.Administrator:
          setBadgeColor('purple')
          break;
      case ROLES.State:
        setBadgeColor('blue')
        break;
      default:
        setBadgeColor('gray') 
        break;
    }
  }, [creatorRole])

  return (
    <VStack alignItems='flex-start' width={'100%'}>
      <HStack>
        <Badge variant='outline' colorScheme={badgeColor}>{creatorRole}</Badge>
        <Text fontStyle='italic' fontSize={12}>{`${creator} - ${getDateFromFirestoreTimestamp(created)}`}</Text>
      </HStack>
      <Text fontSize={14} textAlign='start'>{text}</Text>
    </VStack>
  )
}

export const PropEdit = ({ creator, editedProp, originalValue, newValue, subType, created, isImport }) => { 
  
  const textStyle = { fontStyle:'italic', fontSize:14, color:'gray', display: 'inline'}
  const linkStyle = { "_hover": {color: 'teal.500', textDecoration: 'underline'}}

  const [showOldValue, setShowOldValue] = useState(false)
  const [showNewValue, setShowNewValue] = useState(false)

  function toggleShowOldValue(){setShowOldValue(!showOldValue)}
  function toggleShowNewValue(){setShowNewValue(!showNewValue)}

  if(subType === 'long'){
    return (
      <Text {...textStyle}>
        {creator} edited {editedProp} from{" "}
        <Link {...linkStyle} onClick={toggleShowOldValue}>
          "{
            showOldValue 
            ? originalValue
            : `${originalValue.slice(0,15)}...`
          }"
        </Link>
        {" "}to{" "}
        <Link {...linkStyle} onClick={toggleShowNewValue}>
          "{
            showNewValue 
            ? newValue
            : `${newValue.slice(0,15)}...`
          }"
        </Link>
        {!isImport && `- ${formatDistance(getDateObjFromFirestoreTimestamp(created), new Date(), { addSuffix: true })}`}
      </Text>
    )
  } else {
    return <Text fontStyle='italic' fontSize={14} color='gray'>{`${creator} edited ${editedProp} from "${originalValue}" to "${newValue}"${!isImport ? ` - ${formatDistance(getDateObjFromFirestoreTimestamp(created), new Date(), { addSuffix: true })}` : ''}`}</Text>
  }
}