import { useState, useEffect } from "react";
import Vendors from "../Data/Firestore/vendors.ts";

export default function useFetchVendors(){

    const [vendors, setVendors] = useState([])
    const [vendorsLoading, setVendorsLoading] = useState(true)

    useEffect(() => {
        getVendors()
    }, [])

    async function getVendors(){
        const vendorsData = await Vendors.getAllVendors()
        setVendors(vendorsData)
        setVendorsLoading(false)
    }

    return { vendors, vendorsLoading }
}