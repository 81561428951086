import { useState, useEffect, useContext } from 'react'
import { FormControl, FormLabel, Badge, Switch } from '@chakra-ui/react'
import { useAuth } from '../../../../Data/Contexts/AuthContext'
import { TableContext } from '../../../../Data/Contexts/TableContext';

function TableTypeSwitch() {

  const { isContractor, isVendor, vendorInfo } = useAuth();
  const { toggleAnomalyType, isSwitchChecked } = useContext(TableContext)
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if(isContractor){
      setDisabled(true)
    } else {
      if(isVendor){
        if(!(vendorInfo.isVSVendor && vendorInfo.isEPBVendor)){
          setDisabled(true)
        }
      }
    }
  }, [isContractor, isVendor, vendorInfo])
  

  return (
    <FormControl display='flex' alignItems='center' marginLeft={8}>
        <FormLabel>
            <Badge colorScheme='blue'fontSize='1em'>VS</Badge>
        </FormLabel>
        <Switch size='lg' colorScheme='grey' onChange={toggleAnomalyType} isChecked={isSwitchChecked} isDisabled={disabled}/>
        <FormLabel marginLeft={4}>
            <Badge colorScheme='green' fontSize='1em'>EPB</Badge>
        </FormLabel>
    </FormControl>
  )
}

export default TableTypeSwitch