import { useState, useEffect } from 'react'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, IconButton, Text, Checkbox, FormControl, FormLabel, useDisclosure, useColorModeValue, VStack } from '@chakra-ui/react'
import { DeleteIcon } from '@chakra-ui/icons'
import { httpsCallable } from 'firebase/functions';
import { FormText } from '../../Common/FormInputs';
import { functions } from '../../../Data/firebase';
import useSystemToast from '../../../Custom_Hooks/useSystemToast';

const DeleteUserConfirmationModal = ({ user }) => {

    const borderColor = useColorModeValue('#5e5e5e', '#878787');

    const { isOpen, onOpen, onClose } = useDisclosure()
    const { errorToast } = useSystemToast()

    const [isChecked, setIsChecked] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isConfirmDeleteValid, setIsConfirmDeleteValid] = useState("")
    const [loadingText, setLoadingText] = useState("")
    const [confirmDeleteText, setConfirmDeleteText] = useState("")

    useEffect(() => {
        setIsConfirmDeleteValid(confirmDeleteText === user.name)
    }, [confirmDeleteText, user])
    
    function closeModal(){
        setLoadingText("")
        setConfirmDeleteText("")
        onClose()
    }

    function removeUser(){
        const retrieveUserInformation = httpsCallable(functions, "retrieveUserInformationv2")
        const deleteUserAuth = httpsCallable(functions, 'deleteUserAuthv2')
        const deleteUserAccount = httpsCallable(functions, 'deleteUserAccountv2')

        setIsLoading(true)
        setLoadingText('Retrieving User Information')

        retrieveUserInformation({ email: user.email })
        .then(({ data }) => {
            const { uid } = data
            setLoadingText(`Deleting ${uid} Auth`)
            deleteUserAuth({ userUID: uid })
            .then(() => {
                setLoadingText(`Deleting ${uid} Account`)

                deleteUserAccount({ email: user.email })
                .then(() => {
                    setLoadingText('Account Deleted Successfully')
                    onClose()
                })
                .catch(error => {
                    errorToast()
                })
                .finally(() => {
                    setIsLoading(false)
                })
            })
            .catch(error => {
                errorToast()
            })
        })
        .catch( error => {
            errorToast()
            setIsLoading(false)
        })
    }

    return (
        <>
            <IconButton 
            onClick={onOpen}
            aria-label="Delete register information entry"
            colorScheme='red'
            variant='outline'
            size='sm'
            icon={<DeleteIcon />}
            />    
            <Modal isOpen={isOpen} onClose={closeModal} size='xl'>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Delete {user?.name}@{user?.companyName}?</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>Warning! You are about to completely remove a user.</Text>
                    <Text paddingTop={2}>This action will delete the selected account's authorization, database record, 
                        and any verifications or customizations the account's user may have made.</Text>
                    <VStack spacing={2} paddingTop={2}>
                        <FormControl isRequired display="flex" paddingTop={3}>
                            <Checkbox
                            borderColor={borderColor}
                            marginRight={4}
                            onChange={e => { setIsChecked(e.target.checked) }}/>
                            <FormLabel>
                                I Understand, Continue. 
                            </FormLabel>
                        </FormControl>
                        <FormText
                        input={confirmDeleteText}
                        inputMod={setConfirmDeleteText}
                        isDisabled={!isChecked}
                        isUnsupervised
                        label={`Please type "${user?.name}" to fully confirm deletion.`}
                        placeholder={user?.name}/>
                        <Text color="#cdcdcd">{loadingText}{isLoading && '...'}</Text>
                    </VStack>
                </ModalBody>
    
                <ModalFooter>
                <Button colorScheme='blue' variant='outline' mr={3} onClick={closeModal}>
                    Close
                </Button>
                <Button colorScheme='red' variant='ghost' isDisabled={!isChecked || !isConfirmDeleteValid} isLoading={isLoading} onClick={removeUser}>Delete</Button>
                </ModalFooter>
            </ModalContent>
            </Modal>
        </>
    )
}

export default DeleteUserConfirmationModal
