import { useState, useEffect } from 'react';
import { Box, Text, VStack, Grid, Tabs, TabList, Tab, TabPanel, TabPanels, HStack, Heading } from '@chakra-ui/react';
import { ColorModeSwitcher } from '../Components/Common/ColorModeSwitcher';
import { useAuth } from '../Data/Contexts/AuthContext';
import Accounts from '../Data/Firestore/accounts';
import UsersTable from '../Components/AdminDashboard/UsersTable';
import CreateUserAccount from '../Components/AdminDashboard/CreateUserAccount';
import LogOutButton from '../Components/Common/LogOutButton';
import VendorManagement from '../Components/AdminDashboard/VendorManagement';

const AdminDashboard = () => {
  const { accountInfo } = useAuth();

  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await Accounts.getAccountsListener(accounts => {
        setAccounts(accounts);
        setLoading(false);
      });
    }

    setLoading(true)
    fetchData()
  }, []);

  return (
    <>
      <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3}>
          <VStack spacing={8}>
            <HStack spacing={20}>
              <LogOutButton />
              <Heading>INSOS-ARP Admin Dashboard</Heading>
              <ColorModeSwitcher />
            </HStack>
            <Text>{`Welcome, ${accountInfo.name}!`}</Text>
            <Tabs>
              <TabList>
                <Tab>Users</Tab>
                <Tab>Create User Account</Tab>
                <Tab>Manage Vendors</Tab>
              </TabList>
              <TabPanels
                maxW={'95vw'}
                minW={'95vw'}
                maxH={'71vh'}
                overflow="auto"
              >
                <TabPanel>
                  <UsersTable users={accounts} loading={loading} />
                </TabPanel>
                <TabPanel>
                  <CreateUserAccount />
                </TabPanel>
                <TabPanel>
                  <VendorManagement />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </VStack>
        </Grid>
      </Box>
    </>
  );
};

export default AdminDashboard;
