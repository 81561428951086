import states from '../Data/Constants/CountiesByState.json'
import STATUS from '../Data/Constants/AnomalyStatus.json'
import COMMENTTYPE from '../Data/Constants/AnomalyCommentType.json'
import AnomalyFactory from './AnomalyFactory'
import { v4 as uuidv4 } from 'uuid'

export default class Anomaly {

    //instantiation vars
    id = "DEFAULT"
    state = "DEFAULT"
    county = "DEFAULT"
    reporterDatetime = "DEFAULT"
    reporterName = "DEFAULT"
    reporterEmail = "DEFAULT"
    locationCategory = "DEFAULT"
    locationName = "DEFAULT"
    machineComponent = "DEFAULT"
    machineName = "DEFAULT"
    machineSerial = "DEFAULT"
    machineVersion = "DEFAULT"
    class = "DEFAULT"
    classRationale = "DEFAULT"
    description = "DEFAULT"
    rootCause = "DEFAULT"
    resolution = "DEFAULT"
    status = STATUS.New
    comments = []

    constructor(importData){
        this.id = String(uuidv4()).replaceAll('-',"").slice(0,20) //set id initially, gets rewritten if imported data includes it also
        for(const [key, val] of Object.entries(importData)){//only set values that exist in import data
            if(val){ //val is sometimes undefined
                this[key] = val 
                if(key === 'class'){
                    if(String(val).includes('1')){ this.class = 1 }
                    if(String(val).includes('2')){ this.class = 2 }
                }
            }
        }
    }


    //getters, setters, static methods
    get isValid(){
        return this.#invalidProps().length === 0
    }

    get isCriticallyValid(){
        return !(this.#invalidProps().some(val => [
            "Reporter Name", 
            "Reporter Email", 
            "Reporter DateTime", 
            'State', 
            'County', 
            'Location Category', 
            'Machine Name', 
            "Machine Version", 
            "Anomaly Class", 
            "Anomaly Class Rationale", 
            "Anomaly Description"].includes(val.title)))
    }

    get incompleteProps(){
        return this.#invalidProps()
    }

    get emptyProps(){
        return [
            this.state,
            this.county,
            this.reporterDatetime,
            this.reporterName,
            this.reporterEmail,
            this.locationCategory,
            this.locationName,
            this.machineComponent,
            this.machineName,
            this.machineSerial,
            this.machineVersion,
            this.class,
            this.classRationale,
            this.description,
            this.rootCause,
            this.resolution
        ].filter(prop => prop === 'DEFAULT' || prop === undefined || prop === '')
    }

    get uploadObject(){
        return {
            id: this.id,
            reporterName: this.reporterName,
            reporterEmail: this.reporterEmail,
            reporterDatetime: this.reporterDatetime,
            state: this.state,
            county: this.county,
            locationCategory: this.locationCategory,
            locationName: this.locationName,
            machineName: this.machineName,
            machineComponent: this.machineComponent,
            machineVersion: this.machineVersion,
            machineSerial: this.machineSerial,
            class: this.class,
            classRationale: this.classRationale,
            description: this.description,
            rootCause: this.rootCause,
            resolution: this.resolution,
            status: this.status,
            timestamp: new Date(),
            comments: [],
        }
    }

    get identifier(){
        return `${this.machineName}/${this.machineVersion}`
    }

    static compare(oldAnomaly, newAnomaly, {name, email, role}){
        let comments = []
        for(const [key, newVal] of Object.entries(newAnomaly)){
            const oldVal = oldAnomaly[key]
            if(oldVal !== newVal){
                comments.push({
                    created: new Date(),
                    creator: name,
                    creatorEmail: email,
                    creatorRole: role,
                    editedProp: key,
                    originalValue: oldVal,
                    newValue: newVal,
                    type: COMMENTTYPE.PROPEDIT,
                    subType: ['description', 'rationale', 'resolution', 'rootCause'].includes(key) ? 'long' : '',
                    tags: ['bulk-upload']
                })
            }
        }
        return comments
    }

    static diffComments(anomaly, prev, includePreviousComments, account){
        const correctlyHeaderedAnomaly = AnomalyFactory.convert(prev, AnomalyFactory.DataMode.AnomalyClass, AnomalyFactory.DataMode.Firebase)
        const diffComments = Anomaly.compare(correctlyHeaderedAnomaly, anomaly, account)
        return includePreviousComments ? [...prev.comments, ...diffComments] : diffComments
    }


    //other methods
    #invalidProps(){
        return [
            {
                title: 'Reporter Datetime',
                test: this.#_isDateInstanceOrParseableDate(this.reporterDatetime),
                message: `Not parseable: ${this.reporterDatetime}\nAccepted date formats:\n\tMM-DD-YYYY\n\tMM/DD/YYYY\n\tM-D-YYYY\n\tM/D/YYYY\n\tMonth DD, YYYY\n\tMonth DD YYYY\n\tMon DD, YYYY\n\tMon DD YYYY\n\tMon D, YYYY\n\tMon D YYYY\nAccepted time formats:\n\t01:00 pm\n\t1:00 pm\n\t1:00 p\n\t1:00p`,
                isCritical: true
            },
            {
                title: 'Reporter Email',
                test: this.#_isValidEmail(this.reporterEmail),
                message: `Not formatted correctly: ${this.reporterEmail}`,
                isCritical: true
            },
            {
                title: 'Reporter Name',
                test: this.#_isStringAndHasArbitraryValue(this.reporterName),
                message: `Does not exist, is empty, or DEFAULT: ${this.reporterName}`,
                isCritical: true
            },
            {
                title: 'State',
                test: this.#_isStateNameOrCode(this.state),
                message: `Name or code not recognized: ${this.state}`,
                isCritical: true
            },
            {
                title: 'County',
                test: this.#_isValidCounty(this.county),
                message: `Not recognized, or does not exist within ${this.state}: ${this.county}`,
                isCritical: true
            },
            {
                title: 'Location Category',
                test: ["county election office", "in person absentee", "precinct", "satellite office", "unknown", "vote center", "other"].includes(String(this.locationCategory).toLowerCase()),
                message: `Expected: County Election Office, In Person Absentee, Precinct, Satellite Office, Unknown, Vote Center, or Other\nReceived: ${this.locationCategory}`,
                isCritical: true
            },
            {
                title: 'Location Name',
                test: this.#_isStringAndHasArbitraryValue(this.locationName),
                message: `Does not exist, is empty, or DEFAULT: ${this.locationName}`
            },
            {
                title: 'Machine Component',
                test: this.#_isStringAndHasArbitraryValue(this.machineComponent),
                message: `Does not exist, is empty, or DEFAULT: ${this.machineComponent}`
            },
            {
                title: 'Machine Name',
                test: this.#_isStringAndHasArbitraryValue(this.machineName),
                message: `Does not exist, is empty, or DEFAULT: ${this.machineName}`,
                isCritical: true
            },
            {
                title: 'Machine Serial',
                test: this.#_isStringAndHasArbitraryValue(this.machineSerial),
                message: `Does not exist, is empty, or DEFAULT: ${this.machineSerial}`
            },
            {
                title: 'Machine Version',
                test: this.#_isStringAndHasArbitraryValue(this.machineVersion),
                message: `Does not exist, is empty, or DEFAULT: ${this.machineVersion}`,
                isCritical: true
            },
            {
                title: 'Anomaly Class',
                test: [1, 2, "1", "2"].includes(this.class),
                message: `Expected: 1 or 2\nReceived: ${this.class}`,
                isCritical: true
            },
            {
                title: 'Anomaly Class Rationale',
                test: this.#_isStringAndHasArbitraryValue(this.classRationale),
                message: `Does not exist, is empty, or DEFAULT: ${this.classRationale}`,
                isCritical: true
            },
            {
                title: 'Anomaly Description',
                test: this.#_isStringAndHasArbitraryValue(this.description),
                message: `Does not exist, is empty, or DEFAULT: ${this.description}`,
                isCritical: true
            },
            {
                title: 'Anomaly Root Cause',
                test: this.#_isStringAndHasArbitraryValue(this.rootCause),
                message: `Does not exist, is empty, or DEFAULT: ${this.rootCause}`
            },
            {
                title: 'Anomaly Resolution',
                test: this.#_isStringAndHasArbitraryValue(this.resolution),
                message: `Does not exist, is empty, or DEFAULT: ${this.resolution}`
            }
        ].filter(val => !val.test)
    }
    
    #_isStringAndHasArbitraryValue(str){
        return typeof str === 'string' && str.length > 0 && str !== 'DEFAULT'
    }

    #_isValidEmail(str){
        if(this.#_isStringAndHasArbitraryValue(str)){
            const hasAtSign = str.includes('@') 
            const hasAddress = str.split('@')[0]?.length >= 1
            const hasPeriod = str.split('@')[1]?.includes('.') 
            const hasDomain = str.split('@')[1]?.split('.')[0].length >= 1
            const hasSubDomain = str.split('@')[1]?.split('.')[1]?.length >= 1
            return hasAtSign && hasAddress && hasPeriod && hasDomain && hasSubDomain
        } else {
            return false
        }
    }   
    
    #_isStateNameOrCode(str){
        if(this.#_isStringAndHasArbitraryValue(str)){
            if(str.length === 2){
                //str is a state code
                const found = states.find(stateData => stateData.abbr.toLowerCase() === this.state.toLowerCase())?.state
                if(found){
                    //Automatically set the class's internal state to full name after verification of code
                    this.state = found
                    return true
                } else {
                    return false
                }
            } else {
                return this.#_isStringAndHasArbitraryValue(states.find(stateData => stateData.state.toLowerCase() === this.state.toLowerCase())?.state)
            }
        } else {
            return false
        }
    }

    #_isValidCounty(str){
        if(this.#_isStringAndHasArbitraryValue(str)){
            if (this.state.length === 2){
                //state code, search abbreviations
                return this.#_isStringAndHasArbitraryValue(states.find(state => state.abbr.toLowerCase() === this.state.toLowerCase())?.counties.find(county => county.toLowerCase() === this.county.toLowerCase()))
            } else {
                return this.#_isStringAndHasArbitraryValue(states.find(state => state.state.toLowerCase() === this.state.toLowerCase())?.counties.find(county => county.toLowerCase() === this.county.toLowerCase()))
            }
        } else { 
            return false 
        }
    }

    #_isDateInstanceOrParseableDate(d){
        if(d instanceof Date){
            return true
        } else {
            const fullDateCodeRegex = /(^[1-9]|0[1-9]|1[0,1,2])(\/|-)([1-9]|0[1-9]|[12][0-9]|3[01])(\/|-)(19|20)\d{2}/
            // mm-dd-yyyy
            // m-d-yyyy
            // mm/dd/yyyy
            // m/d/yyyy 
            
            const monthNameDayYearRegex = /(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?) (0[1-9]|[12][0-9]|3[01]|[0-9]),? (19|20)\d{2}/i
            // month dd, yyyy
            // month dd yyyy
            // mon dd, yyyy
            // mon dd yyyy

            const timeRegex = /(0[1-9]|1[0,1,2]|[1-9]):[0-5][0-9] ?[ap][m]?/i
            // 01:00 pm
            // 1:00 pm
            // 1:00 p
            // 1:00p

            return (fullDateCodeRegex.test(d) || monthNameDayYearRegex.test(d)) && timeRegex.test(d)
        }
    }
}