import { useState } from 'react'
import Anomalies from '../Data/Firestore/anomalies'
import STATUS from '../Data/Constants/AnomalyStatus.json'
import useSystemToast from '../Custom_Hooks/useSystemToast'
import { useAuth } from '../Data/Contexts/AuthContext'
import { buildCommentLink } from '../Utils/AnomalyUtils'
import { getAllUniqueCommentEmails } from '../Utils/AnomalyUtils'
import useSendEmail from './useSendEmail'
import COMMENTTYPE from '../Data/Constants/AnomalyCommentType.json'

export default function useReviewAnomaly(anomaly, newCommentText){

    const { accountInfo } = useAuth()
    const { errorToast } = useSystemToast()
    const { sendNewCommentEmail } = useSendEmail()
 
    const [submitCommentLoading, setSubmitCommentLoading] = useState(false)

    async function submitStatusChangeAndNewComment(status, commentType, commentSubType){
        setSubmitCommentLoading(true)

        try {
            const newCommentAndStatus = { 
                status,
                comments: [
                    ...anomaly.comments, 
                    {
                        created: new Date(),
                        creator: accountInfo.name,
                        creatorEmail: accountInfo.email,
                        creatorRole: accountInfo.role,
                        text: newCommentText,
                        type: commentType,
                        subType: commentSubType || '',
                    }
                ],
                report: newCommentText
            }

            if (status !== STATUS.Reported) delete newCommentAndStatus.report
            
            await Anomalies.updateAnomalyField(anomaly.id, newCommentAndStatus)

            if(process.env.NODE_ENV === 'production'){ //TODO REMOVE THIS SHIT (checking node env)
                const emails = getAllUniqueCommentEmails(anomaly, accountInfo.email)
                const link = buildCommentLink(anomaly)
                await sendNewCommentEmail(emails, accountInfo.name, accountInfo.companyName, anomaly.id, newCommentText, link)
            }
        } catch (error) {
            errorToast()
        }

        setSubmitCommentLoading(false)
    }

    function newAnomalyComment(){
        submitStatusChangeAndNewComment(anomaly.status, COMMENTTYPE.COMMENT)
    }

    function acceptAnomaly(){
        submitStatusChangeAndNewComment(STATUS.Accepted, COMMENTTYPE.ACCEPTANOMALY)
    }

    function requestRevisionOfAnomaly(){
        submitStatusChangeAndNewComment(STATUS.Revised, COMMENTTYPE.STATUSCHANGE)
    }

    function submitRevisionOfAnomaly(){
        submitStatusChangeAndNewComment(STATUS.Replied, COMMENTTYPE.STATUSCHANGE)
    }

    function submitManualStatusChange(){
        submitStatusChangeAndNewComment(STATUS.Replied, COMMENTTYPE.MANUALSTATUSCHANGE)
    }
    
    function markAnomalyAsReported(){
        submitStatusChangeAndNewComment(STATUS.Reported, COMMENTTYPE.REPORTANOMALY)
    }

    return { newAnomalyComment, acceptAnomaly, requestRevisionOfAnomaly, submitRevisionOfAnomaly, markAnomalyAsReported, submitManualStatusChange, submitCommentLoading }
}