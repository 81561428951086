import { useToast } from '@chakra-ui/react';

export default function useSystemToast() {
  const toast = useToast();

  function errorToast(process) {
    if (!process) process = "There was an error"
    toast({
      title: 'Failure...',
      description:
        `${process}. Please wait a moment before trying again, or contact support.`,
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
  }

  function successToast(process) {
    if (!process) process = 'Process';
    toast({
      title: 'Success!',
      description: `${process} completed successfully.`,
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
  }

  function infoToast() {
    toast({
      title: 'Info',
      description: 'Completed action',
      status: 'info',
      duration: 1000,
    });
  }
  return { errorToast, successToast, infoToast };
}
