import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Link,
    Flex,
    VStack,
    Text,
    Heading
} from '@chakra-ui/react';

const TermsAndConditionsModal = ({ linkColor }) => {

    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <>
            <Link color={linkColor} onClick={onOpen}>Terms and Conditions</Link>
            <Modal isOpen={isOpen} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Terms and Conditions</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex flexDirection="column" justify="space-evenly" align="center" >
                            <VStack spacing={35} marginBottom={10}>
                                <TermOrCondition 
                                heading='INSOS-ARP Terms and Conditions ("Terms")'
                                text='Please read these Terms and Conditions ("Terms," "Terms and Conditions") carefully before using the http://www.vstop.in.gov website (the "Service") operated by Voting System Technical Oversight Program (hereafter, “VSTOP,” “Program,” us," "we" or "our").
                                Your access to and use of the Service depends on your acceptance of, and compliance with, these Terms. These Terms apply to all visitors, users and others who access or use the Service. Failure to comply with the Terms and Conditions outlined herein will result in a denial to the Service.
                                By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms, you may not access the Service. '
                                />
                                <TermOrCondition
                                heading="Accounts"
                                text='When you create an account with us, you must provide information that is accurate, complete and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account for our Service.
                                You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service.
                                You further agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.'
                                />
                                <TermOrCondition
                                heading="Non-Discolsure Agreement"
                                text="In accordance with the trust placed in us by the State of Indiana, Ball State University, our partners, vendors, VSTOP, you, as an INSOS-ARP user, are responsible for maintaining the confidentiality of the data and information with which you work and for keeping data secure and 
                                accessible only to those who have a right to this information. You will routinely have access to highly sensitive information that could be considered unusual or of interest to other individuals, both inside and outside of the Program, which includes, but is not limited to, proprietary 
                                information on Voting Systems and Electronic Poll Books. Because of the sensitive nature of information accessible to personnel within INSOS-ARP, you must meet the highest standards possible for managing the Program’s information in a secure and professional manner. Any data, software 
                                application, or other information not designated as “Public Record” is considered confidential and cannot be disclosed to anyone not directly involved with the Program, its partners or current vendors. Moreover, no proprietary information belonging to vendors may be shared with other 
                                vendors or anyone not having a legal right to such information. Every user in INSOS-ARP is responsible for maintaining the confidentiality of data to which they may have access through privileged administrator rights. This includes protecting data from those who do not have authorization 
                                to see or to access this information. No unauthorized user may see, hear or use user data without the written permission of the data owner or as authorized in writing by a senior administrator with the authority to grant access. You may not disclose confidential information to unauthorized 
                                persons in any manner of communication (e.g. by file transfer, through digital, written and oral communication, or other means of disclosure). You may not knowingly erase a data record or a data entry from any record, report or file. You may not remove any official record, report, file 
                                or copy of an official record or report from the office where it is maintained except in the performance of official duties. Regardless of whether you are working with official records, files or copies of official records within the office, off-site, or remotely, the same levels of 
                                security, confidentiality and non-disclosure must be followed to protect information. "
                                textAlt="If at any time data under the responsibility of INSOS-ARP is thought to be compromised, VSTOP must be immediately notified. The act of intentionally disclosing user data and/or information to unauthorized persons or causing information to be compromised through gross negligence 
                                will be grounds for immediate termination from INSOS-ARP."
                                textAltTwo="I have read the above Terms and Conditions and understand these as an INSOS-ARP user. "
                                />
                                <TermOrCondition
                                heading="Termination"
                                text="We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if the Terms and Conditions are violated.
                                Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service. "
                                />
                                <TermOrCondition
                                heading="Intellectual Property Rights"
                                text="Other than the content you own, under these Terms and Conditions, VSTOP and/or the state of Indiana own all the intellectual property rights and materials contained in the INSOS-ARP.
                                You are granted limited license only for purposes of viewing the material contained on this Website. "
                                />
                                <TermOrCondition
                                heading="Limitation of Liability"
                                text="In no event shall VSTOP, nor any of its officers, directors and employees, be held liable for anything arising out of or in any way connected with your use of this Website whether or not such liability is under contract. VSTOP, including its officers, directors and employees 
                                shall not be held liable for any indirect, consequential or special liability arising out of or in any way related to your use of the INSOS-ARP. "
                                />
                                <TermOrCondition
                                heading="Governing Law"
                                text="These Terms and Conditions shall be governed and construed in accordance with the laws of Indiana, and the laws of the United States, without regard to its conflict of law provisions. "
                                textAlt="Our failure to enforce any right or provision of these Terms and Conditions will not be considered a waiver of those rights. If any provision of these Terms and Conditions is held to be invalid or unenforceable by a court, the remaining provisions of these Terms and Conditions will 
                                remain in effect. These Terms and Conditions constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us and other parties regarding the Service. "
                                />
                                <TermOrCondition
                                heading="Changes"
                                text="We reserve the right, at our sole discretion, to modify or replace these Terms and Conditions at any time. If a revision is material, we will try to provide at least 15 days' notice prior to any new terms and conditions taking effect. What constitutes a material change will be determined at our sole discretion."
                                textAlt="By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service. "
                                />
                                <TermOrCondition
                                heading="Contact Us"
                                text="If you have any questions about these Terms and Conditions, please contact us. "
                                />
                            </VStack>
                        </Flex>                
                    </ModalBody>
                    <ModalFooter>
                    <Button colorScheme='red' mr={3} onClick={onClose}>
                        Close
                    </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

const TermOrCondition = ({ heading, text, textAlt, textAltTwo}) => (
    <VStack alignItems='flex-start' spacing={5} >
        <Heading fontSize={18}>{heading}</Heading>
        <Text fontSize={16} textAlign='left'>{text}</Text>
        <Text fontSize={16} textAlign='left'>{textAlt}</Text>
        <Text fontSize={16} textAlign='left'>{textAltTwo}</Text>
    </VStack>
)

export default TermsAndConditionsModal
