import { useState, useEffect } from 'react'
import { Box, VStack, Grid, Container, Button, Heading, Text } from '@chakra-ui/react';
import { FormPassword } from '../Components/Common/FormInputs';
import { ColorModeSwitcher } from '../Components/Common/ColorModeSwitcher';
import { isPasswordValid } from '../Utils/TextValidation'; 
import { updatePassword } from 'firebase/auth';
import { useAuth } from '../Data/Contexts/AuthContext';
import useSystemToast from '../Custom_Hooks/useSystemToast';
import useNavToPage from '../Custom_Hooks/useNavToPage';
import useSendEmail from '../Custom_Hooks/useSendEmail';

const PasswordCreation = () => {
    
    const { currentUser, accountInfo } = useAuth()
    const { successToast, errorToast } = useSystemToast()
    const { sendNewUserPasswordChange } = useSendEmail()
    const { toVendorDashboard, toAnomalyDashboard, toAdminDashboard } = useNavToPage()

    const [password, setPassword] = useState("")
    const [isValid, setIsValid] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState("")
    const [isConfirmValid, setIsConfirmValid] = useState("")
    const [isPasswordConsistent, setIsPasswordConsistent] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsValid(isPasswordValid(password))
        setIsConfirmValid(isPasswordValid(confirmPassword))
        setIsPasswordConsistent(password === confirmPassword) //eslint-disable-next-line
    }, [password, confirmPassword])

    async function changePassword(){
        setIsLoading(true)
        try{
            await updatePassword(currentUser, password)
            successToast("Create Password")
            sendNewUserPasswordChange(accountInfo.name)

            switch (accountInfo.role) {
                case "vstop":
                    toAnomalyDashboard()
                    break
                case "state":
                    toAnomalyDashboard()
                    break
                case "admin":
                    toAdminDashboard()
                    break
                default:
                    toVendorDashboard()
                    break;
            }
        } catch (error) {
            errorToast()
        }
        setIsLoading(false)
    }
        
    return (
        <Box textAlign="center" fontSize="xl">  
            <Grid minH="100vh" p={3}>
                <ColorModeSwitcher justifySelf="flex-end" />
                <Container maxW='lg'>
                    <VStack spacing={8}>
                        <Heading>Welcome to INSOS-ARP!</Heading>
                        <Text>Please take this time to create a unique password for your new account.</Text>
                        <FormPassword
                            errorMessage="Password is required"
                            input={password}
                            inputMod={setPassword}
                            isValid={isValid}
                            placeholder="Enter password"
                            stateArray={password}
                        />
                        <FormPassword
                            errorMessage="Password confirmation is required"
                            input={confirmPassword}
                            inputMod={setConfirmPassword}
                            isValid={isConfirmValid && isPasswordConsistent}
                            label="Confirm Password"
                            placeholder="Enter password again"
                            stateArray={confirmPassword}
                        />
                        <Button isLoading={isLoading} isDisabled={false} onClick={changePassword}>Create Password</Button>
                    </VStack>
                </Container>
            </Grid>
        </Box>  
    );
};

export default PasswordCreation;
