import { db } from '../firebase'
import { collection, getDocs, setDoc, doc, updateDoc, getDoc, deleteDoc, onSnapshot} from "firebase/firestore"; 
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { auth } from '../firebase'

const accountDocs = getDocs(collection(db, 'accounts'));
const accountDoc = (id) => doc(db, 'accounts', id)

const Accounts = {

    async requestAccess(accountInfo, cb, onFail){
        try{
            await setDoc(accountDoc(accountInfo.email), accountInfo)
            if(cb) cb()
        } catch (error) {
            if(onFail) onFail(error)
        }
        
    },

    async getAccountByEmail(email){
        const docSnap = await getDoc(accountDoc(email));
        if(docSnap.exists()){
            return docSnap.data();
        } else {
            return {};
        }
    },

    async getAccountsListener(cb){
        const unsubscribe = onSnapshot(collection(db, 'accounts'), (querySnapshot) => {
            const accounts = [];
            querySnapshot.forEach((doc) => {
                accounts.push(doc.data());
            });
            cb(accounts)
        })
        return unsubscribe
    },

    async getAccounts(cb){
        let accounts = []
        const regAccSnapshot = await accountDocs;
        regAccSnapshot.forEach(doc => { accounts.push(doc.data()) })
        cb(accounts)
    },

    async printAccounts(){
        const querySnapshot = await accountDocs;
        querySnapshot.forEach((doc) => {
        });
    },

    registerAuthInfo(email, password, cb, onFail){
        this.updateField(email, {role: 'vendor'})
        this.updateField(email, {isAuthenticated: true})
        createUserWithEmailAndPassword(auth, email, password).then(userCredential => {
            sendEmailVerification(auth.currentUser).then(() => {
                cb(userCredential.user)
            }).catch( error => onFail(error))
        }).catch( error => onFail(error) )
    },

    sendVerifyEmail(cb, onFail){
        sendEmailVerification(auth.currentUser).then(() => {
            cb()
        }).catch( error => onFail(error))
    },

    async updateLastLogin(email){
        await updateDoc(accountDoc(email), {
            lastLogin: new Date()
        })
    },

    async updateAccount(id, accountInfo, cb, onFail){
        try {
            await updateDoc(accountDoc(id), accountInfo)
            cb && cb()
        } catch (error) {
            onFail && onFail()
        }
    },

    async updateField(accountId, kvUpdate, cb){
        try {
            await updateDoc(accountDoc(accountId), kvUpdate)
            if(cb) cb()
        } catch (error) {
            if(cb) cb(error)
        }
    },

    async deleteAccount(id, cb, onFail){
        try {
            await deleteDoc(accountDoc(id))
            if(cb) cb()
        } catch (error) {
            if(onFail) onFail(error)
        }
    }

}

export default Accounts