import { useState, useEffect } from 'react'
import { smallBasic, validEmail } from '../Utils/TextValidation'

export default function useAnomalyValidity(anomaly){

    const [isAnomalyPropsValid, setIsAnomalyPropsValid] = useState(false)
    const [invalidProps, setInvalidProps] = useState([])

    useEffect(() => {
        let propBools = []
        for(const [key, value] of Object.entries(anomaly)){
            if(["reporterName", "locationName", "machineName", "machineVersion", "machineComponent", "machineSerial"].includes(key)){
                propBools.push({ key, value: smallBasic(value)})
            } else if (["description", "classRationale", "resolution", "rootCause"].includes(key)){
                propBools.push({ key, value: smallBasic(value)})
            } else if (["reporterEmail", "vendorEmail"].includes(key)){
                propBools.push({key, value: validEmail(value)})
            }
        }

        setIsAnomalyPropsValid(propBools.reduce((accumulator, currentValue) => accumulator && currentValue.value, true))
        setInvalidProps(propBools.filter(prop => !prop.value).map(prop => prop.key))
    }, [anomaly])

    return {
        isAnomalyPropsValid,
        invalidProps
    }
}