import { useState } from 'react';
import { Box, Text, VStack, Grid, Link, Button, Alert, AlertIcon, AlertTitle, AlertDescription, Image, HStack } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { ColorModeSwitcher } from '../Components/Common/ColorModeSwitcher';
import { FormPassword, FormText } from '../Components/Common/FormInputs';
import { useAuth } from '../Data/Contexts/AuthContext';
//import { httpsCallable } from 'firebase/functions';
//import { functions } from '../Data/firebase';
import { useLocation } from 'react-router-dom'
import ROLES from '../Data/Constants/Roles';
import Accounts from '../Data/Firestore/accounts';
import useNavToPage from '../Custom_Hooks/useNavToPage';
import VSTOPIMG from '../Data/Images/vstoplogo.png';
import SOSIMG from '../Data/Images/SOSLogo.png';
import IEDIMG from '../Data/Images/iedLogo.png';
import reportingPolicy from '../Data/Documents/Anomaly-Reporting-Policy.pdf'
import useSendEmail from '../Custom_Hooks/useSendEmail';

export default function Login(){
  const { toRegister, toAnomalyDashboard, toVendorDashboard, toAdminDashboard, toPasswordChange, toVariableUrl } = useNavToPage();
  const { sendNewUserSuccessfulLogin } = useSendEmail();
  const { login } = useAuth();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [incorrectAttempts, setIncorrectAttempts] = useState(0);

  async function attemptLogin() {
    setLoading(true);
    const { success, account: {initialLogin, name, role}, err } = await login(email, password);
    if (success){
      if (initialLogin) {
        Accounts.updateField(email, { initialLogin: false });
        sendNewUserSuccessfulLogin(name);
        toPasswordChange();
      } else {
        const path = location.state?.from.pathname;
        const singleAnomalyViewPathTest = /^\/anomaly\/(VS|EPB)\/[1-9A-z]{20}$/;
        if (singleAnomalyViewPathTest.test(path) && role !== ROLES.Administrator) { 
          /* This should only ever be for an anomaly specific link from an email.
          Since this path is available to anyone once logged in (except for admins)
          there is no need to do any more role/auth checking. Auth will need to be checked if any
          more wildcard routes are used, unless they are also all available to every role. */
          toVariableUrl(path);

          //For when auth checking is properly implemented
          //setError("Access to Resource Denied - Contact Support")
        } else {
          if ((role === ROLES.Vstop) || (role === ROLES.State)) {
            toAnomalyDashboard();
          } else if (role === ROLES.Vendor) {
            toVendorDashboard();
          } else if (role === ROLES.Administrator) {
            toAdminDashboard();
          } else {
            setError("Unknown Account - Contact Support");
          }
        };
      };
    } else {
      _handleError(err)
    }   
    setLoading(false);
  };

  return (
    <Box textAlign="center" fontSize="xl">
      <Grid minH="100vh" p={3}>
        <ColorModeSwitcher justifySelf="flex-end" />
        <VStack spacing={12}>
          <HStack spacing={10}>
            <Image
              src={IEDIMG}
              alt="SOS Logo"
              boxSize="170px"
              marginRight={4}
            />
            <Image src={VSTOPIMG} alt="VSTOP Logo" />
            <Image src={SOSIMG} alt="SOS Logo" boxSize="200px" />
          </HStack>
          <Text fontSize={40}>
            Indiana Secretary of State Anomaly Reporting Platform
          </Text>
            {error && (
              <Alert status="error" maxW='fit-content' >
                <AlertIcon />
                <AlertTitle>Error!</AlertTitle>
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}
          <VStack maxW="md" spacing={8}>
            <FormText
              errorMessage="Email cannot be empty"
              input={email}
              inputMod={setEmail}
              isUnsupervised
              label="Email Address"
              placeholder="Enter email"
              onKeyPress={login}
            />
            <FormPassword
              errorMessage="Password cannot be empty"
              input={password}
              inputMod={setPassword}
              isUnsupervised
              placeholder="Enter password"
              onKeyPress={login}
            />
            <Button isLoading={loading} onClick={attemptLogin}>
              Login
            </Button>
            <Link onClick={toRegister} _light={{ color: 'red.600' }} _dark={{ color: 'red.200' }}>
              Don't have an account?
            </Link>
            <Link
              _light={{ color: 'red.600' }}
              _dark={{ color: 'red.200' }}
              href={reportingPolicy}
              target="_blank"
              rel='noreferrer'
            >
              Anomaly Reporting Policy
              <ExternalLinkIcon mx="2px" />
            </Link>
          </VStack>
        </VStack>
      </Grid>
    </Box>
  );

  function _handleError(error){
    const errorCode = _parseErrorCode(error.code);
    setError(errorCode);
  };

  // async function _lockAccount(){
  //   const disableUser = httpsCallable(functions, "disableUserv2");
  //   await disableUser({ email });
  // };

  function _parseErrorCode(errorCode){
    switch (errorCode) { 
      //Firebase Error Code Reference: https://firebase.google.com/docs/reference/js/auth.md#autherrorcodes 
      //Some Error Code Explanations: https://firebase.google.com/docs/auth/admin/errors 
      case "auth/user-not-found":
      case "auth/wrong-password":
      case "auth/invalid-email":
        if(incorrectAttempts >= 6){
          return "Account Disabled - Contact Support";
        }
        setIncorrectAttempts(incorrectAttempts + 1)
        return `Check Credentials - Login attempts remaining: ${5 - incorrectAttempts}`;

      case "auth/too-many-requests":
        return "Account Disabled - Contact Support";

      case "auth/user-disabled":
        return "Account Disabled - Contact Support";

      default:
        return "Generic Error - Contact Support";
    }
  };
};