import { useState } from 'react'
import { Square, Text, Spinner,  } from '@chakra-ui/react'

export default function usePlaceHolder(){

  const [timeoutReached, setTimeoutReached] = useState(false)

  setTimeout(() => {
    setTimeoutReached(true)
  }, 3000);

  return timeoutReached
    ? <Square>
        <Text color="#999999" fontSize="md">There is nothing to display at this time</Text>
      </Square>
    : <Spinner />

}