import { functions } from '../Data/firebase'
import { httpsCallable } from 'firebase/functions'
import useSystemToast from './useSystemToast'

export default function useSendEmail() {

  const hostLink = process.env.NODE_ENV === 'development' ? "http://localhost:3000" : "https://insos-arp.org"

  const { errorToast, successToast } = useSystemToast()

  const sendAuthdEmailv2 = httpsCallable(functions, 'sendAuthdEmailv2')
  const sendUnAuthdEmailv2 = httpsCallable(functions, "sendUnAuthdEmailv2")

  function sendRegistrationEmailv2(name, email, onSuccess){
    return sendUnAuthdEmailv2({
      subject: "INSOS-ARP: Access - User Application",
      text: `Name: ${name}
      Email: ${email}`,
      html: `<div><strong>Name:</strong> ${name}</div>` + 
      `<div><strong>Email:</strong> ${email}</div>`
    })
    .then(result => {
      successToast('Requested access');
      onSuccess && onSuccess();
    })
    .catch(error => {
      errorToast();
    })
  }

  async function sendNewUserAcceptedEmail(email, password, onSuccess){
    return await sendAuthdEmailv2({
      to: email,
      subject: "INSOS-ARP: Access - User Application Accepted",
      text: 'Your request to access the Indiana Secretary of State Anomaly Reporting Platform has been approved! Please visit this link, https://insos-arp.org, and enter the user credentials below to activate your account. Upon activation, please follow all prompts to create a new password.' +
      `Email: ${email}` +
      `Temporary password: ${password}` +
      'Please visit contact anomalyreporting@bsu.edu should you have any questions or encounter any difficulties accessing your account.',
      html:
      '<div>Your request to access the Indiana Secretary of State Anomaly Reporting Platform has been approved! Please visit <a href="https://insos-arp.org"/>this link</a>, and enter the user credentials below to activate your account. Upon activation, please follow all prompts to create a new password.</div><br/>' +
      `<div>Email: <i>${email}</i></div>` +
      `<div>Temporary password: <i>${password}</i></div><br/>` +
      '<div>Please visit contact <a href="mailto:anomalyreporting@bsu.edu">anomalyreporting@bsu.edu</a> should you have any questions or encounter any difficulties accessing your account.</div>'
    })
    .then(() => {
      successToast('Create auth, account info, and send email')
      onSuccess && onSuccess()
    })
    .catch(error => {
      errorToast();
    })
  }

  async function sendNewUserSuccessfulLogin(name){
    return await sendAuthdEmailv2({
      to: 'anomalyreporting@bsu.edu',
      subject: "INSOS-ARP: Access - New User Successful Logon",
      text: `${name} has successfully logged on for the first time`,
    })
  }

  async function sendNewUserPasswordChange(name){
    return await sendAuthdEmailv2({
      to: 'anomalyreporting@bsu.edu',
      subject: "INSOS-ARP: Access - New User Successful Password Change",
      text: `${name} has successfully changed their temporary password`,
    })
  }

  async function sendNewAnomalyEmail(fromVendor, anomalyClass, machineType, anomalyDate, anomalyID, anomalyType, link, onSuccess) {
    return await sendAuthdEmailv2({
      to: "anomalyreporting@bsu.edu",
      subject: `INSOS-ARP: New Anomaly - ${fromVendor}`,
      text: `Date of Discovery: ${anomalyDate}:
      Anomaly ID: ${anomalyID}
      Vendor: ${fromVendor}
      System Affected: ${anomalyType}
      Machine Component Affected: ${machineType}
      Class: ${anomalyClass.slice(-1)}
      Link: ${hostLink + link}
      Please visit the Indiana Secretary of State Anomaly Reporting Platform to resolve this anomaly.`,
      html: `<div>Date of Discovery: <i>${anomalyDate}</i></div>` +
      `<div>Anomaly ID: <i>${anomalyID}</i></div>` +
      `<div>Vendor: <i>${fromVendor}</i></div>` +
      `<div>System Affected: <i>${anomalyType}</i></div>` + 
      `<div>Machine Component Affected: <i>${machineType}</i></div>` + 
      `<div>Class: <i>${anomalyClass.slice(-1)}</i></div>` +
      `<div>Link: <i>${hostLink + link}</i></div><br/>` +
      `<div>Please visit the Indiana Secretary of State Anomaly Reporting Platform to resolve this anomaly.</div>`
    })
    .then(result => {
      //successToast('Notify VSTOP: New Anomaly')
      onSuccess && onSuccess()
    })
    .catch(error => {
      errorToast();
    })
  }

  async function sendNewBulkUploadEmail(fromVendor, uploadTimestamp, uploadCount, machineType, onSuccess) {
    return await sendAuthdEmailv2({
      to: "anomalyreporting@bsu.edu",
      subject: `INSOS-ARP: New Bulk Upload - ${fromVendor}`,
      text: `Date of Discovery: ${uploadTimestamp}` + 
      `Vendor: ${fromVendor}` +
      `Election System Affected: ${machineType === 'VS' ? "Voting System" : "Electronic Pollbook"} ` + 
      `Total Anomalies Submitted: ${uploadCount}` +
      `Link: ${hostLink}` +
      `Please visit the Indiana Secretary of State Anomaly Reporting Platform to resolve these anomalies.`,
      html: `<div>Date of Discovery: <i>${uploadTimestamp}</i></div>` + 
      `<div>Vendor: <i>${fromVendor}</i></div>` +
      `<div>Election System Affected: <i>${machineType === 'VS' ? "Voting System" : "Electronic Pollbook"}</i></div>` + 
      `<div>Total Anomalies Submitted: <i>${uploadCount}</i></div>` +
      `<div>Link: <i><a href="${hostLink}">${hostLink}<a/></i></div><br/>` +
      `<div>Please visit the Indiana Secretary of State Anomaly Reporting Platform to resolve these anomalies.</div>`
    })
    .then(result => {
      onSuccess()
    })
    .catch(error => {
      errorToast();
    })
  }

  async function sendNewCommentEmail(toEmail, fromName, fromOrg, anomalyID, comment, link, onSuccess) {
    return await sendAuthdEmailv2({
      to: toEmail,
      subject: `INSOS-ARP: New Comment on Anomaly ID ${anomalyID}`,
      text: `Anomaly ID: ${anomalyID}:` +
      `${fromName}, ${fromOrg}: ${comment}` +
      `Link: ${hostLink + link}` +
      `Please visit the Indiana Secretary of State Anomaly Reporting Platform to respond to this comment.`,
      html: `<div>Anomaly ID: <i>${anomalyID}</i></div>` +
      `<div>${fromName}, ${fromOrg}: <i>${comment}</i></div>` +
      `<div>Link: <i>${hostLink + link}</i></div><br/>` +
      `<div>Please visit the Indiana Secretary of State Anomaly Reporting Platform to respond to this comment.</div>`,
    })
    .then(result => {
      successToast('Notify Stakeholders: New Comment')
      onSuccess && onSuccess()
    })
    .catch(error => {
      errorToast('Send Notification Email Failed');
    })
  }

  return { sendRegistrationEmailv2, sendNewUserAcceptedEmail, sendNewUserPasswordChange, sendNewUserSuccessfulLogin, sendNewAnomalyEmail, sendNewBulkUploadEmail, sendNewCommentEmail }
}
