import { jsPDF } from 'jspdf'
import { getDateAndTimeFromISO, getDateFromFirestoreTimestamp, sanitizeAnomalyClass, sanitizeLongText } from '../Utils/TextFormatting'
import { quickStatsAboutArray } from '../Utils/AnomalyUtils'
import COMMENTTYPE from '../Data/Constants/AnomalyCommentType.json'

let numOfPages = 1

export function createPDFFromAnomalies(anomalies){
  if(anomalies){
    const doc = jsPDF()
    AnomalyReportTitlePage(anomalies, doc, 54, 115)
    doc.addPage()
    numOfPages = numOfPages + 1
    anomalies.forEach(anomaly => {
        if(anomaly.selected){
            fillPageWithAnomalyData(doc, anomaly)
            doc.addPage()
            numOfPages = numOfPages + 1
        }
    })
    doc.deletePage(numOfPages)
    doc.save("anomalyreport.pdf")
  }
}

function AnomalyReportTitlePage(anomalies, doc, x, y){
  const date = new Date();
  doc.setFont('times','roman');
  doc.setFontSize(24);
  doc.text("INSOS-ARP Anomaly Report", x, y)
  doc.setFontSize(10)
  doc.text(`Generated: ${date.toUTCString()}`, x, y+7) //x+25
  doc.setFontSize(14)
  doc.text('Summary:', x, y+32) //x+25
  doc.setFontSize(10)
  doc.text(quickStatsAboutArray(anomalies, true), x, y+38) //x+25
}

function AnomalyReportHeader(doc, anomaly, x, y, isContinued){
  const date = new Date();
  doc.setFont('times','roman');
  doc.setFontSize(12);
  doc.text(`INSOS-ARP Anomaly Report - ${anomaly.id} ${isContinued ? "- cont." : ""}`, x, y);
  doc.setFont('times','italic');
  doc.setFontSize(10);
  doc.text(date.toString().slice(0,24), x, y+5);
}

function CountyInfoSection(doc, anomaly, x, y){
  doc.setFontSize(14);
  doc.setFont('times','bold');
  doc.text("County Information", x, y);
  doc.line(x,y+2,x+45,y+2)

  doc.setFontSize(11);
  doc.setFont('times','roman');
  doc.text("Name:" , x, y+7);
  doc.text("Email:", x, y+12);
  doc.text("Date Reported:", x, y+17);
  doc.text("County:", x, y+22);
  doc.text("State:", x, y+27);
  doc.text("Category:", x, y+32);
  doc.text("Name:", x, y+37);

  doc.setFont('times','italic');
  doc.text(anomaly.reporterName || anomaly.name, x+12, y+7);
  doc.text(`${anomaly.reporterEmail || anomaly.email}`.toLowerCase(), x+12, y+12);
  doc.setFontSize(10);
  doc.text(getDateAndTimeFromISO(anomaly.reporterDatetime, true), x+25, y+17);
  doc.setFontSize(11);
  doc.text(anomaly.county || "Null", x+14, y+22);
  doc.text(anomaly.state || "Null", x+10, y+27);
  doc.text(anomaly.locationCategory || "Null", x+16, y+32);
  doc.text(anomaly.locationName || "Null", x+12, y+37);
}

function VendorInfoSection(doc, anomaly, x, y){
  doc.setFontSize(14);
  doc.setFont('times','bold');
  doc.text("Vendor Information", x, y);
  doc.line(x,y+2,x+50,y+2)

  doc.setFontSize(11);
  doc.setFont('times','roman');
  doc.text("Name:" , x, y+7);
  doc.text("Email:", x, y+12);
  doc.text("Date Received:", x, y+17);

  doc.setFont('times','italic');
  doc.text(anomaly.vendorName, x+12, y+7);
  doc.text(`${anomaly.vendorEmail}`.toLowerCase(), x+12, y+12);
  doc.setFontSize(10);
  doc.text(getDateAndTimeFromISO(anomaly.vendorDatetime, true), x+25, y+17);
  doc.setFontSize(11);
}

function MachineInfoSection(doc, anomaly, x, y){
  const machineType = anomaly.type === 'VS' ? 'Voting System' : 'Electronic Poll Book'
  doc.setFontSize(14);
  doc.setFont('times','bold');
  doc.text("Machine Information", x, y);
  doc.line(x,y+2,x+50,y+2)

  doc.setFontSize(11);
  doc.setFont('times','roman');
  doc.text("Type:" , x, y+7);
  doc.text("Name:", x, y+12);
  doc.text("Version:", x, y+17);
  doc.text("Component:", x, y+22);
  doc.text("Serial Number:", x, y+27);
  doc.setFont('times','italic');
  doc.text(machineType, x+10, y+7);
  doc.text(anomaly.machineName || "Null", x+11, y+12);
  doc.text(anomaly.machineVersion, x+15, y+17);
  doc.text(anomaly.machineComponent || "Null", x+20, y+22, { maxWidth: 35});
  doc.text(anomaly.machineSerial || "Null", x+25, y+27, { maxWidth: 35});
}

function AnomalyInfoSection(doc, anomaly, x, y){
  const y1 = y+10
  const y2 = y+30
  const y3 = y+45
  const y4 = y+60
  doc.setFontSize(14);
  doc.setFont('times','bold');
  doc.text("Anomaly Information", x, y);
  doc.line(x,y+2,x+190,y+2) 

  doc.setFontSize(11);
  doc.text("Class:", x, y1);
  doc.text("Class Rationale:", x+25, y1);
  doc.text("Description:", x, y2);
  doc.text("Root Cause:", x, y3);
  doc.text("Resolution:", x, y4);
  doc.setFont('times','roman');
  doc.text(sanitizeAnomalyClass(anomaly.class), x+11, y1);
  doc.text(sanitizeLongText(anomaly.classRationale), x+53, y1, { maxWidth: 135});
  doc.text("\t\t      " + sanitizeLongText(anomaly.description), x, y2, { maxWidth: 180});
  doc.text("\t\t      " + sanitizeLongText(anomaly.rootCause), x, y3, { maxWidth: 180});
  doc.text("\t\t     " + sanitizeLongText(anomaly.resolution), x, y4, { maxWidth: 180});
}

function CommentInfoSection(doc, anomaly, x, y, isContinued){ //current comment limit is max 7 on a page
  doc.setFontSize(14);
  doc.setFont('times','bold');
  doc.text(`Document History ${isContinued ? "- Continued" : ""}`, x, y);
  doc.line(x,y+2,x+190,y+2)
  doc.setFontSize(11);

  let nextOffset = 0
  const comY = (index) => ((y+10) + (14*index) + nextOffset)
  // y+10 - Move all comments down from Section Header
  // 20*index - Height of comment multiplied by what number comment it is
  // nextOffset - additional spacing for extra long comments

  function setNextOffset(text){
      if((text.length - 315) > 0){
          //~315 is max character density for a standard comment
          nextOffset = Math.ceil(((text.length - 315) / 100)) * 7
          //~107 is max character density for a single line
          //moves next comment down an extra 10 pixels for every additional line
      } else if(nextOffset > 1) { 
          nextOffset = nextOffset / 1.2
      } else {
          nextOffset = 0 
      }
  }

  function createReportedComment(comment, index){
    doc.setFontSize(11);
    doc.setFont('times','bold');
    doc.text(comment.creator, x, comY(index));
    doc.setFont('times','roman');
    doc.text(`Reported - ${comment.text}`, x+30, comY(index), { maxWidth: 160});

    doc.setFontSize(8);
    doc.setFont('times','italic');
    doc.text(getDateFromFirestoreTimestamp(comment.created), x, comY(index)+4, { maxWidth: 25})
    
    setNextOffset(comment.text)
  }

  function createAcceptedComment(comment, index){
    doc.setFontSize(11);
    doc.setFont('times','bold');
    doc.text(comment.creator, x, comY(index));
    doc.setFont('times','roman');
    doc.text('Accepted', x+30, comY(index), { maxWidth: 160});

    doc.setFontSize(8);
    doc.setFont('times','italic');
    doc.text(getDateFromFirestoreTimestamp(comment.created), x, comY(index)+4, { maxWidth: 25})
    
    setNextOffset(comment.text)
  }

  function createStatusChangeComment(comment, index){
      doc.setFontSize(11);
      doc.setFont('times','bold');
      doc.text(comment.creator, x, comY(index));
      doc.setFont('times','roman');
      doc.text(comment.text, x+30, comY(index), { maxWidth: 160});

      doc.setFontSize(8);
      doc.setFont('times','italic');
      doc.text(getDateFromFirestoreTimestamp(comment.created), x, comY(index)+4, { maxWidth: 25})
      
      setNextOffset(comment.text)
  }

  function createPropEditComment({ creator, created, editedProp, originalValue, newValue }, index){
      const text = `${creator} edited ${editedProp} from "${originalValue}" to "${newValue}"`
      
      doc.setFontSize(10);
      doc.setFont('times','italic');
      doc.text(creator, x, comY(index));
      doc.text(text, x+30, comY(index), { maxWidth: 160});
      
      doc.setFontSize(8);
      doc.text(getDateFromFirestoreTimestamp(created), x, comY(index)+4, { maxWidth: 25})
      
      setNextOffset(text)
  }

  
  anomaly?.comments.forEach((comment, index) => {
    switch (comment.type) {
      case COMMENTTYPE.PROPEDIT:
        return createPropEditComment(comment, index)          
      case COMMENTTYPE.ACCEPTANOMALY:
        return createAcceptedComment(comment, index)
      case COMMENTTYPE.REPORTANOMALY:
        return createReportedComment(comment, index)
      default:
        return createStatusChangeComment(comment, index)
    }
  })

  const commentsPerPage = 10;

  if(anomaly?.comments.length > commentsPerPage){
      doc.addPage()
      numOfPages = numOfPages + 1
      const nextCommentSet = anomaly?.comments.slice(commentsPerPage)
      AnomalyReportHeader(doc, anomaly, 10, 10, true)
      CommentInfoSection(doc, {...anomaly, comments: nextCommentSet}, 10, 25, true)
  }

}

function fillPageWithAnomalyData(doc, anomaly){

  AnomalyReportHeader(doc, anomaly, 10, 10, false)

  CountyInfoSection(doc, anomaly, 10, 25)

  VendorInfoSection(doc, anomaly, 75, 25)

  MachineInfoSection(doc, anomaly, 145, 25)

  AnomalyInfoSection(doc, anomaly, 10, 75)

  CommentInfoSection(doc, anomaly, 10, 150, false)
}