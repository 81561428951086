import { useState, useEffect } from 'react';
import { Container, VStack, Button, Alert, AlertIcon, AlertDescription, AlertTitle } from '@chakra-ui/react';
import { FormSelect, FormText } from '../Common/FormInputs';
import { functions } from '../../Data/firebase.js';
import { httpsCallable } from 'firebase/functions';
import { v4 as uuidv4 } from 'uuid'
import useSendEmail from '../../Custom_Hooks/useSendEmail';
import useSystemToast from '../../Custom_Hooks/useSystemToast';
import useFetchVendors from '../../Custom_Hooks/useFetchVendors.js';
import DESIGNATIONS from '../../Data/Constants/Designations.json'
import ROLES from '../../Data/Constants/Roles';

const CreateUserAccount = () => {
  const [,b,c] = uuidv4().split("-");
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showLoadingText, setShowLoadingText] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [designation, setDesignation] = useState(DESIGNATIONS[0]);
  const [arrayToUse, setArrayToUse] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [tempPass, setTempPass] = useState(`${b}${c}`);
  const [role, setRole] = useState('');
  const { errorToast } = useSystemToast();
  const { sendNewUserAcceptedEmail } = useSendEmail(); 
  const { vendors } = useFetchVendors()

  useEffect(() => {
    switch (designation) {
      case 'Indiana Secretary of State':
        setArrayToUse(vendors.filter(vendor => vendor.isState));
        setCompanyName('State of Indiana');
        setRole(ROLES.State);
        break;
      case 'Indiana Election Division':
        setArrayToUse(vendors.filter(vendor => vendor.isState));
        setCompanyName('State of Indiana');
        setRole(ROLES.State);
        break;
      case 'State Contractor':
        setArrayToUse(vendors.filter(vendor => vendor.isIndependent));
        setCompanyName('BakerTilly');
        setRole(ROLES.State);
        break;
      case 'Voting System Technical Oversight Program':
        setArrayToUse(vendors.filter(vendor => vendor.isVSTOP));
        setCompanyName('VSTOP');
        setRole(ROLES.Vstop);
        break;
      default:
        setArrayToUse(vendors.filter(vendor => vendor.isEPBVendor || vendor.isVSVendor));
        setCompanyName('Election Systems & Solutions');
        setRole(ROLES.Vendor);
        break;
    } //eslint-disable-next-line
  }, [designation, vendors]);

  function submitAccountData() {
    const createUserAuth = httpsCallable(functions, 'createUserAuthv2');
    const createUserAccount = httpsCallable(functions, 'createUserAccountv2');

    setIsLoading(true);
    setShowLoadingText(true);
    setShowError(false)

    let accountInfo = {
      companyName,
      designation,
      email,
      name,
      role,
      initialLogin: true
    }
    
    if (role === ROLES.Vendor){
      var vendor = vendors.find(vendObj => vendObj.name === companyName)
      accountInfo.vendorId = vendor.id
    }

    // 1. Connect to Cloud Function and Create Email/Pass auth
    setLoadingText('Creating User Auth...');
    createUserAuth({
      name,
      email,
      password: tempPass,
    })
      .then(result => {
        // 2. Write Account Data to firebase
        setLoadingText('Creating User Account...');
        createUserAccount({
          userInfo: {
            ...accountInfo,
            uid: result.data.uid,
          },
          userID: email,
        })
        .then(() => {
          // 3. Connect to Cloud Function to send email
          setLoadingText('Sending Email...');
          sendNewUserAcceptedEmail(email, tempPass)
          .then(() => {
            const [,s,t] = uuidv4().split("-");
            setName("");
            setEmail("");
            setDesignation(DESIGNATIONS[0]);
            setCompanyName("Election Systems & Solutions");
            setTempPass(`${s}${t}`);
          })
          .catch((error) => {
            errorToast()
          })
          .finally(() => {
            setIsLoading(false);
            setShowLoadingText(false);
          })
        })
        .catch(error => {
          errorToast();
          setShowError(true)
        });
      })
      .catch(error => {
        errorToast();
        setShowError(true)
      });
  }

  return (
    <Container maxW="md">
      <VStack spacing={8}>
        {showError && (
          <Alert status="error" variant="top-accent">
            <AlertIcon />
            <AlertTitle>There was a problem!</AlertTitle>
            <AlertDescription>Could not create user account.</AlertDescription>
          </Alert>
        )}
        <FormText
          errorMessage="Name is required"
          input={name}
          inputMod={setName}
          isUnsupervised
          label="Full Name"
          placeholder="Enter first and last name"
        />
        <FormText
          errorMessage="Email is required"
          input={email}
          inputMod={setEmail}
          isUnsupervised
          label="Email Address"
          placeholder="Enter email"
        />
        <FormSelect
          children={DESIGNATIONS.map((val, index) => (
            <option key={index}>{val}</option>
          ))}
          label="Designation"
          state={designation}
          stateMod={setDesignation}
        />
        <FormSelect
          children={arrayToUse.map((val, index) => (
            <option key={index} value={val.name}>{val.name} {`(${val.abbv})`}</option>
          ))}
          label="Company Name"
          state={companyName}
          stateMod={setCompanyName}
        />
        <FormText
          errorMessage="Temporary password is required"
          input={tempPass}
          inputMod={setTempPass}
          isUnsupervised
          label='Temporary Password'
        />
        <VStack>
          {showLoadingText && <p>{loadingText}</p>}
          <Button
            isLoading={isLoading}
            colorScheme="blue"
            variant="outline"
            onClick={submitAccountData}
          >
            Create Account
          </Button>
        </VStack>
      </VStack>
    </Container>
  );
};

export default CreateUserAccount;
