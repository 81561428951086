import { useState } from 'react';
import { Popover, PopoverTrigger, PopoverContent, PopoverBody, PopoverArrow, IconButton, Button, Stack, Flex, useColorMode, useColorModeValue, FormControl, FormLabel, FormErrorMessage, Alert, AlertIcon } from '@chakra-ui/react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import { FaMoon, FaSun } from 'react-icons/fa';
import { FiMenu } from "react-icons/fi"
import { RiRestartLine } from 'react-icons/ri';
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import { auth } from '../../Data/firebase'
//import useNavToPage from '../../Custom_Hooks/useNavToPage';
import LogOutButton from './LogOutButton';
import { useAuth } from '../../Data/Contexts/AuthContext';
import { isPasswordValid } from '../../Utils/TextValidation'; 


const UserMenu = () => {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { accountInfo } = useAuth();
  const { toggleColorMode } = useColorMode();
  const text = useColorModeValue('Dark', 'Light');
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNew, setConfirmNew] = useState('')
  const [showOld, setShowOld] = useState(false)
  const [showNew, setShowNew] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  function handleOldChange(e){setOldPassword(e.target.value)}
  function handleNewChange(e){setNewPassword(e.target.value)}
  function handleConfirmChange(e){setConfirmNew(e.target.value)}
  function toggleShowOld(){setShowOld(!showOld)}
  function toggleShowNew(){setShowNew(!showNew)}
  function toggleShowConfirm(){setShowConfirm(!showConfirm)}

  const isOldError = oldPassword === ""
  const isNewError = newPassword === ""
  const isNewProperLength = newPassword.length >= 8
  const doesNewHaveLowercase = /[a-z]/.test(newPassword)
  const doesNewHaveUppercase = /[A-Z]/.test(newPassword)
  const doesNewHaveNumbers = /[0-9]/.test(newPassword)
  const doesNewHaveSpecial = /[!?@#$%&*+=]/.test(newPassword)
  const isNewSecure = isPasswordValid(newPassword) && doesNewHaveSpecial
  const isNewNotOld = newPassword !== oldPassword
  const isConfirmError = confirmNew === ""
  const isConfirmConsistent = confirmNew === newPassword

  function handleClose(){
    setOldPassword('')
    setNewPassword('')
    setConfirmNew('')
    setError(null)
    setSuccess(false)
    onClose()
  }

  async function changePassword(){
    setLoading(true)
    setError(null)
    setSuccess(false)
    const user = auth.currentUser
    const credential = EmailAuthProvider.credential(accountInfo.email, oldPassword)
    try {
      await reauthenticateWithCredential(user, credential)
      await updatePassword(user, newPassword)
      setSuccess(true)
    } catch (error) {
      switch (error.code) {
        case 'auth/wrong-password':
          setError("The old password was inorrect.")
          break;
        default:
          setError("An error occured, please try again.")
          break;
      }
      console.error(error)
    }
    setLoading(false)
  }

  return (
    <>
    <Flex justifyContent="center" mt={4}>
      <Popover placement="bottom" >
        <PopoverTrigger>
          <IconButton
            aria-label="More server options"
            icon={<FiMenu />}
            variant="solid"
            w="fit-content"
          />
        </PopoverTrigger>
        <PopoverContent w="fit-content" _focus={{ boxShadow: 'none' }}>
          <PopoverArrow />
          <PopoverBody>
            <Stack>
              <Button
                aria-label={`Switch to ${text} mode`}
                w="194px"
                variant="ghost"
                rightIcon={<SwitchIcon />}
                justifyContent="space-between"
                fontWeight="normal"
                fontSize="sm"
                onClick={toggleColorMode}>
                {text} Mode
              </Button>
              <Button
                colorScheme="red"
                fontSize="sm"
                fontWeight="normal"
                justifyContent="space-between"
                onClick={onOpen}
                rightIcon={<RiRestartLine />}
                variant="ghost"
                w="194px" >
                Change Password
              </Button>
              {/* <Button
                w="194px"
                variant="ghost"
                rightIcon={<RiShutDownLine />}
                justifyContent="space-between"
                fontWeight="normal"
                colorScheme="red"
                fontSize="sm"
                onClick={handleLogout}>
                Logout
              </Button> */}
              <LogOutButton />
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>

    <Modal isOpen={isOpen} onClose={handleClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Change Password</ModalHeader>
      <ModalCloseButton onClick={handleClose} />
      { 
        error &&
        <Alert status="error">
          <AlertIcon />
          {error}
        </Alert>
      }
      { 
        success &&
        <Alert status="success">
          <AlertIcon />
          Password changed successfully!
        </Alert>
      }
      <ModalBody>
        <FormControl isInvalid={isOldError} mb={5}>
          <FormLabel>Old Password</FormLabel>
          <InputGroup size='md'>
            <Input
              pr='4.5rem'
              type={showOld ? 'text' : 'password'}
              placeholder='Enter old password'
              onChange={handleOldChange}
            />
            <InputRightElement width='4.5rem'>
              <Button h='1.75rem' size='sm' onClick={toggleShowOld}>
                {showOld ? 'Hide' : 'Show'}
              </Button>
            </InputRightElement>
          </InputGroup>
          {
            isOldError &&
            <FormErrorMessage>Old password is required.</FormErrorMessage>
          }
        </FormControl>
        
        <FormControl isInvalid={isNewError || !isNewSecure || !isNewNotOld} mb={5}>
          <FormLabel>New Password</FormLabel>
          <InputGroup size='md'>
            <Input
              pr='4.5rem'
              type={showNew ? 'text' : 'password'}
              placeholder='Enter new password'
              onChange={handleNewChange}
            />
            <InputRightElement width='4.5rem'>
              <Button h='1.75rem' size='sm' onClick={toggleShowNew}>
                {showNew ? 'Hide' : 'Show'}
              </Button>
            </InputRightElement>
          </InputGroup>
          {
            isNewError &&
            <FormErrorMessage>New password is required.</FormErrorMessage>
          }
          {
            !isNewSecure &&
            <FormErrorMessage>
              <ul>
                At a minimum, password must contain the following:
                {!doesNewHaveLowercase && <li>One lowercase letter</li>}
                {!doesNewHaveUppercase && <li>One uppercase Letter</li>}
                {!doesNewHaveNumbers && <li>One number</li>}
                {!doesNewHaveSpecial && <li>One of the following: ! ? @ # $ % & * + =</li>}
                {!isNewProperLength && <li>8 character length</li>}
              </ul>
            </FormErrorMessage>
          }
          {
            !isNewNotOld &&
            <FormErrorMessage>New password cannot be old password!
            </FormErrorMessage>
          }
        </FormControl>

        <FormControl isInvalid={isConfirmError || !isConfirmConsistent} mb={5}>
          <FormLabel>Confirm Password</FormLabel>
          <InputGroup size='md'>
            <Input
              pr='4.5rem'
              type={showConfirm ? 'text' : 'password'}
              placeholder='Confirm new password'
              onChange={handleConfirmChange}
            />
            <InputRightElement width='4.5rem'>
              <Button h='1.75rem' size='sm' onClick={toggleShowConfirm}>
                {showConfirm ? 'Hide' : 'Show'}
              </Button>
            </InputRightElement>
          </InputGroup>
          {
            isConfirmError &&
            <FormErrorMessage>New password is required.</FormErrorMessage>
          }
          {
            !isConfirmConsistent &&
            <FormErrorMessage>Passwords do not match!</FormErrorMessage>
          }
        </FormControl>
      </ModalBody>
        
      <ModalFooter>
        <Button 
        colorScheme='green' 
        isDisabled={isOldError || !isNewSecure || !isConfirmConsistent || !isNewNotOld}
        isLoading={loading}
        onClick={changePassword}
        variant='outline' 
        >
          Save New Password
        </Button>
      </ModalFooter>
    </ModalContent>
    </Modal>
    </>
  )
}


export default UserMenu