import { useContext, useState } from 'react'
import { Box, Text, Flex, VStack, Button, IconButton } from "@chakra-ui/react"
import { ChevronDownIcon, ChevronUpIcon, DeleteIcon } from '@chakra-ui/icons'
import { TableContext } from '../../Data/Contexts/TableContext'
import { useAuth } from '../../Data/Contexts/AuthContext'
import Anomaly from '../../Classes/Anomaly'
import Comment from '../Common/Comment'

const AnomalyValidationPreviewBox = ({ anomaly, index, deleteFunc, isUpdate }) => {

  const { accountInfo } = useAuth()
  const { anomalies } = useContext(TableContext)
  const [show, setShow] = useState(false)

  function toggleShow(){ setShow(!show) }
  function handleDelete(){ deleteFunc(index) }

  return (
    <Box w='95%' borderWidth='2px' borderRadius='lg' marginY={2} borderColor={anomaly.isCriticallyValid ? 'green' : anomaly.emptyProps.length > 0 ? 'red' : 'yellow'}>
      <VStack>
        <Flex wrap='wrap' w='100%' justifyContent='space-evenly' alignItems='center'>
          <Text fontSize='2xl' m={3} >#{index+1}</Text>
          <Text fontSize='md' m={3} >{anomaly.identifier}</Text>
          <Text fontSize='md' m={3} >{anomaly.county} County, {anomaly.state}</Text>
          <Text fontSize='xl' m={3} >Class {anomaly.class}</Text>
          <Button
            onClick={toggleShow}
            _focus={{ boxShadow: 'none' }}
            w="fit-content">
            {show ? <ChevronUpIcon boxSize={10} /> : <ChevronDownIcon boxSize={10} />}
          </Button>
          <IconButton onClick={handleDelete} icon={ <DeleteIcon />} colorScheme='red' variant={'outline'}/>
        </Flex>
        { show &&
          <Flex wrap="wrap" justifyContent='flex-start' w={'100%'} >
            {
              (anomaly.incompleteProps.length > 0 && !anomaly.isCriticallyValid)
              ? <Flex w={'100%'} direction={'column'} border={'2px solid gray'} m={2} pt={2} borderRadius={10} position={'relative'}>
                  <Text position={'absolute'} top={-5} left={10} fontSize={20} _dark={{ backgroundColor: '#1A202C'}} _light={{ backgroundColor: "#FFFFFF"}} px={4} style={{fontStyle: 'italic'}}>Missing Information Necessary for Upload</Text>
                  {anomaly.incompleteProps.filter(prop => prop.isCritical).map(({title, message}, index) => (
                    <Flex m={2} key={index} alignItems={'center'}>
                      <Text fontSize={18} _dark={{ backgroundColor: '#1A202C'}} _light={{ backgroundColor: "#FFFFFF"}} px={4}>{title}</Text>
                      <Text fontSize={14} _dark={{ backgroundColor: '#1A202C'}} _light={{ backgroundColor: "#FFFFFF"}} px={4} whiteSpace={"break-spaces"} textAlign={'left'}>{message}</Text>
                    </Flex>
                  ))}
                </Flex>
              : <Flex w={'100%'} direction={'column'} m={4} p={2} borderRadius={10} justify={'flex-start'} align={'flex-start'}>
                  <Text mb={2} fontSize={20} _dark={{ backgroundColor: '#1A202C'}} _light={{ backgroundColor: "#FFFFFF"}} style={{fontStyle: 'italic'}}>Ready for upload!</Text>
                  { 
                    isUpdate && 
                    Anomaly.diffComments(anomaly, anomalies.find(a => a.id === anomaly.id), false, accountInfo).map((comment, index) => (
                      <Comment key={index} {...comment} isImport/>
                    ))
                  }
                </Flex>
            }
          </Flex>
        }
      </VStack>
    </Box>
  )
}

export default AnomalyValidationPreviewBox