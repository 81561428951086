import { Tab, Badge } from '@chakra-ui/react'
import FILTER from '../../Data/Constants/Filters.json'
import useTableAnomalies from '../../Custom_Hooks/useTableAnomalies'

export default function CustomTab({ children, show = true, showBadges = true, status }){

    const { epbCount, vsCount } = useTableAnomalies([FILTER.STATUS, status], true)

    return (
        show && (
            showBadges
            ?   <Tab>
                    <Badge colorScheme='blue' marginRight={1}>{vsCount}</Badge>
                    <Badge colorScheme='green' marginRight={1}>{epbCount}</Badge>
                    {children}
                </Tab>
            :   <Tab>{children}</Tab>
        )
    )
}