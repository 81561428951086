import { Box, Grid, Flex, Heading, Text, VStack } from '@chakra-ui/react'
import ROUTES from '../Data/Constants/Routes';
import NavButton from '../Components/Common/NavButton';

const NotFound = () => (
    <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3}>
            <Flex flexDirection="column" justify="space-evenly" align="center" >
                <VStack>
                    <Heading>Page Not Found</Heading>
                    <Text>
                        It appears you're trying to navigate to a page that does not exist.
                        Please check the url and try again.
                    </Text>
                </VStack>
                <NavButton to={ROUTES.Login}>Return to login</NavButton>
            </Flex>
        </Grid>
    </Box>
)

export default NotFound