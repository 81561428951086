import { useState } from 'react';
import { Box, Text, VStack, Grid, Link, Container, Button, Checkbox, FormControl, FormLabel, useColorModeValue } from '@chakra-ui/react';
import { ColorModeSwitcher } from '../Components/Common/ColorModeSwitcher';
import { FormText } from '../Components/Common/FormInputs';
import { smallBasic, validEmail } from '../Utils/TextValidation';
import useNavToPage from '../Custom_Hooks/useNavToPage';
import useStateValidate from '../Custom_Hooks/useStateValidate';
import TermsAndConditionsModal from '../Components/Register/TermsAndConditionsModal';
import useSendEmail from '../Custom_Hooks/useSendEmail';

const Register = () => {
  const borderColor = useColorModeValue('#5e5e5e', '#878787')
  const linkColor = useColorModeValue('red.600', 'red.200')
  const { toLogin } = useNavToPage()
  const { sendRegistrationEmailv2 } = useSendEmail()
  const [checked, setChecked] = useState(false)
  const [[email, setEmail], isEmailValid] = useStateValidate(validEmail)
  const [[name, setName], isNameValid] = useStateValidate(smallBasic)

  const [loading, setLoading] = useState(false)

  async function requestAccess() { 
    setLoading(true)
    await sendRegistrationEmailv2(name, email, toLogin) 
    setLoading(false)
  }

  return (
    <Box textAlign="center" fontSize="xl">
      <Grid minH="100vh" p={3}>
        <ColorModeSwitcher justifySelf="flex-end" />
        <Container maxW="md">
          <VStack spacing={8}>
            <Text>Request INSOS-ARP Access</Text>
            <FormText
              errorMessage="Name is required"
              input={name}
              inputMod={setName}
              isValid={isNameValid}
              label="Full Name"
              placeholder="Enter first and last name"
              validationTip={`Field requires at least ${4 - name.length} more character(s)`}
            />
            <FormText
              errorMessage="Email is required"
              input={email}
              inputMod={setEmail}
              isValid={isEmailValid}
              label="Email Address"
              placeholder="Enter email"
              validationTip="Field requires an @ and . address at least 2 characters long"
            />
            <FormControl isRequired display="flex">
              <Checkbox
                borderColor={borderColor}
                marginRight={4}
                onChange={e => {
                  setChecked(e.target.checked);
                }}
              />
              <FormLabel>
                I accept the <TermsAndConditionsModal linkColor={linkColor} />
              </FormLabel>
            </FormControl>
            <Button
              isDisabled={!isEmailValid || !isNameValid || !checked}
              isLoading={loading}
              onClick={requestAccess}
            >
              Request Access
            </Button>
            <Text>
              or,{' '}
              <Link color={linkColor} onClick={toLogin}>
                go back
              </Link>
            </Text>
          </VStack>
        </Container>
      </Grid>
    </Box>
  );
};

export default Register;
