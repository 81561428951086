import { useState, useEffect } from 'react';
import { Box, Grid, GridItem, Container, Heading, Button, VStack, HStack, useColorModeValue, IconButton } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useAuth } from '../../Data/Contexts/AuthContext';
import { FormSelect, FormTextArea, FormText } from '../../Components/Common/FormInputs';
import { exists, smallBasic, longBasic, validEmail, longBasicLimit } from '../../Utils/TextValidation';
import HelperText from '../../Data/Constants/HelperText.json';
import useNavToPage from '../../Custom_Hooks/useNavToPage';
import Anomalies from '../../Data/Firestore/anomalies';
import useSystemToast from '../../Custom_Hooks/useSystemToast';
import useStateValidate from '../../Custom_Hooks/useStateValidate';
import STATUS from '../../Data/Constants/AnomalyStatus';
import { buildAnomalyLink } from '../../Utils/AnomalyUtils';
import CountiesByState from '../../Data/Constants/CountiesByState.json'
import useSendEmail from '../../Custom_Hooks/useSendEmail';
import CountyAutoFillData from "../../Data/Constants/CountyAutoFillData.json"

import UserMenu from '../../Components/Common/UserMenu';

export const QuestionGroup = ({ children, sectionName = "Section", isFinal, width = "150%", height = "120%", mB = 0}) => {

    const color = useColorModeValue('black', 'white')
    const greenShade = useColorModeValue('green.300','green')

    return (
        <Grid 
        border={`1px solid ${isFinal ? 'green' : 'gray'}`} 
        borderRadius={20} 
        width={width} 
        height={height}
        templateRows='repeat(2, 1fr)'
        templateColumns='repeat(5, 1fr)'
        gap={4}
        padding={8}
        marginBottom={mB}>
            <GridItem 
            rowSpan={1}
            colSpan={1}>
                <Heading as='h4' size='md' justifySelf={'left'} color={isFinal ? greenShade : color}>{sectionName}</Heading>
            </GridItem>
            <GridItem
            rowSpan={2}
            colSpan={4}>
                <VStack spacing={8}>
                    {children}
                </VStack>
            </GridItem>
        </Grid>
    )
}

const AnomalyForm = ({ isVS = false }) => {

    const { anomalyClassHelperText, resolutionHelperText, rootCauseHelperText, issueHelperText }  = HelperText
    const type = isVS ? 'VS' : "EPB"
    const { toVendorDashboard } = useNavToPage();
    const { successToast, errorToast } = useSystemToast(); 
    const { accountInfo, vendorInfo } = useAuth();
    const { sendNewAnomalyEmail } = useSendEmail();
    const greenShade = useColorModeValue('green.300', 'green');

    const [[reporterName, setReporterName], isNameValid] = useStateValidate(smallBasic);
    const [[reporterEmail, setReporterEmail], isRepEmailValid] = useStateValidate(validEmail);
    const [locationName, setLocationName] = useState('')
    const [[machineName, setMachineName], isMachineNameValid] = useStateValidate(exists);
    const [[machineVersion, setMachineVersion], isMachineVersionValid] = useStateValidate(exists);
    const [[machineComponent, setMachineComponent], isMachineComponentValid] = useStateValidate(smallBasic);
    const [[machineSerial, setMachineSerial], isMachineSerialValid] = useStateValidate(smallBasic);
    const [[description, setDescription], isDescriptionValid] = useStateValidate(longBasic);
    const [rootCause, setRootCause] = useState('')
    const [resolution, setResolution] = useState('')
    const [[classRationale, setClassRationale], isClassRationaleValid] = useStateValidate(longBasic);
    const [[manualClassRationale, setManualClassRationale], isManualClassRationaleValid] = useStateValidate(longBasic);
    const [reporterDatetime, setReporterDateTime] = useState("")
    const [county, setCounty] = useState("Adams")
    const [state, setState] = useState("Indiana")
    const [locationCategory, setLocationCategory] = useState("Unknown")
    const [anomalyClass, setAnomalyClass] = useState("2")
    const [loading, setLoading] = useState(false)
    const [countiesOptions, setCountiesOptions] = useState([])
    const [classRationaleOptions, setClassRationaleOptions] = useState([]);
    const [showClassRationaleTextInput, setShowClassRationaleTextInput] = useState([]);
    
    const isCountyInfoFinal  = isNameValid && isRepEmailValid
    const isMachineFinal = isMachineNameValid && isMachineVersionValid && (isVS ? isMachineComponentValid : true)
    const isAnomalyFinal = isDescriptionValid && isClassRationaleValid && (showClassRationaleTextInput ? isManualClassRationaleValid : true)
    const isSubmitValid = isCountyInfoFinal && isMachineFinal && isAnomalyFinal

    useEffect(() => {
        const dt = new Date();
        setReporterDateTime(toIsoString(dt).slice(0,16))
    }, [])

    useEffect(() => {
        if(anomalyClass === "1"){
            setClassRationaleOptions([
                "Class 1: Occurred on Election Day but did not cause a substantial delay in voting or tabulation.",
                "Class 1: Occurred on Election Day and did cause a substantial delay in voting or tabulation.",
                "Class 1: Occurred during Early Voting but did not cause a substantial delay in voting or tabulation.",
                "Class 1: Occurred during Early Voting and did cause a substantial delay in voting or tabulation",
                "Class 1: Anomaly has suspected malicious or unauthorized origins",
                "Class 1: Occurred on Election Day/Early Voting but outside of Indiana with an Indiana Certified System"
            ])
            setClassRationale("Class 1: Occurred on Election Day but did not cause a substantial delay in voting or tabulation.")
        } else {
            setClassRationaleOptions([
                "Class 2: Did not occur on Election Day and did not pose a delay in voting or tabulation",
                "Class 2: Did not occur on Election Day/Early Voting but did occur outside of Indiana with an Indiana Certified System",
                "Class 2: Other: ____________________________",
            ])
            setClassRationale("Class 2: Did not occur on Election Day and did not pose a delay in voting or tabulation")
        } //eslint-disable-next-line
    }, [anomalyClass])

    useEffect(() => {
        setShowClassRationaleTextInput(classRationale === "Class 2: Other: ____________________________")
    }, [classRationale])
    

    useEffect(() => {
        let optObj = CountiesByState.filter(optionsObj => optionsObj.state === state)
        if(optObj[0]?.counties){
            setCountiesOptions(optObj[0].counties)
            setCounty(optObj[0].counties[0])
        } else {
            setCountiesOptions([])
            setCounty("No Options")
        }
        // eslint-disable-next-line
    }, [state])

    useEffect(() => {
        if(state === "Indiana"){
            const countyAutofillDataArray = CountyAutoFillData.filter(countyData => countyData.county === county)
            if(countyAutofillDataArray[0]){
                const countyData = countyAutofillDataArray[0]
                setReporterName(countyData.contact_name)
                setReporterEmail(countyData.contact_email)
                setMachineName(isVS ? countyData.vs_model : countyData.epb_model)
                setMachineVersion(isVS ? countyData.vs_version : countyData.epb_version)
            }
        } else {
            setReporterName("")
            setReporterEmail("")
        } // eslint-disable-next-line
    }, [state, county])

    function toIsoString(date) {
        let tzo = -date.getTimezoneOffset(),
            dif = tzo >= 0 ? '+' : '-',
            pad = function(num) {
                return (num < 10 ? '0' : '') + num;
            };
      
        return date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate()) +
            'T' + pad(date.getHours()) +
            ':' + pad(date.getMinutes()) +
            ':' + pad(date.getSeconds()) +
            dif + pad(Math.floor(Math.abs(tzo) / 60)) +
            ':' + pad(Math.abs(tzo) % 60);
    }
    
    
    function submitAnomaly(){
        setLoading(true)
        Anomalies.submitAdd({
            vendorName: vendorInfo.name,
            vendorEmail: accountInfo.email,
            vendorDatetime: toIsoString(new Date()).slice(0,16),
            reporterName,
            reporterEmail,
            reporterDatetime,
            county,
            state,
            locationName,
            locationCategory,
            machineName,
            machineVersion,
            machineComponent: isVS ? machineComponent : 'EPB - No Component',
            machineSerial,
            description,
            rootCause,
            resolution,
            class: anomalyClass,
            classRationale: showClassRationaleTextInput ? "Class 2: " + manualClassRationale : classRationale,
            type,
            comments: [],
            status: STATUS.New,
            timestamp: new Date(),
        },
        (id) => {
            if(process.env.NODE_ENV === 'production'){ //TODO REMOVE THIS SHIT (checking node env)
                sendNewAnomalyEmail(vendorInfo.name, anomalyClass, machineName, reporterDatetime, id, type, buildAnomalyLink(type, id), 
                () => {
                    successToast("Submit Anomaly Report");
                    setLoading(false)
                    toVendorDashboard()
                })
            } else {
                successToast("Submit Anomaly Report");
                setLoading(false)
                toVendorDashboard()
            }
        }, 
        (error) => {
            setLoading(false)
            errorToast()
        })
    }

    return (
        <Box textAlign="center" fontSize="xl">
            <Grid minH="100vh" p={3}>
                <Container maxW='md'>
                    <VStack spacing={10}>
                        <HStack>
                            <IconButton onClick={toVendorDashboard} icon={<ArrowBackIcon />}/>
                            <Heading cursor="default">{`${isVS ? 'VS' : "EPB"} Anomaly`}</Heading>
                            <UserMenu />
                            {/* <Button onClick={resetForm} variant="ghost" colorScheme='red'>Clear form</Button> */}
                        </HStack>
                        <QuestionGroup sectionName='County Information' isFinal={isCountyInfoFinal}>
                            <FormSelect
                            borderColor={greenShade}
                            children={ CountiesByState.map((element, index) => ( <option key={index}>{element.state}</option>))}
                            label="State Affected"
                            state={state}
                            stateMod={setState}
                            />
                            <FormSelect
                            borderColor={greenShade}
                            children={ countiesOptions?.map((element, index) => ( <option key={index}>{element}</option>))}
                            label="County Affected"
                            state={county}
                            stateMod={setCounty}
                            />
                            <FormText
                            errorMessage="Name is required"
                            input={reporterName}
                            inputMod={setReporterName}
                            isValid={isNameValid}
                            label="Name of Reporter"
                            placeholder="Enter name"
                            validationTip={`Input needs at least ${4 - reporterName.length} more character(s)`}
                            />
                            <FormText
                            errorMessage="Email is required"
                            input={reporterEmail}
                            inputMod={setReporterEmail}
                            isValid={isRepEmailValid}
                            label="Email of Reporter"
                            placeholder="Enter email"
                            validationTip="Field requires an @ and . address at least 2 characters long"
                            />
                            <FormText
                            errorMessage="Date and time are required"
                            input={reporterDatetime}
                            inputMod={setReporterDateTime}
                            isValid={reporterDatetime !== ""}
                            label="Date & Time County Became Aware of Anomaly"                            
                            type='datetime-local'
                            validationTip="Enter both date AND time"
                            />
                            <FormSelect 
                            children={
                                <>
                                    <option value='County Election Office'>County Election Office</option>
                                    <option value='In Person Absentee'>In Person Absentee Voting Location</option>
                                    <option value='Precinct'>Precinct</option>
                                    <option value='Satellite Office'>Satellite Office</option>
                                    <option value="Unknown">Unknown</option>
                                    <option value='Vote Center'>Vote Center</option>
                                    <option value="Other">Other</option>
                                </>
                            }
                            isUnsupervised
                            label="Location Category"
                            state={locationCategory}
                            stateMod={setLocationCategory}
                            />
                            <FormText
                            errorMessage='Location name is required'
                            input={locationName}
                            inputMod={setLocationName}
                            isUnsupervised
                            label="Location Name"
                            placeholder="Enter location name"
                            validationTip={`Input needs at least ${4 - locationName.length} more character(s)`}
                            />
                        </QuestionGroup>
                        <QuestionGroup sectionName={`${isVS ? 'Voting System' : "Electronic Poll Book"} Information`} isFinal={isMachineFinal} >
                            <FormText
                            errorMessage={`${type} name is required`}
                            input={machineName}
                            inputMod={setMachineName}
                            isValid={isMachineNameValid}
                            label={`${type} Name`}
                            placeholder={`Enter ${type} name`}
                            validationTip={`Input needs at least ${4 - machineName.length} more character(s)`}
                            />
                            <FormText 
                            errorMessage={`${type} version is required`}
                            input={machineVersion}
                            inputMod={setMachineVersion}
                            isValid={isMachineVersionValid}
                            label={`${type} Version`}
                            placeholder={`Enter ${type} version`}
                            validationTip={`Input needs to exist`}
                            />
                            { 
                                isVS && 
                                <FormText 
                                errorMessage={`${type} component is required`}
                                input={machineComponent}
                                inputMod={setMachineComponent}
                                isValid={isMachineComponentValid}
                                label={`${type} Component`}
                                placeholder={`Enter ${type} component`}
                                validationTip={`Input needs at least ${4 - machineComponent.length} more character(s)`}
                                />
                            }
                            <FormText
                            errorMessage={`${type} serial number is required`}
                            input={machineSerial}
                            inputMod={setMachineSerial}
                            isValid={isMachineSerialValid}
                            isUnsupervised
                            label={`${type} Serial Number`}
                            placeholder={`Enter ${type} serial number`}
                            validationTip={`Input needs at least ${4 - machineSerial.length} more character(s)`}
                            />
                        </QuestionGroup>
                        <QuestionGroup sectionName="Anomaly Information" isFinal={isAnomalyFinal}>
                            <FormTextArea
                            errorMessage="Issue description is required"
                            helperText={issueHelperText}
                            input={description}
                            inputMod={setDescription}
                            isValid={isDescriptionValid}
                            isSpellChecked
                            label="Issue"
                            placeholder="Enter description of issue"
                            validationTip={`Input needs at least ${60 - description.length} more character(s)`}
                            />
                            <FormTextArea
                            errorMessage="Issue root cause is required"
                            helperText={rootCauseHelperText}
                            input={rootCause}
                            inputMod={setRootCause}
                            isSpellChecked
                            isUnsupervised
                            label="Root Cause"
                            placeholder="Enter description of root cause"
                            validationTip={`Input needs at least ${60 - rootCause.length} more character(s)`}
                            />
                            <FormTextArea
                            errorMessage="Issue resolution is required"
                            helperText={resolutionHelperText}
                            input={resolution}
                            inputMod={setResolution}
                            isSpellChecked
                            isUnsupervised
                            label="Resolution"
                            placeholder="Enter description of resolution"
                            validationTip={`Input needs at least ${60 - resolution.length} more character(s)`}
                            />
                            <FormSelect 
                            borderColor={greenShade}
                            children={
                                <>
                                    <option value={"1"}>Class 1</option>
                                    <option value={"2"}>Class 2</option>
                                </>
                            }
                            helperText={anomalyClassHelperText}
                            label="Class"
                            state={anomalyClass}
                            stateMod={setAnomalyClass}
                            />
                            <FormSelect
                            //borderColor={greenShade}
                            children={classRationaleOptions?.map((element, index) => ( <option key={index}>{element}</option>))}
                            label="Class Rationale"
                            state={classRationale}
                            stateMod={setClassRationale}
                            />
                            { 
                                showClassRationaleTextInput &&
                                <FormTextArea
                                errorMessage="Class rationale is required"
                                input={manualClassRationale}
                                inputMod={setManualClassRationale}
                                isSpellChecked
                                isValid={isManualClassRationaleValid}
                                label="Class Rationale Manual Input"
                                placeholder="Enter anomaly class rationale"
                                validationTip={`Not quite long enough! Your response needs at least ${longBasicLimit - manualClassRationale.length} more character${longBasicLimit - manualClassRationale.length > 1 ? 's' : ""}.`}
                                />
                            }
                        </QuestionGroup>
                        <QuestionGroup sectionName="Submit Anomaly" isFinal={isSubmitValid} >
                            <Button 
                            onClick={submitAnomaly} 
                            isLoading={loading} 
                            isDisabled={!isSubmitValid} 
                            size='lg' 
                            variant='outline' 
                            colorScheme='green'
                            >
                                Submit
                            </Button>
                        </QuestionGroup>
                    </VStack>
                </Container>
            </Grid>
        </Box>
    )
}

export default AnomalyForm