import Anomaly from "./Anomaly"

export default class AnomalyFactory {

  static #headerPropMap = {
    "ID": "id",
    "STATE" : 'state',
    "COUNTY" : 'county',
    "LOCATION_CATEGORY": "locationCategory",
    "LOCATION_NAME": 'locationName',
    "REPORTER_NAME": "reporterName",
    "REPORTER_EMAIL": 'reporterEmail',
    "DATETIME_REPORTER_MADE_AWARE": 'reporterDatetime',
    "MACHINE_COMPONENT": 'machineComponent',
    "MACHINE_NAME": 'machineName',
    "MACHINE_SERIAL": 'machineSerial',
    "MACHINE_VERSION": "machineVersion",
    "ANOMALY_CLASS": "class",
    "ANOMALY_CLASS_RATIONALE": 'classRationale',
    "ANOMALY_DESCRIPTION": "description",
    "ANOMALY_ROOT_CAUSE": "rootCause",
    "ANOMALY_RESOLUTION": "resolution",
    "STATUS": "status"
  }

  static DataMode = {
    "Firebase": 'firebase',
    "AnomalyClass": 'import-to-export',
    "Spreadsheet": "spreadsheet",
  }

  static #spreadsheetExtraHeaders = [
    "COUNTY REPORTER INFORMATION", 
    "COUNTY INFORMATION", 
    "MACHINE INFORMATION", 
    "ANOMALY INFORMATION"
  ]

  data = []
  dataMode = 'spreadsheet'
  #headerMap = []
  #isIdIncludedInImportData = false

  constructor(dataToConvert, dataMode){
    this.data = dataToConvert
    this.dataMode = dataMode
    this.#isIdIncludedInImportData = this.#getIsIdInlcudedInImportData()
  }

  get anomalies(){
    switch (this.dataMode) {
      case AnomalyFactory.DataMode.Firebase:
        return []
      case AnomalyFactory.DataMode.AnomalyClass:
        return this.#convertAnomalyDataToSpreadsheet()
      default: // 'spreadsheet'
        return this.#convertSpreadsheetDataToAnomalies()
    }
  }

  get detectedIdOnImport(){
    return this.#isIdIncludedInImportData
  }

  static convert(data, to, from){
    function extractHeaders(){
      if(!data) return []
      switch (from) {
        case AnomalyFactory.DataMode.AnomalyClass:
        case AnomalyFactory.DataMode.Firebase:   
          return Object.keys(data)
        default:
          return []
      }
    }

    function getHeader(headerList){
      if(headerList){
        switch (to) {
          case AnomalyFactory.DataMode.AnomalyClass:
          case AnomalyFactory.DataMode.Firebase:
            return headerList[1]
          default:
            return headerList[0]
        }
      }
    }

    let temp = {}
    extractHeaders().forEach(header => {
      for(const kvpair of Object.entries(this.#headerPropMap)){
        if(kvpair.includes(header)){
          temp[getHeader(kvpair)] = data[header]
        }
      }
    })
    return temp
  }

  static spreadsheetHeaders(isEpb){
    let temp = {}
    
    Object.keys(this.#headerPropMap)
    .filter(hKey => !['STATUS', "ID", ...(isEpb ? ['MACHINE_COMPONENT'] : [])].includes(hKey))
    .forEach(headerKey => temp[headerKey] = "")

    return temp
  }


  #convertSpreadsheetDataToAnomalies(){
    //Figure out which index is the correct for headers
    const headerIndex = this.#findDataHeaderIndex()
    //Find header indexes and replace spreadsheet header with object prop name
    //ex. LOCATION_CATEGORY -> { locationCategory: 5 }
    Object.keys(AnomalyFactory.#headerPropMap).forEach(header => {
      this.#headerMap.push({ [AnomalyFactory.#headerPropMap[header]]: this.data[headerIndex].findIndex(head => head === header) })
    })
    
    //We don't care about the first n + 1 arrays from spreadsheet, they are headers
    return this.data.slice(headerIndex + 1).map(a => {
        let tempAnomaly = {}
        this.#headerMap.forEach(header => {
          for(const [prop, index] of Object.entries(header)){
            if(index !== -1){ //header is not in spreadsheet if -1
              tempAnomaly[prop] = a[index]
            }
          }
        })
        return new Anomaly(tempAnomaly)
      }) 
  }

  #convertAnomalyDataToSpreadsheet(){
    // Reverse the headermap black magic
    // Assign map keys where you find map values in object keys
    return this.data.map(a => {
      let tempObj = {}
      for (const [key, val] of Object.entries(a)){
        for (const [hKey, hVal] of Object.entries(AnomalyFactory.#headerPropMap)){
          if ( hVal === key ) {
            tempObj[hKey] = val
          }
        }
      }
      return tempObj
    })
  }

  #getIsIdInlcudedInImportData(){
    switch (this.dataMode) {
      case AnomalyFactory.DataMode.Firebase:
      case AnomalyFactory.DataMode.AnomalyClass:
        return true    
      default:
        return (this.data[this.#findDataHeaderIndex()].find(header => header === "ID")) !== undefined
    }
  }
  
  #findDataHeaderIndex(){
    if(this.data[0].some(header => 
      AnomalyFactory.#spreadsheetExtraHeaders.includes(header)
    )){ return 1 }
    return 0
  }
}