import { useState, useEffect } from 'react'
import Anomalies from '../Data/Firestore/anomalies'
import { useAuth } from '../Data/Contexts/AuthContext'
import ROLES from '../Data/Constants/Roles.json'

export default function useFetchAnomalies(){

    const { accountInfo: { role, designation, companyName } } = useAuth()
    const [anomalies, setAnomalies] = useState([])

    useEffect(() => {
        function addCheckedAttThenSetState(anomalies){
            if (process.env.NODE_ENV === 'production'){ 
                //Remove dev anomalies in production
                //TODO REMOVE THIS SHIT (checking node env)
                setAnomalies(anomalies.filter(anomaly => anomaly.vendorName !== "VSTOP Developer Vendor").map(a => ({...a, selected: false})))
            } else {
                setAnomalies(anomalies.map(a => ({...a, selected: false})))
            }
        }

        if (role === ROLES.Vendor) {
            Anomalies.getVendorAnomaliesListener(companyName, addCheckedAttThenSetState)
        } else if (role === ROLES.Vstop) {
            Anomalies.getAnomaliesListener(addCheckedAttThenSetState)
        } else if (role === ROLES.State && designation === "State Contractor") {
            Anomalies.getContractorAnomaliesListener(addCheckedAttThenSetState)
        }
        
    }, [ role, companyName, designation ])

    return { anomalies }
}