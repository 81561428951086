import { useState, useEffect } from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Textarea,
  InputGroup,
  InputRightElement,
  Select,
  Tooltip,
  Button,
  useColorModeValue,
  ButtonGroup,
  IconButton,
} from '@chakra-ui/react';
import { CheckIcon, CloseIcon, TimeIcon, EditIcon } from '@chakra-ui/icons';
import PropTypes from 'prop-types';

export const FormText = ({
  borderColor,
  defaultTouched,
  errorMessage,
  helperText,
  input,
  inputMod,
  isDisabled,
  isRequired,
  isUnsupervised,
  isValid,
  label,
  onKeyPress,
  paddingX,
  placeholder,
  stateArray,
  type,
  validationTip,
  variant,
}) => {
  const [isTouched, setIsTouched] = useState(defaultTouched);
  const [state, stateMod] = inputMod ? [input, inputMod] : stateArray;
  const greenShade = useColorModeValue('green.300', 'green');
  const redShade = useColorModeValue('red.300', 'red');
  const yellowShade = useColorModeValue('yellow.300', 'yellow');

  const isTouchedAndEmpty = isTouched && state === '' && !isUnsupervised;

  useEffect(() => {
    if(state !== "" && state !== undefined){
      setIsTouched(true)
    }
  }, [state])
  

  function handleChange(e) {
    setIsTouched(true);
    stateMod(e.target.value);
  }

  function handleKeyPress(e) {
    if (e.key === 'Enter' && onKeyPress) onKeyPress();
  }

  return (
    <FormControl isInvalid={isTouchedAndEmpty} isRequired={!isUnsupervised && isRequired} paddingX={paddingX}>
      <FormLabel>{label}</FormLabel>
      <InputGroup>
        <Input
          isDisabled={isDisabled}
          value={state}
          onChange={handleChange}
          placeholder={placeholder}
          variant={variant}
          type={type}
          borderColor={(!isUnsupervised && isValid) ? greenShade : borderColor}
          onKeyPress={handleKeyPress}
        />
        <InputRightElement
          children={
            !isUnsupervised &&
            (isTouched ? (
              isValid ? (
                <CheckIcon color={greenShade} />
              ) : (
                <Tooltip label={validationTip} aria-label='input validation tooltip'>
                  <TimeIcon color={yellowShade} />
                </Tooltip>
              )
            ) : (
              <Tooltip label={validationTip} aria-label='input validation tooltip'>
                <CloseIcon color={redShade} />
              </Tooltip>
            ))
          }
        />
      </InputGroup>
      {isTouchedAndEmpty && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
      {helperText && (
        <FormHelperText justifySelf={'start'} textAlign="start">
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

FormText.defaultProps = {
  borderColor: '',
  defaultTouched: false,
  errorMessage: 'This is an error message. Your input is empty',
  helperText: '',
  isRequired: true,
  isUnsupervised: false,
  isValid: true,
  onKeyPress: e => {},
  placeholder: 'Default placeholder',
  stateArray: [],
  type: 'text',
  variant: 'filled',
  paddingX: 0
};

FormText.propTypes = {
  borderColor: PropTypes.string,
  defaultTouched: PropTypes.bool,
  errorMessage: PropTypes.string,
  helperText: PropTypes.string,
  isRequired: PropTypes.bool,
  isUnsupervised: PropTypes.bool,
  isValid: PropTypes.bool,
  onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  stateArray: PropTypes.array,
  type: PropTypes.string,
  variant: PropTypes.string,
  paddingX: PropTypes.number
};

export const FormEditable = ({
  defaultValue,
  isRequired,
  isTextarea = false,
  label,
  setState,
  type = 'text',
  validCheckFunc,
}) => {

  const [isEditMode, setIsEditMode] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)
  const [value, setValue] = useState(defaultValue)
  
  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    setIsInvalid(!validCheckFunc(value)) //eslint-disable-next-line
  }, [value])

  function handleChange(event){
    setValue(event.target.value)
  }

  function handleEdit(){
    setIsEditMode(true)
  }

  function handleSave(){
    setState(value)
    setIsEditMode(false)
  }

  function handleCancel(){
    setValue(defaultValue)
    setIsEditMode(false)
  }
  
  if(isTextarea){
    return (
      <FormControl isRequired={isRequired} isInvalid={isInvalid}>
        <FormLabel>{label}</FormLabel>
        <InputGroup size='lg'>
          <Textarea
          _disabled={{ opacity: 0.8, cursor: 'not-allowed' }}
          _invalid={{
            border: `2px solid ${isRequired ? 'red' : 'blue'}`
          }}
          height={120}
          isDisabled={!isEditMode}
          onChange={handleChange}
          placeholder={defaultValue}
          pr={ isEditMode ? '4rem' : '2rem'}
          spellCheck
          type={type}
          value={value}
          />
          <InputRightElement width={ isEditMode ? '7rem' : '5rem'}>
            { isEditMode 
              ? <ButtonGroup size='sm' isAttached variant='outline'>
                  <IconButton aria-label='Save entry edit' icon={<CheckIcon />} onClick={handleSave} _hover={{ backgroundColor: '#22ff2230'}}/>
                  <IconButton aria-label='Cancel endtry edit' icon={<CloseIcon />} onClick={handleCancel} _hover={{ backgroundColor: '#ff222230'}}/>
                </ButtonGroup>
              : <IconButton icon={<EditIcon />} onClick={handleEdit} size='sm'/>
            }
          </InputRightElement>
        </InputGroup>
      </FormControl>
    )
  }
  return (
    <FormControl isRequired={isRequired} isInvalid={isInvalid}>
      <FormLabel>{label}</FormLabel>
      <InputGroup size='lg'>
        <Input
          _disabled={{ opacity: 0.8, cursor: 'not-allowed' }}
          _invalid={{
            border: `2px solid ${isRequired ? 'red' : 'blue'}`
          }}
          pr='4.5rem'
          type={type}
          placeholder={defaultValue}
          value={value}
          onChange={handleChange}
          isDisabled={!isEditMode}
        />
        <InputRightElement width='4.5rem'>
          { isEditMode 
            ? <ButtonGroup size='sm' isAttached variant='outline'>
                <IconButton aria-label='Save entry edit' icon={<CheckIcon />} onClick={handleSave} _hover={{ backgroundColor: '#22ff2230'}}/>
                <IconButton aria-label='Cancel endtry edit' icon={<CloseIcon />} onClick={handleCancel} _hover={{ backgroundColor: '#ff222230'}}/>
              </ButtonGroup>
            : <IconButton icon={<EditIcon />} onClick={handleEdit} size='sm'/>
          }
        </InputRightElement>
      </InputGroup>
    </FormControl>
  )
}

export const FormSelectEditable = ({
  children,
  defaultValue,
  isRequired,
  label,
  setState,
  validCheckFunc,
}) => {

  const [isEditMode, setIsEditMode] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    setIsInvalid(!validCheckFunc(value)) //eslint-disable-next-line
  }, [value])

  function handleChange(event){
    setValue(event.target.value)
  }

  function handleEdit(){
    setIsEditMode(true)
  }

  function handleSave(){
    setState(value)
    setIsEditMode(false)
  }

  function handleCancel(){
    setValue(defaultValue)
    setIsEditMode(false)
  }

  return (
    <FormControl isRequired={isRequired} isInvalid={isInvalid}>
      <FormLabel>{label}</FormLabel>
      <InputGroup size='lg'>
        <Select
        placeholder={defaultValue}
        value={value} 
        onChange={handleChange}
        isDisabled={!isEditMode}
        iconSize={"0"}
        >
          {children}
        </Select>
        <InputRightElement width='4.5rem' pr={ isEditMode ? 4 : 0 }>
          { isEditMode 
            ? <ButtonGroup size='sm' isAttached variant='outline'>
                <IconButton aria-label='Save entry edit' icon={<CheckIcon />} onClick={handleSave} _hover={{ backgroundColor: '#22ff2230'}}/>
                <IconButton aria-label='Cancel endtry edit' icon={<CloseIcon />} onClick={handleCancel} _hover={{ backgroundColor: '#ff222230'}}/>
              </ButtonGroup>
            : <IconButton icon={<EditIcon />} onClick={handleEdit} size='sm'/>
          }
        </InputRightElement>
      </InputGroup>
    </FormControl>
  )
}


export const FormTextArea = ({
  borderColor,
  defaultTouched,
  errorMessage,
  helperText,
  input,
  inputMod,
  isSpellChecked,
  isUnsupervised,
  isValid,
  label,
  placeholder,
  stateArray,
  validationTip,
  variant,
}) => {
  const [state, stateMod] = inputMod ? [input, inputMod] : stateArray;
  const [isTouched, setIsTouched] = useState(defaultTouched);
  const greenShade = useColorModeValue('green.300', 'green');
  const redShade = useColorModeValue('red.300', 'red');
  const yellowShade = useColorModeValue('yellow.300', 'yellow');

  useEffect(() => {
    if(state !== "" && state !== undefined){
      setIsTouched(true)
    }
  }, [state])

  const isTouchedAndEmpty = isTouched && state === '';

  function handleChange(e) {
    setIsTouched(true);
    stateMod(e.target.value);
  }

  return (
    <FormControl
      isInvalid={isTouchedAndEmpty && errorMessage}
      isRequired={isUnsupervised ? false : label ? true : false}
    >
      <FormLabel>{label}</FormLabel>
      <InputGroup>
        <Textarea
          spellCheck={isSpellChecked}
          value={state}
          onChange={handleChange}
          placeholder={placeholder}
          variant={variant}
          height={120}
          borderColor={!isUnsupervised && isValid ? greenShade : borderColor}
          borderWidth={2}
        />
        {
          !isUnsupervised &&
          <InputRightElement
            children={
              isTouched ? (
                isValid ? (
                  <CheckIcon color={greenShade} />
                ) : (
                  <Tooltip label={validationTip} aria-label='input validation tooltip'>
                    <TimeIcon color={yellowShade} />
                  </Tooltip>
                )
              ) : (
                <Tooltip label={validationTip} aria-label='input validation tooltip'>
                  <CloseIcon color={redShade} />
                </Tooltip>
              )
            }
          />
        }
      </InputGroup>
      {isTouchedAndEmpty && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
      {helperText && (
        <FormHelperText justifySelf={'start'} textAlign="start">
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

FormTextArea.defaultProps = {
  borderColor: '',
  defaultTouched: false,
  errorMessage: 'This is an error message. Your input is empty',
  helperText: '',
  isSpellChecked: false,
  isValid: true,
  label: 'Default Label',
  placeholder: 'Default placeholder',
  stateArray: [],
  variant: 'filled',
};

FormTextArea.propTypes = {
  borderColor: PropTypes.string,
  defaultTouched: PropTypes.bool,
  errorMessage: PropTypes.string,
  helperText: PropTypes.string,
  isSpellChecked: PropTypes.bool,
  isValid: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  stateArray: PropTypes.array,
  variant: PropTypes.string,
};

export const FormSelect = ({
  borderColor,
  children,
  errorMessage,
  helperText,
  isInvalid,
  isUnsupervised,
  label,
  paddingX,
  state,
  stateArray,
  stateMod,
}) => {
  const [input, inputMod] = stateArray ? stateArray : [state, stateMod];

  const handleChange = e => {
    inputMod(e.target.value);
  };

  return (
    <FormControl isInvalid={isInvalid} isRequired={!isUnsupervised} paddingX={paddingX}>
      <FormLabel>{label}</FormLabel>
      <Select value={input} onChange={handleChange} borderColor={borderColor} borderWidth={2}>
        {children}
      </Select>
      {!isInvalid ? '' : <FormErrorMessage>{errorMessage}</FormErrorMessage>}
      {helperText && (
        <FormHelperText justifySelf={'start'} textAlign="start">
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

FormSelect.defaultProps = {
  borderColor: '',
  errorMessage: '',
  helperText: '',
  isInvalid: false,
  isUnsupervised: false,
  paddingX: 0,
};

FormSelect.propTypes = {
  borderColor: PropTypes.string,
  errorMessage: PropTypes.string,
  helperText: PropTypes.string,
  isInvalid: PropTypes.bool,
  isRequired: PropTypes.bool,
  isUnsupervised: PropTypes.bool,
  label: PropTypes.string,
  paddingX: PropTypes.number
};

export const FormPassword = ({
  borderColor,
  errorMessage,
  input,
  inputMod,
  isUnsupervised,
  isValid,
  label,
  onKeyPress,
  placeholder,
  stateArray,
  variant,
}) => {
  const [isTouched, setIsTouched] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [state, stateMod] = inputMod ? [input, inputMod] : stateArray;
  const greenShade = useColorModeValue('green.300', 'green');
  const redShade = useColorModeValue('red.300', 'red');
  const yellowShade = useColorModeValue('yellow.300', 'yellow');

  const isTouchedAndEmpty = isTouched && state === '' && !isUnsupervised;

  function toggleShow() {
    setShowPassword(!showPassword);
  }

  function handleChange(e) {
    setIsTouched(true);
    stateMod(e.target.value);
  }

  function handleKeyPress(e) {
    if (e.key === 'Enter' && onKeyPress) onKeyPress();
  }

  return (
    <FormControl isInvalid={isTouchedAndEmpty} isRequired={!isUnsupervised}>
      <FormLabel>{label}</FormLabel>
      <Tooltip
        isDisabled={isUnsupervised}
        hasArrow
        label="Password must contain at least one of each: uppercase letter, lowercase letter, number. Minimum 8 characters"
        placement="right"
        openDelay={500}
      >
        <InputGroup>
          <Input
            value={state}
            onChange={handleChange}
            placeholder={placeholder}
            variant={variant}
            type={showPassword ? 'text' : 'password'}
            borderColor={borderColor}
            onKeyPress={handleKeyPress}
          />
          <InputRightElement
            width="25%"
            children={
              <>
                {!isUnsupervised &&
                  (isTouched ? (
                    isValid ? (
                      <CheckIcon color={greenShade} />
                    ) : (
                      <TimeIcon color={yellowShade} />
                    )
                  ) : (
                    <CloseIcon color={redShade} />
                  ))}
                <Button
                  marginLeft={2}
                  h="1.75rem"
                  size="sm"
                  onClick={toggleShow}
                >
                  {showPassword ? 'Hide' : 'Show'}
                </Button>
              </>
            }
          />
        </InputGroup>
      </Tooltip>
      {isTouchedAndEmpty && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
};

FormPassword.defaultProps = {
  borderColor: '',
  errorMessage: 'This is an error message. Your input is empty',
  isUnsupervised: false,
  isValid: true,
  label: 'Password',
  onKeyPress: e => {},
  placeholder: 'Default placeholder',
  stateArray: [],
  variant: 'filled',
};

FormPassword.propTypes = {
  borderColor: PropTypes.string,
  errorMessage: PropTypes.string,
  isUnsupervised: PropTypes.bool,
  isValid: PropTypes.bool,
  label: PropTypes.string,
  onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  stateArray: PropTypes.array,
  variant: PropTypes.string,
};

export const FormReadOnly = ({ label, value }) => (
  <FormControl>
    <FormLabel>{label}</FormLabel>
    <Input variant="filled" defaultValue={value} readOnly />
  </FormControl>
);

FormReadOnly.defaultProps = {
  label: 'ReadOnly',
  value: ' ',
};

FormReadOnly.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};
