import { useState, useEffect } from 'react'
import Anomalies, { vendorCountQuery} from '../Data/Firestore/anomalies';

export default function useFetchCount(companyName, mode, status){

    const [countLoading, setCountLoading] = useState(true)
    const [count, setCount] = useState(0)

    useEffect(() => {
        Anomalies.getCountOf(
            vendorCountQuery(companyName, mode, status), 
            (count) => {
                setCount(count)
                setCountLoading(false)
            }
        )       
    }, [companyName, mode, status])    

    return [ count, countLoading ]
}