import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  IconButton,
  useDisclosure
} from '@chakra-ui/react'
import { DeleteIcon } from '@chakra-ui/icons'
import Anomalies from '../../Data/Firestore/anomalies'
import useSystemToast from '../../Custom_Hooks/useSystemToast'


export function DeleteAnomalyModal({ anomaly }) {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { errorToast, successToast } = useSystemToast()

  return (
    <>
      <IconButton aria-label='Delete anomaly' colorScheme='red' icon={<DeleteIcon />} onClick={onOpen} variant='outline' />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Anomaly</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete this anomaly?
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' variant='ghost' mr={3} onClick={onClose}>
              No, Cancel
            </Button>
            <Button colorScheme='red' onClick={deleteAnomaly}>Yes, Delete</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )

  function deleteAnomaly(){
    Anomalies.deleteAnomaly(anomaly.id, onSuccess, onFail)
  }

  function onSuccess(){
    successToast("Delete Anomaly")
  }

  function onFail(){
    errorToast("Delete Anomaly")
  }
}