import "./Styles/App.css"
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import TableContextProvider from './Data/Contexts/TableContext';
import AuthProvider from './Data/Contexts/AuthContext';
import ROLES from './Data/Constants/Roles.json';
import ROUTES from './Data/Constants/Routes.json';
import AccessDenied from './Pages/AccessDenied';
import AnomalyDashboard from './Pages/AnomalyDashboard';
import AnomalyForm from './Pages/vendor/AnomalyForm';
import Login from './Pages/Login';
import NotFound from './Pages/NotFound';
import PasswordCreation from './Pages/PasswordCreation';
import PrivateRoute from './Pages/PrivateRoute';
import Register from './Pages/Register';
import SingleAnomaly from './Pages/SingleAnomaly';
import VendorLobby from './Pages/vendor/VendorLobby';
import BulkUpload from './Pages/vendor/BulkUpload';
import AdminDashboard from "./Pages/AdminDashboard";

function App() {
  return (
    <div id='page-content'>
      <AuthProvider>
        <TableContextProvider>
          <BrowserRouter>
            <Routes>
              <Route path={ROUTES.Login} element={<Login />}/>
              <Route
                path={ROUTES.AdminDashboard}
                element={
                  <PrivateRoute roles={[ROLES.Administrator]}>
                    <AdminDashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.AnomalyDashboard}
                element={
                  <PrivateRoute roles={[ROLES.Vstop, ROLES.Vendor, ROLES.State]}>
                    <AnomalyDashboard/>
                  </PrivateRoute>
                }
              />
              <Route 
                path={ROUTES.VendorDashboard}
                element={
                  <PrivateRoute roles={[ROLES.Vendor]}>
                    <VendorLobby />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.SingleAnomaly}
                element={
                  <PrivateRoute roles={[ROLES.Vstop, ROLES.State, ROLES.Vendor]}>
                    <SingleAnomaly />
                  </PrivateRoute>
                }
              />
              <Route  
                path={ROUTES.EPBReport}
                element={
                  <PrivateRoute roles={[ROLES.Vendor]}>
                    <AnomalyForm />
                  </PrivateRoute>
                }
              />
              <Route  
                path={ROUTES.VSReport}
                element={
                  <PrivateRoute roles={[ROLES.Vendor]}>
                    <AnomalyForm isVS />
                  </PrivateRoute>
                }
              />
              <Route 
                path={ROUTES.BulkUpload}
                element={
                  <PrivateRoute roles={[ROLES.Vendor]}>
                    <BulkUpload />
                  </PrivateRoute>
                }
              />
              <Route
              path={ROUTES.PasswordCreation}
              element={
                <PrivateRoute roles={[ROLES.Vstop, ROLES.State, ROLES.Vendor]}>
                  <PasswordCreation />
                </PrivateRoute>
              }
              />
              <Route path={ROUTES.Register} element={<Register />} />
              <Route path={ROUTES.AccessDenied} element={<AccessDenied />}/>
              <Route path={ROUTES.NotFound} element={<NotFound />} />
              <Route path={ROUTES.Wildcard} element={<Navigate to={ROUTES.NotFound} replace />} />
            </Routes>
          </BrowserRouter>
        </TableContextProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
