import { Box, Heading, Text, VStack, Container, HStack, Button, Badge, Tooltip, useColorModeValue } from '@chakra-ui/react';
import { useAuth } from '../../Data/Contexts/AuthContext';
import { getTooltipLabel } from '../../Utils/TextFormatting';
import useNavToPage from '../../Custom_Hooks/useNavToPage';
import LogOutButton from '../../Components/Common/LogOutButton';
import useFetchCount from '../../Custom_Hooks/useFetchCount';
import UserMenu from '../../Components/Common/UserMenu';
import STATUS from '../../Data/Constants/AnomalyStatus.json'
import '../../Styles/VendorLobby.css'

const VendorLobby = () => {

    const { accountInfo, vendorInfo } = useAuth();
    const {  toEPBReport, toVSReport, toAnomalyDashboard, toBulkUploadVS, toBulkUploadEPB } = useNavToPage()
    const [ epb, epbLoading ] = useFetchCount(accountInfo.companyName, 'EPB', STATUS.Revised)
    const [ vs, vsLoading ] = useFetchCount(accountInfo.companyName, 'VS', STATUS.Revised)
    const mode = useColorModeValue("light","dark")
    const tooltip = getTooltipLabel({ vs, epb })
    
    return (
        <Box textAlign="center" fontSize="xl" marginTop={5}>
            <Container maxW='1500px'>
                <VStack spacing={8}>
                    <HStack spacing={10} justifyContent='flex-end'>
                        <LogOutButton />
                        <Heading as='h2' size='3xl'>Anomaly Reporting Platform</Heading>
                        <UserMenu />
                    </HStack>
                    <Heading as='h3' size='lg'>{`Welcome, ${accountInfo.name}!`}</Heading>
                </VStack>
                <VStack marginTop={20} spacing={8}>
                    <Text>I am reporting an anomaly for a....</Text>
                    <HStack spacing={10}>
                        <VStack>
                            <Button onClick={toEPBReport} h='300px' w='300px' variant='outline' className={`vendor-lobby-${mode}-button`} isDisabled={!vendorInfo.isEPBVendor}>
                                <Heading size='lg'>
                                    Electronic Poll Book
                                </Heading>
                            </Button>
                            <Button onClick={toBulkUploadEPB} className={`vendor-lobby-${mode}-button`} variant='outline' isDisabled={!vendorInfo.isEPBVendor}>
                                EPB Bulk
                            </Button>
                        </VStack>
                        <VStack>
                            <Button onClick={toVSReport} h='300px' w='300px' variant='outline' className={`vendor-lobby-${mode}-button`} isDisabled={!vendorInfo.isVSVendor}>
                                <Heading size='lg'>
                                    Voting System
                                </Heading>
                            </Button>
                            <Button onClick={toBulkUploadVS} className={`vendor-lobby-${mode}-button`} variant='outline' isDisabled={!vendorInfo.isVSVendor}>
                                VS Bulk
                            </Button>
                        </VStack>
                    </HStack>
                    <HStack>
                        {
                            !vsLoading && 
                            <Badge colorScheme='blue' className='noselect' fontSize='1em'>
                                {vs}
                            </Badge>
                        }
                        {
                            !epbLoading && 
                            <Badge colorScheme='green' className='noselect'  fontSize='1em'>
                                {epb}
                            </Badge>
                        }
                        <Tooltip maxW={260} label={tooltip}>
                            <Button
                            isLoading={epbLoading || vsLoading}
                            loadingText={"Loading..."}
                            onClick={toAnomalyDashboard}
                            variant='outline'
                            colorScheme={vs > 0 || epb > 0 ? 'yellow' :'gray'}
                            className={`vendor-lobby-${mode}-button`}
                            >
                                Previously Reported
                            </Button>
                        </Tooltip>
                    </HStack>
                </VStack>
            </Container>
        </Box>
    )
}

export default VendorLobby;