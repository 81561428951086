import { HStack, VStack } from '@chakra-ui/react'
import DisplayTableColumnsPicker from './TableFeaturesBar/DisplayTableColumnsPicker'
import TableTypeSwitch from './TableFeaturesBar/TableTypeSwitch'
import ExportXLSXModal from './TableFeaturesBar/ExportXLSXModal'
import PrintToPDF from './TableFeaturesBar/PrintToPDF'
import FilterMenu from './TableFeaturesBar/FilterMenu'
import FilterList from './TableFeaturesBar/FilterList'
//import DeleteAnomalies from './TableFeaturesBar/DeleteAnomalies'

export default function TableFeaturesBar() {

    return (
            <VStack>
                <HStack width="100%" id='table-controls'>
                    <TableTypeSwitch />
                    <HStack spacing={15}>
                        {/* <DeleteAnomalies /> */}
                        <PrintToPDF/>
                        <FilterMenu isMainMenu/>
                        <DisplayTableColumnsPicker />
                        <ExportXLSXModal/>
                    </HStack>
                </HStack>
                <FilterList />        
            </VStack>
    )
}