import { Tooltip, Button } from "@chakra-ui/react"
import useReviewAnomaly from "../../Custom_Hooks/useReviewAnomaly"
import useAnomalyValidity from "../../Custom_Hooks/useAnomalyValidity"

const AcceptAnomalyButton = ({ isCommentValid, anomaly, comment }) => {

    const { acceptAnomaly, submitCommentLoading } = useReviewAnomaly(anomaly, comment)
    const { isAnomalyPropsValid, invalidProps } = useAnomalyValidity(anomaly)

    return (
        <Tooltip label={!isAnomalyPropsValid && `All fields must be sufficiently completed before anomaly can be accepted.\n${invalidProps} ${invalidProps.length === 1 ? 'is' : 'are'} invalid`}>
            <div>
                <Button 
                size='lg'
                variant='outline'
                colorScheme='green' 
                onClick={acceptAnomaly}
                isDisabled={isCommentValid || !isAnomalyPropsValid}
                isLoading={submitCommentLoading}
                >
                    Accept Anomaly
                </Button>
            </div>
        </Tooltip>
    )
}

export default AcceptAnomalyButton