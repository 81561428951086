import { useState, useEffect } from 'react'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, useDisclosure, VStack, HStack } from '@chakra-ui/react'
import { FormText, FormSelect } from '../../Common/FormInputs'
import ROLES from '../../../Data/Constants/Roles'
import Accounts from '../../../Data/Firestore/accounts'
import useSystemToast from '../../../Custom_Hooks/useSystemToast'
import Vendors from '../../../Data/Firestore/vendors.ts'

const ModifyUserModal = ({ user }) => {

    const vstop = ['VSTOP'];
    const state = ['State of Indiana'];
    const independent = ['BakerTilly', 'Civix'];
    const designations = [
        'Election Systems Vendor',
        'Indiana Secretary of State',
        'Indiana Election Division',
        'State Contractor',
        'Voting System Technical Oversight Program',
    ];

    const { isOpen, onOpen, onClose } = useDisclosure()
    const { successToast, errorToast } = useSystemToast()

    const [companyName, setCompanyName] = useState(user.companyName)
    const [designation, setDesignation] = useState(user.designation)
    const [email, setEmail] = useState(user.email)
    const [name, setName] = useState(user.name);
    const [role, setRole] = useState(user.role);
    const [vendorId, setVendorId] = useState(user.vendorId)
    const [vendors, setVendors] = useState([])
    const [availableRoles, setAvailableRoles] = useState([user.role])
    const [companyNamesToUse, setCompanyNamesToUse] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        async function getVendors(){
            const vendors = await Vendors.getAllVendors()
            setVendors(vendors)
        }

        getVendors()

        setCompanyName(user.companyName)
        setDesignation(user.designation)
        setEmail(user.email)
        setName(user.name)
        setRole(user.role)
        setVendorId(user.vendorId)
    }, [user])

    useEffect(() => {
        if(designation === 'Election Systems Vendor'){
            const vendor = vendors.find(v => v.name === companyName)
            if(vendor){
                setVendorId(vendor.id)
            } else {
                setVendorId('')
            }
        }
    }, [companyName, designation, vendors])

    useEffect(() => {
        switch (designation) {
          case 'Indiana Secretary of State':
            setCompanyNamesToUse(state);
            setCompanyName('State of Indiana');
            setRole(ROLES.State);
            setAvailableRoles([ROLES.State])
            setVendorId('')
            break;
          case 'Indiana Election Division':
            setCompanyNamesToUse(state);
            setCompanyName('State of Indiana');
            setRole(ROLES.State);
            setAvailableRoles([ROLES.State])
            setVendorId('')
            break;
          case 'State Contractor':
            setCompanyNamesToUse(independent);
            setCompanyName('BakerTilly');
            setRole(ROLES.State);
            setAvailableRoles([ROLES.State])
            setVendorId('')
            break;
          case 'Voting System Technical Oversight Program':
            setCompanyNamesToUse(vstop);
            setCompanyName('VSTOP');
            if (user.role === ROLES.Administrator) {
                setRole(ROLES.Administrator)
            } else {
                setRole(ROLES.Vstop)
            }
            setAvailableRoles([ROLES.Administrator, ROLES.Vstop])
            setVendorId('')
            break;
          default:
            const mapped = vendors.map(v => (v.name));
            setCompanyNamesToUse(mapped);
            setCompanyName('Election Systems & Solutions');
            setRole(ROLES.Vendor);
            setAvailableRoles([ROLES.Vendor])
            break;
        } //eslint-disable-next-line
    }, [designation]);

    function updateUser(){
        setLoading(true)
        Accounts.updateAccount(email, {
            companyName,
            designation,
            email,
            name,
            role,
            vendorId
        }, 
        () => {
            setLoading(false)
            successToast()
        }, 
        () => {
            setLoading(false)
            errorToast()
        })
    }

    function reset(){
        setCompanyName(user.companyName)
        setDesignation(user.designation)
        setEmail(user.email)
        setName(user.name)
        setRole(user.role)
        setVendorId(user.vendorId)
    }
    
    return (
        <>
        <Button onClick={onOpen} size='sm'>Modify</Button>

        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Modify User: {user.name}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack>
                        <FormText 
                        input={email}
                        inputMod={setEmail}
                        isUnsupervised 
                        label="Email" 
                        />
                        <FormText 
                        input={name}
                        inputMod={setName}
                        isUnsupervised
                        label="Name" 
                        />
                        <FormSelect
                        children={designations.map((val, index) => (
                            <option key={index}>{val}</option>
                        ))}
                        isUnsupervised
                        label="Designation"
                        state={designation}
                        stateMod={setDesignation} 
                        />
                        { 
                            (designation === "Election Systems Vendor" || designation === "State Contractor")
                            &&  <FormSelect
                                children={companyNamesToUse.map((val, index) => (
                                    <option key={index}>{val}</option>
                                ))}
                                isUnsupervised
                                label="Company Name"
                                state={companyName}
                                stateMod={setCompanyName} 
                                />
                        }
                        { 
                            (designation === 'Election Systems Vendor') 
                            &&  <FormText
                                input={vendorId}
                                inputMod={setVendorId}
                                isDisabled={true}
                                isUnsupervised={true}
                                label="Vendor Id"
                                /> 
                        }
                        { 
                            (designation === 'Voting System Technical Oversight Program')
                            &&  <FormSelect
                                children={ availableRoles.map((val, index) => ( 
                                    <option key={index}>{val}</option>
                                )) }
                                isUnsupervised
                                label="Role"
                                state={role}
                                stateMod={setRole} 
                                />
                        }
                    </VStack>
                </ModalBody>
                <ModalFooter display='flex' style={{ justifyContent: 'space-between'}}>
                    <Button colorScheme='red' variant='outline' onClick={reset}>
                        Reset to Default
                    </Button>
                    <HStack>
                        <Button colorScheme='blue' variant='outline' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button colorScheme='green' isLoading={loading} variant='outline' onClick={updateUser}>
                            Submit
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
        </>
    )
}

export default ModifyUserModal