import { Menu, MenuButton, MenuList, MenuItem, IconButton, Button } from '@chakra-ui/react'
import { useContext } from 'react'
import { AiOutlineFilter } from 'react-icons/ai'
import { CheckIcon } from '@chakra-ui/icons'
import { TableContext } from '../../../../Data/Contexts/TableContext'

export default function FilterMenu({ isMainMenu }){

    const { filterInformation } = useContext(TableContext)

    return (
        <Menu>
            {
                isMainMenu
                ?   <MenuButton as={IconButton} icon={<AiOutlineFilter />} aria-label='Anomaly table filter' />
                :   <MenuButton as={Button} aria-label='Add anomaly table filter' variant={'ghost'} >+ Add Filter</MenuButton>
            }
            <MenuList>
                {filterInformation.sort(sortFunc).filter(onlyShowUnselected).map((filterControls, index) => (
                    <CustomMenuItem key={index} {...filterControls} />
                ))}
            </MenuList>
        </Menu>
    )

    function sortFunc(a,b){
        if( a.title === b.title){
            return 0
        } else if (a.title > b.title){
            return 1
        } else {
            return -1
        }
    }

    function onlyShowUnselected(propControl){
        return isMainMenu ? true : !propControl.state
    }

}

function CustomMenuItem({ icon, title, func, filterType}){

    const { filterArray, setFilterArray } = useContext(TableContext)

    const isInArray = filterArray.findIndex(filter => filter.title === title) !== -1 

    return (
        <MenuItem fontSize={16} icon={icon} defaultChecked={isInArray} onClick={handleClick} command={isInArray && <CheckIcon />}>
            {title}
        </MenuItem>
    )

    function handleClick(){
        if (isInArray) {
            //Find entry in array and remove at index
            const filterIndex = filterArray.map(filter => filter.title).indexOf(title) //Find index of object with title as its key
            setFilterArray(prevArray => prevArray.splice(filterIndex, 1)) //Set filter value to current value without object at specified index 
        } else {
            //Add entry to array with blank value
            setFilterArray(prevArray => 
                [ ...prevArray, 
                    { 
                        title, 
                        value: "",
                        comparator: getComparatorDefault(), 
                        type: filterType, 
                        filterFunc: (a) => (`${func(a)}`.toLowerCase())
                    }
                ]
            )
        }
    }

    function getComparatorDefault(){
        switch (filterType) {
            case 'text':
                return 'contains'
            case 'number':
                return 'equals'
            case 'datetime':
                return 'on'
            default:
                return 'is'
        }
    }
}