import { Thead, Tbody, Tr, Th, Td, TableContainer, Spinner, Table} from '@chakra-ui/react'
import { capitalizeFirstLetter, getDateFromFirestoreTimestamp } from '../../Utils/TextFormatting'
import ModifyUserModal from './UsersTable/ModifyUserModal'
import DeleteUserConfirmationModal from './UsersTable/DeleteUserConfirmationModal'

const UsersTable = ({ users, loading}) => (
    <>
    {loading && <Spinner />}
    {!loading && <TableContainer >
        <Table size='sm' variant='striped'>
            <Thead>
                <Tr>
                    <Th>Name</Th>
                    <Th>Company Name</Th>
                    <Th>Email</Th>
                    <Th>Last Login</Th>
                    <Th>Role</Th>
                    <Th>Change Role</Th>
                    <Th>Delete</Th>
                </Tr>
            </Thead>
            <Tbody>
                <>
                    {users.map(({ email, name, companyName, role, lastLogin, designation, vendorId }, index)=> (
                        <Tr key={index}>
                            <Td>{name}</Td>
                            <Td>{companyName}</Td>
                            <Td>{email}</Td>
                            <Td>{getDateFromFirestoreTimestamp(lastLogin)}</Td>
                            <Td>{capitalizeFirstLetter(role)}</Td>
                            <Td>
                                <ModifyUserModal user={{email, role, name, designation, companyName, vendorId}}/>
                            </Td>
                            <Td>
                                <DeleteUserConfirmationModal user={{email, name, companyName}}/>
                            </Td>
                        </Tr>
                    ))}
                </>
            </Tbody>
        </Table>
    </TableContainer>}
    </>
)


export default UsersTable