import { Box, Grid, Flex, Heading, Text, VStack } from '@chakra-ui/react'
import ROUTES from '../Data/Constants/Routes';
import NavButton from '../Components/Common/NavButton';

const AccessDenied = () => (
    <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3}>
            <Flex flexDirection="column" justify="space-evenly" align="center" >
                <VStack>
                    <Heading>ACCESS DENIED</Heading>
                    <Text>You do not have sufficient permissions to access that page.</Text>
                </VStack>
                <NavButton to={ROUTES.Login}>Return to Login</NavButton>
            </Flex>
        </Grid>
    </Box>
)
export default AccessDenied