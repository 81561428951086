import { useState, useEffect } from 'react'
import { useAuth } from '../Data/Contexts/AuthContext'

export default function useToggleMachineType(anomalies){
  
  const { isVendor, vendorInfo } = useAuth();
  const [anomalyType, setAnomalyType] = useState('VS')
  const [isSwitchChecked, setIsSwitchChecked] = useState(false)
  const [toggledAnomalies, setToggledAnomalies] = useState([])

  useEffect(() => {
    if(isVendor){
      if(vendorInfo.isEPBVendor && !vendorInfo.isVSVendor){
        setAnomalyType('EPB')
        setIsSwitchChecked(true)
      }
    }
  }, [isVendor, vendorInfo])

  useEffect(() => {
    if(anomalies !== undefined){
      setToggledAnomalies(anomalies.filter( anomaly => anomaly.type === anomalyType))
    }
  }, [anomalies, anomalyType])

  function toggleAnomalyType(e){
    if(e.target.checked){
        setAnomalyType('EPB')
    } else{
        setAnomalyType('VS')
    }
    setIsSwitchChecked(e.target.checked)
  }

  return {
    isSwitchChecked,
    toggledAnomalies,
    toggleAnomalyType,
  }
}