import { useState } from "react";
import { Spinner, useColorModeValue, Switch, Badge, HStack, IconButton } from "@chakra-ui/react";
import { DeleteIcon } from '@chakra-ui/icons'
import useFetchVendors from "../../Custom_Hooks/useFetchVendors"
import Vendors from "../../Data/Firestore/vendors.ts";
import "../../Styles/VendorManagement.css";

const MachineTypeSwitch = ({ vData }) => { 
    
    const mode = useColorModeValue('light', 'dark')

    const [loadingVS, setLoadingVS] = useState(false)
    const [loadingEPB, setLoadingEPB] = useState(false)
    const [checkedEPB, setCheckedEPB] = useState(vData.isEPBVendor)
    const [checkedVS, setCheckedVS] = useState(vData.isVSVendor)

    function toggle(event, vData, type){
        let update = {
            load: (loadBool) => {setLoadingEPB(loadBool)},
            check: (checkBool) => {setCheckedEPB(checkBool)}
        }

        if(type === 'VS'){
            update.load = (loadBool) => {setLoadingVS(loadBool)}
            update.check = (checkBool) => {setCheckedVS(checkBool)}
        }

        update.load(true)

        Vendors.setVisbilityForMachineType(vData.id, type, event.target.checked, () => {update.load(false)})
        
        update.check(event.target.checked)
    }

    return (
        <div className={`vendor-card ${mode}`}>
            <h3>{vData.abbv}</h3>
            <p>{vData.name}</p>
            <HStack marginTop={4} marginBottom={2}>
                <Badge colorScheme='green' fontSize='1em'>EPB</Badge>
                <Switch size='lg' colorScheme='green' onChange={(e) => {toggle(e, vData, 'EPB')}} isChecked={checkedEPB}/>
                { loadingEPB && <Spinner /> }
            </HStack>
            <HStack marginTop={2} marginBottom={4}>
                <Badge colorScheme='blue' fontSize='1em'>VS</Badge>
                <Switch size='lg' colorScheme='blue' onChange={(e) => {toggle(e, vData, 'VS')}} isChecked={checkedVS}/>
                { loadingVS && <Spinner /> }
            </HStack>
            <HStack width={'100%'} justify='flex-end' paddingRight={3}>
                <IconButton 
                    colorScheme="red"
                    aria-label="delete vendor"
                    variant='outline'
                    icon={<DeleteIcon />}
                    onClick={() => {alert("TODO// Build Feature")}}/>
            </HStack>
        </div>
    )
}

const VendorManagement = () => {

    const { vendors, vendorsLoading } = useFetchVendors()

    return (
        <>
            { 
                vendorsLoading 
                ?   <Spinner /> 
                :   <div id='vendor-cards'>
                        {
                            vendors.map((vData, idx) => (
                                <MachineTypeSwitch vData={vData} key={idx}/>
                            ))
                        }
                        {/* <button className="vendor-card add" onClick={() => {alert("TODO// Build Feature")}}>
                            <h3>Add Vendor</h3>
                            <p>( + )</p>
                        </button> */}
                    </div>
            }
        </>
    )
}

export default VendorManagement
