import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  HStack,
  VStack,
  Select
} from '@chakra-ui/react'
import { useState } from 'react'
import { months, monthsShortened } from '../../Utils/TextFormatting'
import { makeYearList } from '../../Utils/AnomalyUtils'
import useReviewAnomaly from '../../Custom_Hooks/useReviewAnomaly'

export default function MarkAnomalyAsReportedModal({ anomaly }){
  
  const [month, setMonth] = useState(months[(new Date()).getMonth()])
  const [year, setYear] = useState((new Date()).getFullYear())
  
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { markAnomalyAsReported } = useReviewAnomaly(anomaly, `${month} ${year}`)
  const years = makeYearList()


  return (
    <>
      <Button aria-label='Mark anomaly as reported' colorScheme='blue' onClick={onOpen} variant='outline'>
        Mark as Reported
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Assign Anomaly to Report</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              Please select the Month and Year this anomaly was reported:
              <HStack width={'50%'}>
                <Select defaultValue={months[(new Date()).getMonth()]} width={'50%'} onChange={changeMonth} value={month}>
                  {
                    months.map((monthName, index) => (<option value={monthName}>{monthsShortened[index]}</option>))
                  }
                </Select>
                <Select defaultValue={(new Date()).getFullYear()}  width={'50%'} onChange={changeYear} value={year}>
                  {
                    years.map(year => (<option value={year}>{year}</option>))
                  }
                </Select>
              </HStack>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' variant='outline' mr={3} onClick={markAnomalyAsReported}>
              Complete and Mark as Reported
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )

  function changeMonth(e){
    setMonth(e.target.value)
  }

  function changeYear(e){
    setYear(e.target.value)
  }

}