import { useColorModeValue, Th } from '@chakra-ui/react'

export const CustomTH = ({ show, id, noOP, onClick, display }) => {

    const mode = useColorModeValue('light', 'dark')
    const headerBg = useColorModeValue("#ffffff", "#1A202C")

    function action(){ if(!noOP){ onClick(id) }}

    if(show) return ( <Th className={`customth ${mode} noselect`} scope='col' onClick={action} style={{ position: 'sticky', top: 0, backgroundColor: headerBg}}>{id}{display(id)}</Th> )
}

export default CustomTH