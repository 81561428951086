import { useNavigate } from 'react-router';
import ROUTES from '../Data/Constants/Routes.json';

export default function useNavToPage(to) {
    const navigate = useNavigate()

    const toAccessDenied = () => {navigate(ROUTES.AccessDenied)};
    const toAdminDashboard = () => {navigate(ROUTES.AdminDashboard)}
    const toEPBReport = () => {navigate(ROUTES.EPBReport)};
    const toLogin = () => {navigate(ROUTES.Login)};
    const toNotFound = () => {navigate(ROUTES.NotFound)};
    const toPasswordChange = () => {navigate(ROUTES.PasswordCreation)};
    const toAnomalyDashboard = () => {navigate(ROUTES.AnomalyDashboard)};
    const toRegister = () => {navigate(ROUTES.Register)};
    const toTermsAndConditions = () => {navigate(ROUTES.TermsAndConditions)};
    const toVendorDashboard = () => {navigate(ROUTES.VendorDashboard)};
    const toVSReport = () => {navigate(ROUTES.VSReport)};
    const toBulkUpload = () => {navigate(ROUTES.BulkUpload)};
    const toBulkUploadVS = () => {navigate('/vendor/bulk/VS')};
    const toBulkUploadEPB = () => {navigate('/vendor/bulk/EPB')};
    const toVariableUrl = (url) => {navigate(url)};

    if(to){
        let navFunc = toLogin
        switch (to) {
            case ROUTES.AccessDenied:
                navFunc = toAccessDenied
                break
            case ROUTES.AdminDashboard:
                navFunc = toAdminDashboard
                break
            case ROUTES.EPBReport:
                navFunc = toEPBReport
                break
            case ROUTES.NotFound:
                navFunc = toNotFound
                break
            case ROUTES.PasswordCreation:
                navFunc = toPasswordChange;
                break;
            case ROUTES.AnomalyDashboard:
                navFunc = toAnomalyDashboard;
                break;
            case ROUTES.Register:
                navFunc = toRegister
                break
            case ROUTES.TermsAndConditions:
                navFunc = toTermsAndConditions;
                break
            case ROUTES.VendorDashboard:
                navFunc = toVendorDashboard
                break
            case ROUTES.VSReport:
                navFunc = toVSReport
                break
            case ROUTES.BulkUpload:
                navFunc = toBulkUpload
                break 
            default:
                break
        }
        return { navFunc }
    } else {
        return { 
            toAccessDenied, 
            toAdminDashboard,
            toEPBReport, 
            toLogin,
            toNotFound, 
            toPasswordChange,
            toAnomalyDashboard,
            toRegister, 
            toTermsAndConditions,
            toVendorDashboard,
            toVSReport,
            toBulkUpload,
            toBulkUploadEPB,
            toBulkUploadVS,
            toVariableUrl
        };
    }
}