import { useState, useEffect, useRef, createContext, useContext } from 'react'
import { Box, Container, HStack, VStack, Heading, Tooltip, Button, Link, useColorModeValue, Spinner, Flex } from "@chakra-ui/react"
import { BsDownload } from 'react-icons/bs'
import { useAuth } from '../../Data/Contexts/AuthContext';
import { useParams } from 'react-router';
import { read, utils } from 'xlsx';
//import { writeFile } from 'xlsx';
import Anomalies from '../../Data/Firestore/anomalies';
import UserMenu from '../../Components/Common/UserMenu';
import Anomaly from '../../Classes/Anomaly';
import AnomalyFactory from '../../Classes/AnomalyFactory';
import useNavToPage from '../../Custom_Hooks/useNavToPage';
import useSendEmail from '../../Custom_Hooks/useSendEmail';
import useSystemToast from '../../Custom_Hooks/useSystemToast';
import BulkUploadTemplate from '../../Data/Documents/AnomalyBulkUploadTemplate.xlsx'
import AnomalyValidationPreviewBox from '../../Components/BulkUpload/AnomalyValidationPreviewBox';
import { TableContext } from '../../Data/Contexts/TableContext';

export const BulkUploadContext = createContext()

const BulkUpload = () => {

  let uploadResults = []
  const params = useParams()
  const aType = params.anomalyType.toUpperCase()
  const { toVendorDashboard } = useNavToPage()
  const { accountInfo, vendorInfo } = useAuth()
  const { sendNewBulkUploadEmail } = useSendEmail()
  const { successToast } = useSystemToast()
  const { anomalies: fetchedAnomalies } = useContext(TableContext)
  const uploadFileRef = useRef(null)
  const linkColor = useColorModeValue('blue.700','blue.300')
  const [anomalies, setAnomalies] = useState([])
  const [loading, setLoading] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [canUpload, setCanUpload] = useState(false)
  const [canReset, setCanReset] = useState(false)
  const [uploadFinalResult, setUploadFinalResult] = useState([])
  const [isForCurrentUpdate, setIsForCurrentUpdate] = useState(false)
  //const [isForLaterUpdate, setIsForLaterUpdate] = useState(false)

  useEffect(() => {
    if(anomalies.length <= 0){
      setCanReset(false)
      setCanUpload(false)
    } else {
      setCanReset(true)
      setCanUpload(anomalies.every(anomaly => anomaly.isCriticallyValid))
    }
  }, [anomalies])

  return (
    <Box id="bulk-upload-page" textAlign="center" fontSize="md" marginTop={5}> 
      <HStack id='bulk-upload-header' justifyContent='space-evenly'>
        <Button
        variant="ghost"
        colorScheme="blue"
        onClick={toVendorDashboard}> 
          Back to Lobby
        </Button>          
        <Heading as='h3' size='lg' paddingRight={'5rem'}>Bulk Upload</Heading>
        <UserMenu />
      </HStack>
      <Container maxW='1400px' id="bulk-upload-container" >
        <VStack id="bulk-upload-body" marginTop={10} spacing={8} alignItems='flex-start'>
          <HStack justifyContent={'space-between'} w='100%'>
            <VStack alignItems={'flex-start'}>
              <HStack w={'100%'} justifyContent={'flex-start'}>
                <BsDownload color="#2c5282"/>
                <Link
                  color={linkColor}
                  href={BulkUploadTemplate}
                  target="_blank"
                  rel='noreferrer'
                >
                  Anomaly Bulk Upload Template (.xlsx)
                </Link>
              </HStack>
              <form style={{ display: 'flex', flexDirection: 'column', gap: 6}} ref={uploadFileRef}>
                {/* We wrap these buttons in a form so they are easily reset-able */}
                <input type='file' id='bulk-upload' accept=".xlsx" onChange={retrieveSpreadsheetData}/>
                <HStack>
                  <Button onClick={clearAnomalies} w={'75px'} type='reset' variant='outline' colorScheme='red' isDisabled={!canReset}>Clear</Button>
                  <Tooltip label={canUpload ? "Good to go!" : "Oops! Some anomalies can't be uploaded. Expand the boxes with a red or yellow outline to find out what spreadsheet data needs to be modified."}>
                    <Button onClick={upload} w={'75px'} variant={canUpload ? 'solid' : 'outline'} colorScheme='green' isDisabled={!canUpload} isLoading={isUploading}>Upload</Button>
                  </Tooltip>
                  {/* <VStack alignItems={'flex-start'}>
                    <Checkbox onChange={(e) => {setIsForLaterUpdate(e.target.checked)}} size={'sm'}>Download a copy with anomaly IDs to update later</Checkbox>
                    { isForCurrentUpdate && <Text fontSize={'small'}>Detected anomaly id during import... Upload will modify existing anomaly information</Text> }
                  </VStack> */}
                </HStack>
              </form>
            </VStack>
            {isUploading && <Spinner />}
            <Flex direction={'column'} justify='flex-start' align='flex-start' maxH={'100px'} w="20%" overflowY="auto" padding="10px">
              {uploadFinalResult.map((text, index) => (<p key={index} style={{fontSize: 12}}>{text}</p>))}
            </Flex>
          </HStack>
          <VStack width={'100%'} overflowY='scroll' maxHeight={'72vh'}>
            {
              loading
              ? <Spinner />
              : anomalies.map((a, idx) => (
                  <AnomalyValidationPreviewBox key={idx} index={idx} anomaly={a} deleteFunc={deleteAnomaly} isUpdate={isForCurrentUpdate}/>
                ))
            }
          </VStack>
        </VStack>
      </Container>
    </Box>
  )

  function retrieveSpreadsheetData(e){
    setLoading(true)
    let files = e.target.files, f = files[0]
    let reader = new FileReader()

    reader.onload = (evt) => {
      //Parse Data
      const bstr = evt.target.result
      const wb = read(bstr, {type: 'binary'})
      
      //Get Worksheet
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]

      //Convert to JSON array of arrays
      const data = utils.sheet_to_json(ws, {header:1})
      const factory = new AnomalyFactory(data, AnomalyFactory.DataMode.Spreadsheet)
      
      setIsForCurrentUpdate(factory.detectedIdOnImport)
      setAnomalies(factory.anomalies)
      setUploadFinalResult([])
    }
    
    reader.readAsBinaryString(f); //TODO: fix deprecation
    setLoading(false)
  }

  // function downloadBulkUploadTemplate(){
  //   const headersJson = AnomalyFactory.spreadsheetHeaders(aType === 'EPB')
  //   const ws = utils.json_to_sheet([headersJson])
  //   ws["!cols"] = Object.keys(headersJson).map(key => ({ wch: key.length + 5 }))
  //   const wb = utils.book_new()
  //   utils.book_append_sheet(wb, ws, "Anomalies")
  //   writeFile(wb, `${aType}BulkUploadTemplate.xlsx`, { compression: true })
  // }

  function clearAnomalies(){
    setAnomalies([])
  }

  function deleteAnomaly(idx){
    setAnomalies(anomalies.filter((a, index) => (index !== idx)))
  }

  function addUploadResult(result){
    uploadResults = [...uploadResults, result]
    setUploadFinalResult(uploadResults)
  }

  async function upload(){
    let uploadedCount = 0
    let successfulUploadId = []
    setIsUploading(true)
    addUploadResult(`Begin uploading ${anomalies.length} anomalies...`)

    await Promise.all(anomalies.map( async (a, aIdx) => {
      try {
        await Anomalies.setAnomaly({
          ...a.uploadObject,
          vendorName: vendorInfo.name,
          vendorEmail: accountInfo.email,
          vendorDatetime: (new Date()).toISOString(),
          type: aType,
          comments: isForCurrentUpdate ? Anomaly.diffComments(a, fetchedAnomalies.find(anomaly => anomaly.id === a.id), true, accountInfo) : []
        })
        uploadedCount++
        successfulUploadId.push(a.id)
        addUploadResult(`${aIdx + 1}. Success: ${a.id}`)
      } catch (e) {
        console.log(e)
        addUploadResult(`${aIdx + 1}. Failure`)
      }
    }))

    addUploadResult(`Stopped Uploading. Finished ${uploadedCount} uploads.`)
    
    // if (isForLaterUpdate) { 
    //   try{
    //     //Create and download a spreadsheet using the xlsx tools
    //     const anomJson = ((new AnomalyFactory(anomalies, AnomalyFactory.DataMode.AnomalyClass)).anomalies)    
    //     const ws = utils.json_to_sheet(anomJson)
    //     ws["!cols"] = Object.keys(anomJson[0]).map(key => ({ wch: anomJson.reduce((w,r) => Math.max(w, r[key].length), 10) + 2 }))
    //     const wb = utils.book_new()
    //     utils.book_append_sheet(wb, ws, "Anomalies")
    //     writeFile(wb, `${aType} Anomalies.xlsx`, { compression: true })
    //   } catch (e) {
    //     console.log(e)
    //     errorToast("There was an error creating the spreadsheet")
    //   }
    // }
    
    uploadFileRef.current.reset();
    setAnomalies([])
    setIsUploading(false)
    addUploadResult("Sending notification email to VSTOP...")
    sendNewBulkUploadEmail(vendorInfo.name, new Date(), uploadedCount, aType,
      () => {
        addUploadResult("Sent notification email!")
        successToast("Bulk Anomaly Upload")
        // if(redirect){ toVendorDashboard() }
      }
    )
  }
}

export default BulkUpload
