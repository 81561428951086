import { useState } from 'react'
import { useAuth } from '../../Data/Contexts/AuthContext';
import { Button } from '@chakra-ui/react'
import useNavToPage from '../../Custom_Hooks/useNavToPage';
import useSystemToast from '../../Custom_Hooks/useSystemToast';

const LogOutButton = () => {

    const { logout } = useAuth();
    const { toLogin } = useNavToPage();
    const { errorToast } = useSystemToast();

    const [logoutLoading, setLoading] = useState(false);

    function logoutError(error) {
        errorToast();
        setLoading(false);
    }

    function logOut() {
        setLoading(true);
        logout()
        .then(() => {
            toLogin();
        })
        .catch(error => {
            logoutError(error);
        });
    }

    return (
        <Button
        variant="ghost"
        isLoading={logoutLoading}
        colorScheme="red"
        onClick={logOut}>
        Log Out
        </Button>
    )
}

export default LogOutButton
