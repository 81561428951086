import { db } from '../firebase'
import { collection, addDoc, doc, getDoc, getDocs, updateDoc, deleteDoc, onSnapshot, query, where, getCountFromServer, orderBy, limit, setDoc } from "firebase/firestore"; 

export const anomaliesCollectionQuery = collection(db, "anomalies")
export const epbAnomaliesQuery = query(collection(db, "anomalies"), where("type", "==", "EPB"))
export const vendorAnomaliesQuery = (vendorName) => query(collection(db, "anomalies"), where("vendorName", "==", vendorName))
export const individualAnomalyQuery = (id) => doc(db, 'anomalies', id)
export const vendorVSCountQuery = (vendorName, statusInt) => query(collection(db, "anomalies"), where("vendorName", "==", vendorName), where("type", '==', 'VS'), where("status", "==", statusInt))
export const vendorEPBCountQuery = (vendorName, statusInt) => query(collection(db, "anomalies"), where("vendorName", "==", vendorName), where("type", '==', 'EPB'), where("status", "==", statusInt))
export const vendorCountQuery = (vendorName, machineType, statusInt) => query(collection(db, "anomalies"), where("vendorName", "==", vendorName), where("type", '==', machineType), where("status", "==", statusInt))


const Anomalies = {

  async getListOneTime(query){
    let list = []
    const querySnapshot = await getDocs(query)
    querySnapshot.forEach(doc => list.push({...doc.data(), id: doc.id}))
    return list
  },

  async getObjectOneTime(query){
    const docSnap = await getDoc(query)
    return {...docSnap.data(), id: docSnap.id}
  },

  async getListListener(query){
    const list = []
    const unsub = onSnapshot(
      query, 
      ((querySnapshot) => {
        querySnapshot.forEach(
          doc => {
            list.push({
              ...doc.data(), 
              id: doc.id
            })
          }
        )
      })
    )
    return { unsub, list }
  },

  async getObjectListener(query){
    let object;
    const unsub = onSnapshot(query, (doc) => { object = {...doc.data(), id: doc.id}})
    return { unsub, object }
  },
  
  async getAllAnomalies(cb){
    let anomalies = []
    const querySnapshot = await getDocs(collection(db, "anomalies"));
    querySnapshot.forEach(doc => {
      const docData = {...doc.data(), id: doc.id}
      anomalies.push(docData)
    })
    cb(anomalies)
  },

  async getVendorAnomalies(vendorName, cb){
    const q = query(collection(db, "anomalies"), where("vendorName", "==", vendorName))
    const anomalies = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const docData = {...doc.data(), id: doc.id}
      anomalies.push(docData);
    });
    cb(anomalies)
  },

  async getAnomaliesListener(cb){
    const q = query(collection(db, 'anomalies'), orderBy('timestamp', 'desc'), limit(500))
    const unsubscribe = onSnapshot(q, (querySnapshot => {
      const anomalies = [];
      querySnapshot.forEach((doc) => {
          const docData = {...doc.data(), id: doc.id}
          anomalies.push(docData);
      });
      cb(anomalies)
    }))
    return unsubscribe
  },

  async getAnomaliesOneTime(){
    const anomaliesCollectionQuery = collection(db, "anomalies")
    return this.getListOneTime(anomaliesCollectionQuery)
  },

  async getContractorAnomaliesListener(cb){
    const q = query(collection(db, "anomalies"), where("type", "==", "EPB"), orderBy('timestamp', 'desc'), limit(500))
    const unsubscribe = onSnapshot(q, (querySnapshot => {
      const anomalies = [];
      querySnapshot.forEach((doc) => {
          const docData = {...doc.data(), id: doc.id}
          anomalies.push(docData);
      });
      cb(anomalies)
    }))
    return unsubscribe
  },

  async getContractorAnomaliesOneTime(){
    const contractorQuery = query(collection(db, "anomalies"), where("type", "==", "EPB"))
    const querySnapshot = await getDocs(contractorQuery)
    return querySnapshot.map(doc => ({...doc.data(), id: doc.id}))
  },

  async getVendorAnomaliesListener(vendorName, cb){
    const q = query(collection(db, "anomalies"), where("vendorName", "==", vendorName), orderBy('timestamp', 'desc'), limit(500))
    const unsubscribe = onSnapshot(q, (querySnapshot => {
      const anomalies = [];
      querySnapshot.forEach((doc) => {
          const docData = {...doc.data(), id: doc.id}
          anomalies.push(docData);
      });
      cb(anomalies)
    }))
    return unsubscribe
  },

  async getVendorAnomaliesOneTime(vendorName){
    const vendorQuery = query(collection(db, "anomalies"), where("vendorName", "==", vendorName))
    const querySnapshot = await getDocs(vendorQuery)
    return querySnapshot.map(doc => ({...doc.data(), id: doc.id}))
  },

  async getSpecificAnomalyListener(id, cb){
    const unsubscribe = onSnapshot(doc(db, 'anomalies', id), (doc) => {
      cb({...doc.data(), id: doc.id})
    })
    return unsubscribe
  },

  async getAnomalyByID(id){
    const docSnap = await getDoc(doc(db, "anomalies", id))
    return {...docSnap.data(), id: docSnap.id}
  },

  async submitAdd(anomaly, cb, onFail){
    try {
      const docRef = await addDoc(collection(db, 'anomalies'), anomaly);
      if (cb) cb(docRef.id)
    } catch (e) {
      console.error("Error adding document: ", e);
      if(onFail) onFail(e)
    }
  },

  async setAnomaly(anomaly){
    return await setDoc(doc(db, 'anomalies', anomaly.id), anomaly)
  },

  async addAnomaly(anomaly){
    return await addDoc(collection(db, 'anomalies'), anomaly)
  },

  async updateAnomalyField(anomalyID, kvUpdate){
    return await updateDoc(doc(db, 'anomalies', anomalyID), kvUpdate)
  }, 

  async getCountOf(q, cb){
    const snapshot = await getCountFromServer(q)
    cb(snapshot.data().count)
  },

  async deleteAnomaly(anomalyId, cb, onFail){
    try {
      await deleteDoc(doc(db, 'anomalies', anomalyId))
      cb && cb()
    } catch (e) {
      console.error("Error deleting document", e)
      onFail && onFail()
    }
  },

}

export default Anomalies