export function getAllUniqueCommentEmails(anomaly, currentUserEmail){
    let emails = anomaly?.comments.map(comment => comment.creatorEmail) //get all emails from comments
    let allEmails = [anomaly.vendorEmail, ...emails] //add the targeted anomaly's vendor email, in case they haven't commented
    let unique = allEmails.filter(uniqueFilter) //remove duplicate emails (two or more comments from same person)
    let index = unique.indexOf(currentUserEmail) //find index of current user email, as a check if it exists in the list
    
    if(index > -1){ unique.splice(index, 1) } //remove the current users email (if exists), since they know they submitted the comment
    
    return unique
}

export function uniqueFilter(value, index, self) {
    return self.indexOf(value) === index;
}

export function buildAnomalyLink(type, id){
    return `/anomaly/${type}/${id}`
}

export function buildCommentLink(anomaly){
    return `/anomaly/${anomaly.type}/${anomaly.id}`
}

export function quickStatsAboutArray(array, noHTML){

    if(!array) return <></> //classic javascript. Also typescript. to heck with TS

    const numOfSelecteds = array.reduce((accumulator, currentValue) => accumulator + currentValue.selected, 0)
    const [oldDate, newDate] = _DateRangeQuickStats(array)
    const numOfCounties = _UniqueCountiesQuickStats(array)
    const totalComments = _TotalCommentsQuickStats(array)

    if(noHTML){
        return `Total: ${numOfSelecteds}\n` + 
        `Date Range: ${oldDate} - ${newDate}\n` + 
        `Counties: ${numOfCounties}\n` + 
        `Total Comments: ${totalComments}\n` + 
        `Avg Comments: ${Math.round(totalComments/numOfSelecteds)}\n`
    }

    return (
        <div>
            <p>Total: {array.length}</p>
            <p>Date Range: {oldDate} - {newDate}</p>
            <p>Counties: {numOfCounties}</p>
            <p>Total Comments: {totalComments}</p>
            <p>Avg Comments: {Math.round(totalComments/array.length)}</p>
        </div>
    )
}

function _DateRangeQuickStats(anomalyArray){
    function compareByDate(a,b){
        let aTime = a.timestamp.valueOf()
        let bTime = b.timestamp.valueOf()

        if(aTime > bTime){
            return 1
        }

        if(aTime < bTime){
            return -1
        }

        return 0
    }

    const sortedByDate = anomalyArray.sort(compareByDate)

    let oldest = sortedByDate[0]?.timestamp.toDate().toLocaleString()
    let newest = sortedByDate[anomalyArray.length - 1]?.timestamp.toDate().toLocaleString()
    let oldDate = oldest?.split(", ")[0]
    let newDate = newest?.split(", ")[0]

    return [oldDate, newDate]
}

function _UniqueCountiesQuickStats(anomalyArray){
    let counties = anomalyArray?.map(a => a.county)
    let unique = counties.filter(uniqueFilter)
    return unique.length
}

function _TotalCommentsQuickStats(anomalyArray){
    let comments = anomalyArray?.flatMap(a => a.comments)
    return comments.length
}

export function makeYearList(){
    const currentYear = (new Date()).getFullYear()
    let years = []
    for (let i = 5; i > 0; i--){
        years.push(currentYear - i)
    }
    for (let i = 0; i < 6; i++){
        years.push(currentYear + i)
    }
    return years
}