import { createContext, useState } from 'react';
import useFetchAnomalies from '../../Custom_Hooks/useFetchAnomalies';
import useToggleMachineType from '../../Custom_Hooks/useToggleMachineType';
import useAnomalyPropsDetails from '../../Custom_Hooks/useAnomalyPropsDetails';
import useSortAnomalies from '../../Custom_Hooks/useSortAnomalies';

export const TableContext = createContext()

export const TableContextProvider = ({ children }) => {

    const { checkboxInformation } = useAnomalyPropsDetails()
    const { checkboxInformation: filterInformation} = useAnomalyPropsDetails(false)
    const { anomalies } = useFetchAnomalies()
    const { toggledAnomalies, isSwitchChecked, toggleAnomalyType} = useToggleMachineType(anomalies)
    const { sortedAnomalies, arrow, columnClick } = useSortAnomalies(toggledAnomalies, checkboxInformation)
    const [tabIndex, setTabIndex] = useState(0)
    const [filterArray, setFilterArray] = useState([])

    function removeAllFilters(){
        setFilterArray([])
    }

    return (
        <TableContext.Provider 
            value={{
                checkboxInformation,
                filterInformation,
                rawAnomalies: anomalies,
                anomalies: sortedAnomalies,
                isSwitchChecked,
                toggleAnomalyType,
                arrow,
                columnClick,
                tabIndex,
                setTabIndex,
                filterArray,
                setFilterArray,
                removeAllFilters
            }}
        >
            {children}
        </TableContext.Provider>
    )
}


export default TableContextProvider