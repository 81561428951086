import { useState, useEffect, useContext } from 'react'
import { TableContext } from '../Data/Contexts/TableContext'
import { useAuth } from '../Data/Contexts/AuthContext'
import FILTER from '../Data/Constants/Filters.json'

export default function useTableAnomalies([filterType, filterVal], useRawAnomalies = false) {
    
    const { anomalies, rawAnomalies } = useContext(TableContext)
    const { accountInfo } = useAuth()
    const [filteredAnomalies, setFilteredAnomalies] = useState()
    const [vsCount, setVSCount] = useState()
    const [epbCount, setEPBCount] = useState()

    useEffect(() => {      
        let filtered = [{}]
        let filterFunc = () => (true)
        const anomalySource = useRawAnomalies ? rawAnomalies : anomalies

        switch (filterType) {
            case FILTER.STATUS:
                filterFunc = (a) => (a.status === filterVal) 
                break
            case FILTER.REPORT:
                filterFunc = (a) => (a.report === filterVal)
                break
            case FILTER.ALLREPORTED:
                filterFunc = (a) => (a.report !== undefined)
                break
            default:
                break
        }

        if (accountInfo?.designation === "State Contractor") { //Only show epb anomalies to state contractors ie bakertilly and civix
            filtered = anomalySource.filter(anomaly => anomaly.type === "EPB").filter(filterFunc)
        } else {
            filtered = anomalySource.filter(filterFunc)
        }

        const epbCount = filtered.filter(a => a.type === 'EPB') 
        //we filter for EPBs since they are more of a guarantee to exist. Doesn't really affect anything to do the count either way, though
        setFilteredAnomalies(filtered)
        setVSCount(filtered.length - epbCount.length)
        setEPBCount(epbCount.length)

        //eslint-disable-next-line
    }, [anomalies, rawAnomalies, accountInfo, filterType, filterVal])
    
    return ({
        anomalies: filteredAnomalies,
        vsCount,
        epbCount
    }) 
}
