import { db } from '../firebase'
import { doc, getDoc, getDocs, collection, updateDoc } from "firebase/firestore"; 

type VendorDoc = {
    abbv: string
    id: string
    name: string
    isEPBVendor: boolean
    isVSVendor: boolean
}

const vendorDoc = (id: string) => doc(db, 'vendors', id);
const vendorDocs = getDocs(collection(db, 'vendors'));

const Vendors = {

    async getVendor(vendorDocId: string): Promise<VendorDoc> {
        let vData = { abbv: '', isEPBVendor: false, isVSVendor: false, name: '', id: ''}
        const doc = await getDoc(vendorDoc(vendorDocId))
        if(doc.exists()){
            vData = doc.data() as VendorDoc
            vData.id = doc.id
        }
        return vData
    },

    async getAllVendors(): Promise<Array<VendorDoc>> {
        let vendors = [] as Array<VendorDoc>;
        const docs = await vendorDocs;
        docs.forEach( d => {
            let vData = d.data() as VendorDoc
            vData.id = d.id
            vendors.push(vData)
        })
        
        return vendors
    },

    async setVisbilityForMachineType(vendorDocId: string, machineType: string, visibility: boolean, callback: Function) {
        let update: any = {}
        if( machineType === 'VS' ){
            update.isVSVendor = visibility
        } else {
            update.isEPBVendor = visibility
        }

        await updateDoc(vendorDoc(vendorDocId), update)
        
        callback()
    }

}

export default Vendors