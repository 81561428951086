import { getDateFromFirestoreTimestamp } from '../Utils/TextFormatting'
import xlsx from "json-as-xlsx"

function stringifyComments(comments){
  let masterString = ""
  comments.forEach(comment => {
    masterString += `${getDateFromFirestoreTimestamp(comment.created)} - ${comment.creator} - ${comment.creatorRole} - ${comment.creatorEmail} - ${comment.text} \n`
  });
  return masterString
}

export function exportTableToXlsx(anomalies, switchState){
  let data = [
    {
      sheet: `${switchState ? 'Electronic Poll Book' : 'Voting System' } Anomalies`,
      columns: [
        { label: "Manufacturer Name", value: "vendorName" }, 
        { label: "Manufacturer Email", value: "vendorEmail" }, 
        { label: "Manufacturer Date Received", value: "vendorDatetime" }, 
        { label: "Reporter Name", value: "reporterName" },
        { label: "Reporter Email", value: "reporterEmail" }, 
        { label: "Date Reported", value: "reporterDatetime" },
        { label: "County", value: "county" }, 
        { label: "State", value: "state" }, 
        { label: "Category", value: "locationCategory" }, 
        { label: "Name", value: "locationName" }, 
        { label: `${switchState ? 'EPB' : 'VS'} Name`, value: "machineName" }, 
        { label: `${switchState ? 'EPB' : 'VS'} Version`, value: "machineVersion" }, 
        { label: `${switchState ? 'EPB' : 'VS'} Component`, value: "machineComponent" }, 
        { label: "Serial Number", value: "machineSerial" }, 
        { label: "Anomaly Class", value: "class" }, 
        { label: "Rationale", value: "classRationale" },
        { label: "Description", value: "description" }, 
        { label: "Root Cause", value: "rootCause" }, 
        { label: "Resolution", value: "resolution" },
        { label: "Comments", value: (row) => (stringifyComments(row.comments))}
      ],
      content: anomalies,
    },
  ]
    
  let settings = {
    fileName: `${switchState ? 'Voting-System' : 'Electronic-Poll-Book'}-Anomalies`, 
    extraLength: 3, 
    writeOptions: {}, 
  }
  
  xlsx(data, settings) 
}